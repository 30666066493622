   <template>

  <div class="" :id="scope + '_' + objective._id" >
    <div :class="{'hide':pageloaded==true}" > 
    <i class="fa fa-circle-o-notch fa-spin"></i>
  </div>    

   <div class="row m-2 " v-if="objective.noaccess" :class="{'hide':pageloaded==false}" >
       <p class ="text-danger"> The account you are currently signed in with does not have access to this section. You will need to request access or sign in with an account that has access. </p>
     </div>


     <div class="" v-if="objective.noaccess == false" :class="{'hide':pageloaded==false}">
       <!-- objective start -->
       <div class="objective_titlewrapper d-flex">
         <div class="col-11 col-md-11 px-2 d-flex flex-column">
 
           <h4 class="objectiveCardtitle align-items-center" v-if="pageloaded" >

              <template v-if="objective.isactive == false">
                <span class="badge badge-light" >Inactive</span>  
              </template>
              <template v-if="objective.isactive == true && objective.noaccess == true">
                <span class="badge badge-light" v-if="objective.userstatus != 'pendingjoin'">No access</span>
                <span class="badge badge-light" v-if="objective.userstatus == 'pendingjoin'">Approval pending</span>   
              </template>
               
                            
           </h4>
          
         </div>
       </div>
 
       <div id="activemodalobjective" class="indicator_development hasindicators hide" v-if="!objective.noaccess && ( objective.isactive || objective.myrole == 'owner' ) ">
        <ul class="nav nav-tabs indicator_tab" role="tablist" >
       <li class=" hasleft indicatorsmove" role="presentation" style ="list-style: none;">
        <div class=" moveleft icon-holder" :class = "{'disabled':!hasleft} " href="#" :disabled="!hasleft" @click.prevent="tabmove($event,objective,'left')">

          <span>
           <i class="fas fa-caret-left "></i>
         </span>
       </div>
     </li>
     <!-- tabs loops starts -->
     <!-- data-toggle="tooltip" data-placement="top" title="09 Sep 2018" --> 
     <li data-toggle="tooltip" data-placement="top" data-trigger="hover" :data-original-title="indicatordate(indicator.indicatordate)" class="nav-item indicatortab showdatetooltip"  v-for="indicator in objective.indicators" v-bind:key="indicator.id">

       <a :class="{'active':indicator.tabactive}" 
       class="nav-link indicatorlink" 
       data-toggle="tab"
       :href="'#' +scope+ '_indicatordetails_'+objective._id+'_'+indicator._id" 
       role="tab" 
       style="position: relative; text-align: center;"
       @click.prevent="indicatorlinkclick(objective, indicator)"
       >

       <div class ="icon-holder">
          <span v-html="$options.filters.statusoptionicon(indicator)"></span>
       </div>


       <span class="notify" v-if="indicator.statusindicator=='high'"> 
         <span class="heartbit"></span> <span class="point"></span>
       </span>
     </a>


   </li>
   <!-- tabs loops ends -->
   <li class=" hasright indicatorsmove" role="presentation" style ="margin-bottom: -1px;">
    <div class=" moveright icon-holder" href="#" :class = "{'disabled':!hasright} " :disabled="!hasright" @click.prevent="tabmove($event,objective,'right')">

          <span>
           <i class="fas fa-caret-right "></i>
         </span>
       </div>
  </li>
</ul>

   <div class="indicatorpanels tab-content" v-bind:id="'indicatorpanels_'+objective._id">
       <!-- indicator start -->
         

        <div :id="scope+'_indicatordetails_'+objective._id+'_'+indicator._id" v-for="indicator in objective.indicators" v-bind:key="indicator.id" class="indicatordetails tab-pane" role="tabpanel">

           <!--  start tab contens  -->
 
           <div class="comment-widgets">
 
             <div class="comment-row comment-main">
               <div class="row">
                 <div class="p-2 pull-left d-flex">
                     <base-avatar v-if="indicator.userdetail" :imgsrc = "indicator.userdetail.profilepic" :name = "indicator.userdetail.firstname" :newclass="'statuspic round'" > </base-avatar>
                     <base-avatar v-else :silent ="true" :newclass="'statuspic round'"> </base-avatar>
                 </div>
                 <div class="align-self-center">
                   <div v-if="indicator.userdetail" class="comment-name" v-text="indicator.userdetail.firstname + ' ' + indicator.userdetail.lastname"><span>Indicator author</span></div>
 
                   <div v-if="!indicator.userdetail" class="comment-name"><span>User</span></div>
                   <div class="comment-date">{{indicator.indicatordate | timefromnow}}</div>
                 </div>
               </div>
 
               <div class="p-2 mb-2 comment">
                 <div class="statustext  fr-view" v-html="indicator.message">indicator message text</div>
               </div>
 
 
               <div class="p-2 mt-2 mb-2 comment-measure" v-if="indicator.indicators"> 
                 <p class="mb-0">Status is based on these linked objectives:</p> 
                 <ul class="list-unstyled mb-0">
                   <li v-for="indicator in indicator.indicators" v-bind:key="indicator.id">

                     <div class = "d-flex flex-row my-1 ">
                      <div class ="d-flex flex-row" data-toggle="tooltip" data-placement="top" title="" data-trigger="hover" :data-original-title="statusfrom(indicator.indicatordate)">
                        
                     
                      <div style="position: relative; width:14px" class ="mr-2 text-center">

                <span v-html="$options.filters.statusoptionicon(indicator)"></span>
            
              </div>
                      

                     <a href="#" class="actionprimary text-nowrap" v-text="indicator.measurename" v-on:click.prevent="openlinkedobjective(indicator.measureid,indicator._id)" v-if="indicator.measurename && ( !indicator.measurenoaccess && ( indicator.measureisactive || indicator.measuremyrole == 'owner') )  && scope !='newsletter'"></a>             

                     <span class="text-muted"  v-else v-text="indicator.measurename "></span> 

                    </div>

                     <template v-if="indicator.measureisactive == false">
                     <span class=" ml-1 badge badge-light">Inactive</span>
                     </template>
                     <template v-if="indicator.measurenoaccess == true &&  indicator.measureisactive == true">
                     <span class=" ml-1 badge badge-light" v-if="indicator.measurependingaccess == false">No access</span>
                     <span class=" ml-1 badge badge-light" v-if="indicator.measurependingaccess == true">Approval pending</span>
                     </template>

                     </div>

                   </li>
                 </ul>  

                  <basedonaggregation :haserrors="false" :aggregationmethod="indicator.aggregationmethod"></basedonaggregation>


               </div>
 

             <indicators :indicator ="indicator" :activeObjective="objective.isactive" :activeCockpit="activeCockpit" @showcomments="showcomments" @like="Postlike" :feedback="feedback" :loading = "loadingindicatordata" ></indicators>
             </div>
            <comments style = "margin: 0 -16px; " :cockpit="cockpit" :activeObjective="objective.isactive" scope="objetivemodal" :activeCockpit="activeCockpit" :loggedinuser="loggedinuser" :objectdata="indicator" @onlike="Postlike" @replycomment="replycomment" @updated="updatepages" @oncommentposted="commentposted" @onupdatecomments="loadComments" :feedback="feedback" :commenttype="'status'" :showalways="true"></comments>
 
 
             <!--  end tab contens  -->
           </div> 
 
         </div>
 
       </div>

 
 
   </div>
 
   <!-- objective end -->
 </div>
 
        <!-- 
       <setactivemodal :modalids="'objectivessetactivemodal_'+objective._id" :activeobjective="activeobjective"  @change='setmeasureactivity'></setactivemodal>
 
       <b-modal :id="'aboutobjectivemodalpage_'+objective._id" modal-class="fulldescription simple-modal" hide-footer size="xl" >
         <template v-slot:modal-title>{{objective.name}}</template>
         <div v-html="objective.description"></div>
       </b-modal>           
 
       <memberlistmodal :modalid= '"objectivemeberlistmodal"' :members="objectivemembers"></memberlistmodal>
 
     -->

     
 
   </div>

 </template>
 <style type="text/css"  scoped>
  .nav-link{
    padding: 0 !important
  }
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   /* display: none; <- Crashes Chrome on hover */
   -webkit-appearance: none;
   margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
 }
 
 input[type=number] {
   -moz-appearance:textfield; /* Firefox */
 }

 #objectivemodal___BV_modal_body_{
  padding-bottom: 0px;
 }

 a:active, a:focus, a:visited {
   outline: 0;
   -moz-outline-style: none;
 }
 
 </style>
 <script>
 
 </script>
 
 <script type="text/javascript">
  import avatar from '@/views/tools/avatar'

   import Cockpitsapi from '@/services/api/cockpits'
   // import Swipe from'swipe-js/swipe.js'
   import {indicators,comments} from '@/components/feedback'

   import basedonaggregation from '@/views/cockpit/components/basedonaggregation'
 
   import {bus} from '@/main'
  import moment from 'moment';
   import autosize from 'autosize'
 import Viewer from 'viewerjs';
 
   
   export default {
     name: 'objectivesCardfull',
     props:{
      cockpit:Object,
      loggedinuser:Object,
      activeCockpit:Boolean,
      usemenu:Boolean,
      useswipe:Boolean,
      fulldesc:Boolean,
      scope:String,
      feedback:String,
      index:Number,
      columnnumber: Number,
      indicatorid:String,   

     },
 
     components:{indicators,comments,avatar,basedonaggregation},
 
     data(){
       return{
        objective:{},
        objectiveid:'',
        cursor:{position: 0, hasleft:false, hasright:false},
         activeobjective : {},
         errors: [],
         swiperwrapper: null,
         swiper: null,
         cockpitcursors: [],
         activetab:null,
         tables:null,
         objectivemembers : [],
         showsetaktivemodale:false,
         showinstruction : false,
         showmore : false,
         showobjectivesettings : false,
         addstatusindicator : false,
         status : { "targetvalue": "", "realizedvalue": "", "calculatedindex":1, "statusindicator":"green", "calculatedindexcolor":"", "text":"" },
         calculatedindexvalue : 0,
         pageloaded : false,
         loadingindicatordata:{like:true, comments:true},
         perpage: 10 
        
 
       }
 
     },
          computed:{

            orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

            token(){
    return localStorage.token
  },


            screenType(){
        return this.$store.state.screenType
      },
       hasleft(){
        return this.cursor.hasleft
       },
       hasright(){
        return this.cursor.hasright
       },

       noaccess(){
          return this.objective.noaccess
       }
     },
 
     watch: {
 
 
     },
 
 
     mounted(){


      let self = this


      moment.locale();
     
       //this.loadcockpit();  
       // event bus

       bus.$off('modalobjectiveshown');      
       bus.$off('onreloadobjectivedata');

       bus.$on('onreloadobjectivedata', this.reloadobjectivedata );
       bus.$on('modalobjectiveshown', this.rendermodal);
      
       window.addEventListener('resize',this.ribbonbar)
 
     },
 
     methods:{
      rendermodal( objectiveid, indicatorid ){

         let self = this 

          self.objectiveid = objectiveid
           self.loadobjective( objectiveid,function(){
              self.$nextTick(function(){
                self.loadcockpit(  

                  function(){
                    setTimeout(
                      function(){

                        self.ribbonbar()

                        if(self.$route.query.oid && self.$route.query.iid && self.$route.query.modal == 'yes' ){
                          self.openactivetab(self.$route.query.oid, self.$route.query.iid)
                        }else if (indicatorid){
                          self.openactivetab( objectiveid, indicatorid )
                        }  else {
                           self.openactivetab(objectiveid)
                        }
                        
                        self.pageloaded = true

                        if(self.$store.state.hasTouch){
                          var card = $('#'+self.scope+ '_' + self.objective._id)
                          var tooltip = $('[data-toggle="tooltip"]',card)
                           $(function () {
                              tooltip.tooltip({delay: {show:500, hide:0}})
                            })

                         tooltip.on('mouseenter', function(e){
                          
                            tooltip.each(function(){
                              var newself = this
                              setTimeout(function(){
                                $(newself).data('bs.tooltip').config.delay = {show:0, hide:0}
                                   },300)                   
                              })
                                setTimeout(function(){
                             // console.log('show', tooltip.data('bs.tooltip').config.delay)
                               },300)  
                            
                            }).on('mouseleave',function(e){
                            tooltip.each(function(){
                              var newself = this
                              setTimeout(function(){
                                $(newself).data('bs.tooltip').config.delay = {show:500, hide:0}
                                })
                               })
                            setTimeout(function(){
                            // console.log('hidden', tooltip.data('bs.tooltip').config.delay)
                                })
                          })

              

            }

            autosize($('.autogrow'));

                      },300)
                  });
              
            })
        })
       }, 

      reloadobjectivedata( measureid, indicatorid){

        //alert( measureid + " " + indicatorid)

        this.pageloaded = false
        this.rendermodal( measureid, indicatorid)
      },
      statusfrom(indicator){
        // console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
        return "Status from " + moment(indicator).format("DD MMM, YYYY")
      },
      indicatordate(indicator){
        // console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
        return moment(indicator).format("DD MMM, YYYY")
      },

      openmeasureinmodal( objective, indicator ){

         this.$emit( 'onopenmeasureinmodal', objective, indicator)

      },
      openlinkedobjective(measureid, indicatorid){

      $('.indicatorlink.active').removeClass('active')
      $('.indicatordetails.active').removeClass('active')

        this.$router.push({name: this.$route.name, query: { oid: measureid, iid: indicatorid, modal: 'yes'}})
        this.reloadobjectivedata( measureid, indicatorid)
        //this.$emit('onopenmeasureinmodal', measureid, indicatorid)
        // bus.$emit('modalobjectiveshown',measureid, indicatorid)
      },

      loadobjective(objectiveid ,callback ){    

        let self = this 

        Cockpitsapi.Objective( { "iid":objectiveid, "token":self.token, "context":this.orgkey } )
         .then( function(response){

          console.log( response.data , "bbbbbbbbbbbbbbbbbbbbbbbb")

           if( response.data.ok && ( response.data.measure && response.data.measure._id ) ){

            self.$emit( 'onobjectiveloaded', response.data.measure )
            
            self.objective = response.data.measure


            if( self.objective.indicators ){

              for( var i in self.objective.indicators){
               // if( self.objective.indicators[i] ){
                  self.objective.indicators[i].loadingindicatordata = {like:true, comments:true} 
               // }                
              }


            }

              

            
             //indicator.comments = response.data.comments
             //indicator.commentscount = response.data.numcomments
           }
 
         })
         .catch(error => console.log(error))
         .finally( () => {
                   //do everything
            if( callback ){
              callback()
            }
                   //self.loadLikes( indicator )
          })

      },
 
       openactivetab( oid, iid ){

        if(this.objective._id == oid){
            //console.log(oid)
           if( this.objective.indicators && this.objective.indicators.length){
          // get active indicator and load likes and comments for it

            var index 
            if( this.objective.indicators.findIndex( x => x._id === iid) >= 0 ){
              index = this.objective.indicators.findIndex( x => x._id === iid)
            } else {
               index = this.objective.indicators.length-1
            }
          //  console.log('index',index)
            this.activeobjective = this.objective
            this.loadComments( this.objective.indicators[index] )
            this.loadLikes( this.objective.indicators[index] )

            var el = '#'+this.scope+'_indicatordetails_'+ oid + '_' +this.objective.indicators[index]._id
            var link = $('a[href="' + el + '"]')

            link.addClass('active')

            link.parents('.nav-tabs').addClass("opened")

            $(el).addClass('active')  


            // move the cursor to the active batch
           var parent = '#'+ this.scope + '_' +oid
           var start = $( '.indicatortab', parent).index( $( '.indicatorlink.active', parent).parent('.indicatortab') )
 
            
            var end = start + this.perpage
            var tabs = $( '.indicatortab ', parent )
            if( tabs.length > this.perpage ){
              if(tabs.length > this.perpage + index){
                  this.cursor.hasleft = true; 
              }
              if(  0 < this.perpage - index){
                this.cursor.hasright = true;
              }

              
              

              if( end > tabs.length ){
                end = tabs.length
              }

              if( (end - start) < this.perpage ){
                var diff = this.perpage - (end - start)
                start = start - diff
              }

              tabs.addClass("hide")
              tabs.slice( start, end ).removeClass("hide")

            }
          
          }
          
           Cockpitsapi.Tabclicked( iid, self.token)

        }

       },
 
       objectiveupdated( objectiveid, indicatorid ){
         var self = this;
 
         if( this.objective._id == objectiveid ){        
 
           setTimeout( 
             function(){               
               self.loadcockpit( function(){    
                 
                  self.openactivetab( '#'+this.scope+'_indicatordetails_' + objectiveid + '_' + indicatorid )


               }); 
             }, 100 )
 
         }

         $('[data-toggle="tooltip"]').tooltip()
 
       },

        attachviewer( img ){

        let self = this

        this.viewer = new Viewer( img, {
          title : false,
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            rotateLeft: 1,
            rotateRight: 1
          },
          movable : false,
          inline: false,
          button: false,
          navbar: false,
          viewed() {
            //viewer.zoomTo(0);
          },
          hidden(){
            self.viewer.destroy()
          },
        }).view(0);

      },
 
      loadcockpit( callback ){
        
        var self = this;
        var tabid = self.objectiveid
        var tagets=$('a[data-toggle="tab"]',"#"+ this.scope + '_' +tabid);

         for (var i=0; i<tagets.length;i++){
           
           tagets[i.toString()].addEventListener("click", function(e) {            

            var parent = $('#' + self.scope + '_' + tabid )

            var isopened = $(e.target).parents('.nav-tabs').hasClass("opened")
 

           if( self.objective.indicators && self.activetab !== null && typeof( parent.find('.indicatorlink.active').attr('href')) !== 'undefined'){
               // console.log(self.activetab)
             self.objective.indicators.find( x => x._id == parent.find('.indicatorlink.active').attr('href').slice( parent.find('.nav-link.indicatorlink.active').attr('href').lastIndexOf('_')+1, parent.find('.nav-link.indicatorlink.active').attr('href').length)).showcomments = false;
 
             }
 
              // e.preventDefault()
              if(self.activetab  == e.currentTarget){
 
               $(e.currentTarget).parents('.nav-tabs').removeClass("opened")
               $(self.activetab).removeClass("active") 
               
               $('#indicatorpanels_'+tabid).toggleClass('hide')
               $('.indicatorpanels_'+tabid).toggleClass('hide')
 
               if( isopened ){
                 $(e.target).parents('.nav-tabs').next('.tab-content').find('.active').removeClass("active")
                 e.preventDefault()
                 e.stopPropagation()
               }else{
               // console.log( $(e.target).parents('.nav-tabs').next('.tab-content').find('.active'))
                 $(e.target).parents('.nav-tabs').next('.tab-content').find('.active').addClass("active")

                
               }
             }else{


             }


 
           });
 
         }
 
 
        tagets.on('shown.bs.tab', function (e) {

          var tab =  $($(e.target).attr('href'))
          tab.addClass('active')
          var lis = $( '.indicatortab' , $(e.currentTarget).parents('.nav-tabs') )
          var li = $(e.currentTarget).parent('.indicatortab')
          var next = $(e.currentTarget).parents('.nav-tabs').next('.indicatorpanels')

          next.removeClass('hide')

          var tabpos = lis.index(li)           
 
        })
 
        
        tagets.on('shown.bs.tab', function (e) { 

          var x = e.target.href.split('#')
          var parts = x[1].split('_')
 
          $(e.target).parents('.nav-tabs').addClass("opened")
           self.activetab = e.target 
 
         });

         this.ribbonbar();
 
         if( callback ){
           callback()
         }
        },
 
       commentposted( indicator, comments, commenttype, iid, numcomments ){          
 
         if( commenttype == "comment"){
 
           for( var i in indicator.comments ){
             if( indicator.comments[i]._id == iid ){
               indicator.comments[i].comments = comments
             }         
           }
 
         }else{
           indicator.comments = comments
         }

         indicator.commentscount = numcomments

         bus.$emit('updatedcommentscount', this.objective._id, indicator._id, numcomments)

         
 
         this.updatepages()
       },
 
       loadComments(indicator){
 
         let self = this 
         Cockpitsapi.Comments( { context:this.orgkey, "iid":indicator._id, "type":"status" , "token": this.token } )
         .then( function(response){
 
           if( response.data.ok && response.data.comments != null){
             indicator.comments = response.data.comments
             indicator.commentscount = response.data.numcomments
           }
 
         })
         .catch(error => console.log(error))
         .finally( () => {
                   //do everything
self.loadingindicatordata.comments = false
                   //self.loadLikes( indicator )
                 })
 
 
       },
       loadLikes(indicator){
 
         let self = this 
         Cockpitsapi.Likes( { "iid":indicator._id, "type":"status", "token": this.token } )
         .then( function(response){
 
           if( response.data.ok ){
             indicator.likescount = response.data.numlikes
             indicator.dislikescount = response.data.numdislikes
             indicator.vote = response.data.hasvoted
              
           }
 
         })
         .catch(error => console.log(error))
         .finally( () => {
                self.loadingindicatordata.like = false
                   //do everything
                 })
 
 
       },
 
 
 
       replycomment(indicator,cid,commenttype,issilent,message){ 
 
         var self = this
         Cockpitsapi.Postcomment({ "context":this.orgkey, "cockpitid":this.cockpit._id,"iid":cid,"commenttype":commenttype,"issilent":issilent,"message":message,"t":this.token,"action": "postcomment"})
         .then( function(response){
           if( response.data.ok ){
           console.log()          
            self.loadComments(indicator)
          }
 
        })
         .catch(error => console.log(error))
         .finally( () => {
 
               //do everything
               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
             })
 
       },
 
       Postlike(type, object , action){

 
         var self = this
 
         Cockpitsapi.Issuealike( type, object._id ,action, this.token, self.cockpit._id )
         .then( function(response){
           if( response.data.ok ){

            object.likescount = response.data.numlikes
            object.dislikescount = response.data.numdislikes
            object.vote = response.data.hasvoted
            console.log(response.data, object) 

             bus.$emit('updatedlikecount',self.objective._id, object._id,object.likescount, object.dislikescount )
          }
 
        })
         .catch(error => console.log(error))
         .finally( () => {
 
               //do everything
               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
 
             })
 
       },
 
         ribbonbar : function(){ //counts tab, if more than 10 hide other links 

              var card = $('#'+this.scope + '_' + this.objective._id);    
              var w = card.innerWidth() - 100;
              var icons = 50
              var perpage 
              if(w <= 0){
                perpage = 6
              } else {perpage = this.perpage <= Math.floor(w/icons)  ? this.perpage: Math.floor(w/icons);}
              var items = $('#' + this.scope + '_' +this.objective._id).find(".indicator_tab" );
               this.perpage = perpage
              var position = this.cursor.position;
                
                 if( this.objective.indicators && this.objective.indicators.length > perpage ){
   
                   this.cursor.position = this.objective.indicators.length -  perpage;
                   this.cursor.hasleft = true;
   
   
                 } else if ( this.objective.indicators && this.objective.indicators.length <= perpage){
                  this.cursor.hasleft = false;
                  this.cursor.hasright = false;
                  this.cursor.position = 0;
   
   
                 }

                /*  console.log('card',card,'w',w,'perpage',perpage,'this.objective.indicators.length',this.objective.indicators.length,' this.cursor.position', this.cursor.position)*/

                 items.each( function( i, o ){                
                    
                   var tabs = $('.indicatortab', o )
                                 
                   if( tabs.length + 1 > perpage){
                     //  console.log('tabs: ',tabs ) 
                     position = tabs.length - perpage;
                     
                     tabs.addClass("hide")
                     tabs.slice( position, tabs.length ).removeClass("hide")
   
                   }                   
   
                 })  
                $('.indicator_development' , card ).removeClass('hide')
 
             },
 
             moveArrow(objective, indicator, dir){ 
               objective.indicator
             },
             
             indicatorlinkclick : function( objective, indicator){

              let self = this
 
               this.activeobjective = objective
               this.loadComments(indicator)
               this.loadLikes( indicator )
                this.loadingindicatordata = {like:true, comments:true}
               Cockpitsapi.Tabclicked( indicator._id, self.token)
               //

               setTimeout( function(){
                  //console.log( $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext') )

                      $('img', '#'+self.scope+'_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext').off('click').on('click', function( i,o ){
                      self.attachviewer( i.currentTarget )
                    }).addClass('viewer')

                      autosize($('.autogrow'));

                 }, 100)



               self.$func.addQuery({oid:objective._id,iid:indicator._id, modal:'yes'}, self.$route, self.$router)
               //console.log( "zzz", objective)
             },
 
 
 
            tabmove : function( ev, objective, moveto, movetoablselut ){
              console.log('tabmove')
            var card = $('#'+this.scope + '_' + this.objective._id);
            var w = card.innerWidth() - 62;
            var icons = 32
            var perpage = this.perpage < Math.floor(w/icons) ? this.perpage: Math.floor(w/icons);

            if(!movetoablselut){
             if( moveto == 'left' ){

               this.cursor.position = this.cursor.position - 1;

               this.cursor.hasright = true

               if( this.cursor.position <= 0 ){
                 this.cursor.position = 0
                 this.cursor.hasleft = false                  
               }
             }

             if( moveto == 'right' ){
               this.cursor.position = this.cursor.position + 1;

               this.cursor.hasleft = true;

               if( this.cursor.position > this.objective.indicators.length ){

                 this.cursor.position = this.objective.indicators.length - perpage
                 this.cursor.hasright = false

               }else{

                 if( (this.cursor.position + perpage) >= this.objective.indicators.length  ){

                   this.cursor.position = this.objective.indicators.length - perpage
                   this.cursor.hasright = false
                 }

               }
             }
           } 
               //if( $(ev.currentTarget).parents('.indicator_tab').siblings('.indicatorpanels').prop("id") == this.swiperwrapper ){
                //this.swiper.slide( objective.cursor.position,0)
                  // alert('hello')
                //}
                if(ev){             
                  var parent = $(ev.currentTarget).parents('.indicator_tab')
                }else {
                  var parent = $('.indicator_tab',card)
                }
                var tabs = $('.indicatortab', parent ) 

                var activetabe = $(tabs.find('.active').parent())
                var numofactivetabe = tabs.index(activetabe)
                
                if(numofactivetabe - this.cursor.position > perpage -1 && numofactivetabe >= 0 ){
                  tabs[numofactivetabe-1].firstChild.click()
                } else if(numofactivetabe <  this.cursor.position &&  numofactivetabe >= 0 ){
                  tabs[numofactivetabe+1].firstChild.click()
                } 
                if( tabs.length > perpage ){

                 tabs.addClass("hide")
                 tabs.slice( this.cursor.position, this.cursor.position +perpage ).removeClass("hide")

               } 

             },
             
             tabs : function(){
 
 
               var numbertabs = 7;
 
               var uls = $('.indicator_tab');
 
               uls.each( function( i,o ){
                 $( '.indicatortab', o ).slice( numbertabs ).addClass('hide')
               })
 
             },

 
             showcomments : function( indicator ){
              //console.log('hello')
               if( indicator.showcomments == true ){
                 indicator.showcomments = false
                 autosize()
               }else{
                 indicator.showcomments = true
               }
 
               
 
               this.$nextTick(function() {
                this.updatepages()
                 autosize($('.autogrow'));
               })
             },
 
             show_objectivedesc(){


              //this.$em
               $('#aboutobjectivemodalpage').modal('show');              
             },
 
             show_objectivestatusadd(){
               this.addstatusindicator = true 
             },
 
             show_objectivestatusaggregatoradd(){
               $('#aggregatoraddstatus').modal('show');    
             },
 
             updatepages(){
             
              // var indicatorheight = $('#index_indicatordetails_' + this.$route.params.objectiveid + '_' + window.location.pathname.slice(window.location.pathname.lastIndexOf('indicator/')+10)).height()
               //////console.log('height ' + indicatorheight)
               //$('#indicatorpanels_'+this.$route.params.objectiveid ).height(indicatorheight +'px')

               //$('.panelheight', '#objectivemodal').height( indicatorheight +'px')
             }
 
           },
           created () {
           },
           destroyed () {
 
           },
           updated(){
            
           }
         }
 
       </script>

