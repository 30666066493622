<script type="text/javascript"></script>
<template>
          <div  id="statisticspage" class = "">
            <div class ="loadingstatistics" :class = "{'hide': !loading}" style="display: none;">
              <i class="fa fa-circle-o-notch fa-spin"></i> <span> We are gathering all the stastistics this may take a bit </span>
            </div>

            <button @click="refreshdata()" class="hide">Refresh</button>
                          <!-- charts -->

            <Riskcharts :xaxissize="'14px'" v-if="cockpit.risks"></Riskcharts>

            <!-- charts -->
            <Boardcharts :xaxissize="'14px'"></Boardcharts>

             <h4 style="width: 100%;" class ="sectionheader mb-1"> <span style="background-color: #ffffff; padding-right: 20px;"> Objectives</span> 
             </h4>

             <template>

            <template>
            <div v-for="(section) in cockpitdata.sections" :key="'section_'+section._id" class="">
              
              <h4 v-if="section.objectivesdata" class="sectionhead"  style="width: 100%;"><span style="background-color: #ffffff; padding-right: 20px;" v-text="section.label"></span></h4>

               <div v-for="measure in section.objectivesdata" :key="'insection_'+measure._id">
                
                   <statistic v-if="measure.noaccess == false" :objectivedata="measure" :objectiveid="measure._id" :placeholder="measure.name" @oncolumnclick="columnclick" @onobjectivesstatisticsload="objectivesstatisticsload"></statistic>
                   

                   <div id="statisticitem" v-if="measure.noaccess == true" > 
                    <div class="card mb-2">
                      <div class="card-body">
                        <h4> {{ measure.name }} 

                        <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus != 'pendingjoin'">No access</span>
                          <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus == 'pendingjoin'">Approval pending</span>
                          <span class="badge badge-light" v-if="measure.isactive==false">Inactive</span>
                        </h4>
                      </div>
                    </div>
                  </div>

                   <div v-if="measure.noaccess == false && measure.nodes.length > 0 " class="ml-5">
                    <template v-if ="!measure.noaccess && ( measure.isactive == true || ( measure.isactive == false && measure.myrole.toUpperCase() == 'OWNER' ) ) "> 
                      <div v-for="submeasureid in measure.nodes" :key="'insection_'+measure._id + '_' +submeasureid">

                      <statistic :objectiveid="submeasureid" :placeholder="submeasureid.name" @oncolumnclick="columnclick"  @onobjectivesstatisticsload="objectivesstatisticsload"></statistic>

                      </div>
                    </template>
                    
                   </div>
                </div>
            </div>
            </template>

            
            
            <h4 class="sectionhead"  style="width: 100%;"><span style="background-color: #ffffff; padding-right: 20px;">Not grouped</span></h4>

            <div v-for="measure in cockpitdata.measuresnotinsections" :key="'notinsection_'+measure._id">
             <statistic v-if="measure.noaccess == false" :objectiveid="measure._id" @oncolumnclick="columnclick"></statistic>

             <div id="statisticitem" v-if="measure.noaccess == true" class="mb-2" > 
                    <div class="card">
                      <div class="card-body">
                        <h4> {{ measure.name }} 

                        <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus != 'pendingjoin'">No access</span>
                          <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus == 'pendingjoin'">Approval pending</span>
                          <span class="badge badge-light" v-if="measure.isactive==false">Inactive</span>
                        </h4>
                      </div>
                    </div>
                  </div>



                  <div v-if="measure.noaccess == false && measure.nodes.length > 0 " class="ml-5">
                    <template v-if ="!measure.noaccess && ( measure.isactive == true || ( measure.isactive == false && measure.myrole.toUpperCase() == 'OWNER' ) ) "> 
                      <div v-for="submeasureid in measure.nodes" :key="'insection_'+measure._id + '_' +submeasureid">

                      <statistic v-if="measure.noaccess == false" :objectiveid="submeasureid" :placeholder="submeasureid.name" @oncolumnclick="columnclick"  @onobjectivesstatisticsload="objectivesstatisticsload"></statistic>

                      <div id="statisticitem" v-if="measure.noaccess == true" class="mb-2" > 
                        <div class="card">
                          <div class="card-body">
                            <h4> {{ measure.name }} 

                            <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus != 'pendingjoin'">No access</span>
                              <span class="badge badge-light" v-if="measure.isactive==true && measure.noaccess == true && measure.userstatus == 'pendingjoin'">Approval pending</span>
                              <span class="badge badge-light" v-if="measure.isactive==false">Inactive</span>
                            </h4>
                          </div>
                        </div>
                      </div>

                      </div>
                    </template>
                    
                   </div>

            </div>

            </template>

               <h4 style="width: 100%;" class="mt-4 sectionheader mb-3"> <span style="background-color: #ffffff; padding-right: 20px;"> Newsletters</span> <p class="border-top" style="margin-top: -9px;">&nbsp;</p></h4>
              
                <div  > 
                  
                  <div class="card-body" >

                         <div class = "row" style = "flex-wrap: nowrap;" id="DataTables_Newsletter">
                        <div class = "d-flex flex-column" width="155">
                          <div class=" ml-auto headcol"   height="31" style="background-color: #ffffff; margin-top: 21px;">&nbsp;</div>
                          <div class=" ml-auto headcol"   height="31" style="background-color: #ffffff;">Views/Users</div>
                          <div class=" ml-autot headcol"  height="31" style="background-color: #ffffff;">Likes/Dislikes</div>
                          <div class=" ml-auto headcol"   height="31" style="background-color: #ffffff;">Comments</div>  
                        </div>
                        <div class = "clearfix"></div>
                        <div class ="mainstatistics statstable row m-0 pb-2" style = "flex-wrap: nowrap;">
                            <div class = "stautsindicator" v-for="indicator in cockpitstatistics.newsletterdata" :key="indicator._id" @click="onopennewsletterfromcockpit(indicator._id)" >

                               <div class ="stauts">
                               <span v-if="indicator" style="position: relative; white-space: nowrap;">
                                {{indicator.published.date | dateformat}}
                              </span>
                              </div>


                              <div class ="stauts">

                                <span v-if="indicator.statistics" style="position: relative;"> 
                                  <span v-text="indicator.statistics.viewcount == 0 ? '-': indicator.statistics.viewcount">0</span>/<span v-text="indicator.statistics.viewcountunique == 0 ? '-': indicator.statistics.viewcountunique">0</span>
                                  <span v-if="indicator.statistics.viewsindicator.ishigh" class="notify"> 
                                    <span class="heartbit"></span> 
                                  </span>
                                  <span class="point"></span>
                                  <span v-if="!indicator">&nbsp;</span>
                                </span>
                              </div>

                              <div class ="stauts" nowrap ="">
                              
                               <span v-if="indicator.statistics" style="position: relative;" >
                                <span v-text="indicator.statistics.numlikes == 0 ? '-': indicator.statistics.numlikes">0</span>
                                /
                                <span v-text="indicator.statistics.numdislikes  == 0 ? '-': indicator.statistics.numdislikes">0</span>
                                <span v-if="indicator.statistics.numlikesindicators.ishigh" class="notify">
                                  <span class="heartbit"></span> 
                                  <span class="point blue"></span>
                                </span>
                                <span v-if="!indicator">&nbsp; </span>
                              </span>
                                
                              </div>

                              <div class ="stauts">
                              <span v-if="indicator" style="position: relative;" >
                                <span v-text="indicator.statistics.commentscount == 0 ? '-': indicator.statistics.commentscount">0</span>
                                <span v-if="indicator.statistics.commentactivity.ishigh" class="notify"> 
                                  <span class="heartbit"></span>
                                  <span class="point"></span>
                                </span> 
                                <span v-if="!indicator">&nbsp; </span>
                              </span>

                              </div>
                            </div>
                            <div class = "clearfix"></div>
                        </div>
                      </div>




                </div>
                </div>


          </div>



</template>

<style scoped>
       /* table { border-collapse:separate; border-top: 3px solid grey; }
        td, th {
            margin:0;
            border:3px solid grey; 
            border-top-width:0px; 
            white-space:nowrap;
        }
        div#statistics { 
            width: 600px; 
            overflow-x:scroll;  
            margin-left:5em; 
            overflow-y:visible;
            padding-bottom:1px;
        }
        .headcol {
            position:absolute; 
            width:5em; 
            left:0;
            top:auto;
            border-right: 0px none black; 
            border-top-width:3px;
            margin-top:-3px; 
        }
        .headcol:before {content: 'Row ';}*/

        .statstable td{
          width: 50px;
          text-align: right;
        }

        .sectionheader{
          font-weight: bold;
        }
</style>

<script>

  import Cockpitsapi from '@/services/api/cockpits'

  import statistic from '@/views/cockpit/components/statistic.vue'

  import {bus} from '@/main'

  import moment from 'moment';

  import Boardcharts from '@/components/boardcharts/boardcharts.vue'

  import Riskcharts from '@/components/boardcharts/riskcharts.vue'


  export default{
    name:'cockpitstatistics',
    props:{

      cockpitdata:Object
    },


    data(){
      return{
        cockpitstatistics:{},
        loading:true,
        mindate :  new Date().getTime(),
     
      }
    },
    watch:{},
    computed:{

      cockpit(){
        return this.$store.getters.GetCockpit
      },

      cockpitid(){
        return this.$route.params.cid
      },
      token(){
        return this.$store.getters.token
      },
      statisticobjectives(){
        return this.$store.getters.statisticsobjectives
      },
    },
    components:{statistic, Boardcharts, Riskcharts },
    mounted(){
      //console.log(this.$route)
      
      bus.$off('openstatisticspage')
      bus.$on('openstatisticspage',this.openstatisticspage)
      bus.$on('updatednewslettercommentscount',this.updatednewslettercommentscount)
      bus.$on('updatednewsletterlikecount',this.updatednewsletterlikecount)
      this.getstatistics()



      


    },
    methods:{

      refreshdata(){

        this.getstatistics()
      },

      objectivesstatisticsload( obj ){


        //alert('x')
/*

       //alert('')
       var allindexsum = 0
       var cntr = 0 

       for( var i in obj.indicators ){

          if( obj.indicators[i].indexvalue ) {

            allindexsum += parseFloat( obj.indicators[i].indexvalue )
           // allindex.push( obj.indicators[i].indexvalue )
           cntr++
          }
       }

        console.log( allindexsum / cntr, "emit2")

        */

        //var rndnum = Math.floor(Math.random() * 10)

        //this.addrdata( rndnum )
      },

      addrdata( datetime, value ){

        if ( value == undefined ) {
           var rndnum = Math.floor(Math.random() * 10)
           value = rndnum
        }

        var data = []
        var name = ""
        var series =  this.series[0]
        name = series.name
        data = series.data

        //var item = []

       // item.push( value )
       // item.push( datetime )

        data.push( value )


        this.series = []
        this.series.push( { "name":name, "data":data} )
      },
      
      newsletterclick( nid ){

        //console.log(nid)

        // this.$emit('onopennewsletter', nid)

      },
      updatednewslettercommentscount(iid, count){
        for( var i in this.cockpitstatistics.newsletterdata){
           if(this.cockpitstatistics.newsletterdata[i]._id == iid){

              this.cockpitstatistics.newsletterdata[i].statistics.commentscount = count

            }

          }
        },
      updatednewsletterlikecount(iid, like, dislike){
        for( var i in this.cockpitstatistics.newsletterdata){
           if(this.cockpitstatistics.newsletterdata[i]._id == iid){

              this.cockpitstatistics.newsletterdata[i].statistics.numlikes  = like
              this.cockpitstatistics.newsletterdata[i].statistics.numdislikes  = dislike

            }

          }
      },
      openstatisticspage(){

        //console.log( this.cockpitdata )
        this.getstatistics()
      },  

      columnclick(evt, iid, mid, measure){

        if(!measure.noaccess){
           this.$emit('onopenmeasureinmodal', mid, iid)
            this.$router.push({query:{oid: mid, iid:iid, modal: 'yes'}})
        }
       
      },

      getstatistics(){

        let self = this

        this.cockpitstatistics = []
        this.loading = true

        // clear graph values
        this.$store.dispatch('addObjectiveToStatistics',{ objective:null, clear:true })

        Cockpitsapi.Statistics( this.cockpitid, this.token )
        .then( function(response){

          if( response.data.ok ){    

            self.cockpitstatistics = response.data.cockpit

            setTimeout( function(){
              $('[data-toggle="tooltip"]').tooltip()
            }, 200)

            self.loading = false
             
            
          }

        }).finally(()=>{
          $('td').mouseover(function(){
            let self = this
            $('*').removeClass('statstable-selected')
            var tableC =  $(this).closest( "table" )
            var tableid = tableC.attr('id')
            var table = $('#'+tableid +' td').each(function(index){
              if(self.cellIndex == this.cellIndex){
                $(this).addClass('statstable-selected')
                 // console.log('this ',self.cellIndex, ':', ' td ', this.cellIndex, ':', ' td all ', this )
              }
              
              })
            })
           $('td').mouseout(function(){
           
            $('*').removeClass('statstable-selected')
          })
        })

        Cockpitsapi.Cockpitschartdata( { "_id": this.cockpitid, "token": this.token } ).then( function(response){

          if( response.data.indicatorinweeks ){

            self.series2data = response.data.indicatorinweeks
            self.chartwarning = response.data.incompletedata
            //console.log( response.data.indicatorinweeks , "xx")
          }

      })


      },
      onopennewsletterfromcockpit(nid){

        bus.$emit('onopennewsletterforviewing',nid,this.$route.params.cid)
      }
    }
  }
</script>