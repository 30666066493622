import { render, staticRenderFns } from "./objectivesSearch.vue?vue&type=template&id=b267ffa2&scoped=true&"
import script from "./objectivesSearch.vue?vue&type=script&lang=js&"
export * from "./objectivesSearch.vue?vue&type=script&lang=js&"
import style0 from "./objectivesSearch.vue?vue&type=style&index=0&id=b267ffa2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b267ffa2",
  null
  
)

export default component.exports