<template>
  <div>




 <div class="diverder mt-2"></div>
 <h4 class="font-weight-bold">Group objectives</h4>
  <div class = "d-flex flex-row" v-if = "ttype == 'cockpits'"> 


    <button class="btn btn-secondary mr-auto"  style ="white-space: nowrap;" href="#" @click.prevent="addsection( object )">Add section</button>
  </div>
  <div class="form-group">

    <template v-if="object.sections"> 
      <div  v-for="section in object.sections" :key="section._id" class="cockpitsection dynamic-tables noshadow mt-2">           

        <div class="d-flex align-items-center sectionwrapper" :id="'sectionwrapper_' + section._id"> 

          <h4 href="#" style ="min-width: 1px;" class = "overflow-string m-0"  v-if="section.action==''"> 
            {{ section.label }} 
          </h4>
          <h4 href="#" style ="min-width: 1px;" class = "overflow-string mx-0 my-auto" v-if = "section.action=='tryremove'">
            {{ section.label }} 
          </h4>

          <base-icon  :forcemd = "true" v-if="section.action==''" @click.prevent="updatesectionlabel(section)" icon ="fal fa-pencil editableicon" iconSize ="sm"/>              
          

          <input class ="form-control" type="text" maxlength="70" @input = "updatelabel($event)"  v-if="section.action=='editlabel'" v-model="editsectionlabel"/>
          <div style = "margin-left: 3.5px" v-if="section.action=='editlabel'"></div>



          <base-btn label = "Save" class ="my-auto" default ="enter" v-if="section.action=='editlabel'" id="saveingbtn" :size ="btnsize" variant="primary"  @click = "updatesection( section, section._id, 'label')"/>



          <p class = "border-top my-auto ml-2" style ="flex-grow:1"></p>
          <base-icon v-if="section.action==''" :forcemd = "true" @click.prevent="removesection( 'try', section, object )" icon ="fal fa-trash-alt" iconSize ="sm"/>

          <base-btn
           label ="Confirm?"  class ="my-auto ml-2" default ="enter" v-if="section.action=='tryremove'" id="deletingbtn" :size ="btnsize" variant="danger"  @click = "removesection( 'confirm', section, object )"/>


        </div>
        <p class="text-danger" v-if="section.error != ''">{{ section.error }}</p> 

        <ul class="list-group sectionitem list-objlist" :id="'section_'+section._id">

          <li class="list-group-item" v-bind:data-sort="measure._id" v-for="measure in section.objectivesdata" :id="'listitem_'+measure._id" :key="measure._id">

            <div class="row section-item p-0 " style =" flex-wrap: nowrap;" :class = "{'draghandle':draghandle}">  
              <div class="list-content d-flex align-items-center overflow-string"  nowrap v-bind:class=" { 'text-muted': measure.mode=='deleting' } " > 
               <base-icon icon = "fas fa-grip-lines fa-lg" :class = "{'draghandle':!draghandle}"/> 

               <span v-if="systemgenerated(measure)" class="mr-1"><i class="fal fa-globe"></i></span>

               <span class = "overflow-string  mr-2 pr-2" style ="min-width:0px" v-text="measure.name"></span></div>
               <div class="list-content pt-0 pl-2 ml-auto d-flex align-items-center">
                <div class="actions " v-bind:id="'objactions_'+measure._id" >

                  <a href="#" v-if="measure.mode=='idle'" @click.prevent.stop="tryremoveobjective(measure, 'tryremove')" class="actiondelete my-auto">Remove</a>

                  <div class ="my-auto ">
                    <b-button v-if="measure.mode=='deleting'" id="deletingbtn" :size ="btnsize" variant="danger"  @click.prevent.stop = " tryremoveobjective(measure, 'confirm', $event)">Confirm?</b-button>
                  </div>



                </div>
              </div>
            </div> 
          </li>


        </ul>

      </div>
    </template>

    <template> 
      <div class="cockpitsection dynamic-tables noshadow mt-2">            
        <h4  class="d-flex" style ="white-space: nowrap;" v-if="ttype == 'cockpits' && object.sections && object.sections.length">
          Not grouped
          <p class = "border-top my-auto w-100 ml-4"/>
        </h4>
        <ul class="list-group list-group-flush sectionitem list-objlist" id="list-objlist"> 

          <li class="list-group-item" v-bind:data-sort="measure._id" v-for="measure in object.measuresnotinsections" :id="'listitem_'+measure._id" :key="measure._id">

            <div class="row section-item p-0" style =" flex-wrap: nowrap;" :class = "{'draghandle':draghandle}">  
              <div class="list-content d-flex align-items-center overflow-string" style ="min-width:0px" v-bind:class=" { 'text-muted': measure.mode=='deleting' } " > 
                <base-icon icon = "fas fa-grip-lines fa-lg" :class = "{'draghandle':!draghandle}"/> 
                <span v-if="systemgenerated(measure)" class="mr-1"><i class="fal fa-globe"></i></span>
                <span class = "overflow-string mr-2 pr-2" style ="min-width:0px" v-text="measure.name"></span>
              </div>

              <div class="list-content pt-0 pl-2 ml-auto d-flex align-items-center">
                <div class="actions " v-bind:id="'objactions_'+measure._id" >

                  <a href="#" v-if="measure.mode=='idle'" v-on:click.prevent.stop="tryremoveobjective(measure, 'tryremove')" class="actiondelete my-auto">Remove</a>

                  <div class ="my-auto ">
                    <b-button v-if="measure.mode=='deleting'" id="deletingbtn" :size ="btnsize" variant="danger"  @click = " tryremoveobjective(measure, 'confirm', $event)">Confirm?</b-button>
                  </div>



                </div>
              </div>
            </div> 
          </li>
        </ul>

      </div>
    </template>

    <div class="text-label mt-2">
      <div class="form-group"> 
        <vueselect id="search-objusers-objectives" @search:focus="ajustlengthdown" @search:blur="ajustlengthup" :options="availableobjectives"  placeholder="Click to link an objective..." label="name" @input="objectiveSelect" :resetOnOptionsChange="true" :clearSearchOnSelect="true" :closeOnSelect="true" @search="availableobjectivesupdate">

          <div class="my-auto noresult" slot="no-options">
            <span>Sorry, no matching options.</span> 
          </div>

        </vueselect>
      </div>
    </div>
  </div>

</div>
</template>
<style lang = "scss" spoce>

.badge{
  font-size: 85%;
}

.headername{
  display: flex;
  white-space: nowrap;
  min-width: 0px;
}

.objectname{
  color: var(--N800);
  margin-top: -8px;
}

h4{
  height: auto;
}
.vs__actions{
  display: none;
}

.draghandle {
  cursor: move;
}

.shadow{
  z-index: 400;
  border: 1px solid #d5dae3 !important;
}

.ghostClass{
  opacity: 0;
}

.upgradetoprolink{
  font-size: 14px;
  font-weight: normal;
}

.iconwrapper:hover i, .iconwrapper:focus i{
  font-weight: 700;
}

</style>
<script type="text/javascript"></script>
<script>

  import Sortable from 'sortablejs';
  import vueselect from '@/views/tools/vueselect'
  import Cockpitsapi from '@/services/api/cockpits'

  import {bus} from '@/main'

  export default {
    name:'objetivesTable',
    props:{
      checkforinvalidlink:Boolean,
      ttype:String,
      linkrobjeves: Boolean,
      setmaxuser:Object,
      myrole:String,
      isactive:Boolean
    },
    data(){
      return{
      availableobjectives:[],
      object:{}, // objective or cockpit
      groups:[],
      groupcntr:1,
      sections:null,
      tempsections:[],
      sortables:[],
      editsectionlabel:'',
      sectionerror:"",
      sectionnamehascontent: true,
      newmeasuresnotinsections: []
    }
  },
  watch:{

    setmaxuser( e, ev ){

      //console.log( e.licensetype, e.status, "ev")
    }
  },
  components:{ vueselect},
  mounted(){      
    bus.$on('objectivetableloadobjectives', this.initcomponent )
    bus.$on('onafterupdated', this.loadobjectives )

  },
  computed : {



    orgkey(){
      return this.$route.params.orgname
      // ? this.$route.params.orgname : this.$store.state.organizations.selectedorganizations.key
    },

    showupgrade(){
      
      var toreturn = false

      if( !this.object.setmaxuser || (this.object.setmaxuser && this.object.setmaxuser.licensetype != 'pro' && this.object.setmaxuser.status != 'request') ){
        toreturn = true
      }

      if( this.object.setmaxuser && this.object.setmaxuser.status == 'limited' ){
        toreturn = true
      }

      if( this.object.setmaxuser && this.object.setmaxuser.licensetype == 'pro' ){
        toreturn = false
      }

      return toreturn

    },
    btnsize(){
      return this.$store.state.screenType == 'mobile' ? 'sm' : ''
    },

    draghandle(){
      return this.$store.state.hasTouch
    },

    measuregroups(){

      var measuregroups = {"default":[]} 
      for( var i in this.object.measures ){

        if( this.object.measures[i].group ){
         measuregroups[ this.object.measures[i].group ] = this.object.measures[i]
       }else{
        measuregroups.default.push( this.object.measures[i] ) 
      }


    }

        //console.log( measuregroups )

        return measuregroups
        //return this.object.measuregroups
      },
      token(){
        return this.$store.getters.token
      },
      loggedinuser(){
        return this.$store.getters.user
      }
    },
    methods:{  

      systemgenerated( object ){

        let toreturn = false
        if( object.measuretype && ( object.measuretype == "risk" || object.measuretype == "masterplan" ) ){
          toreturn = true
        }

        return toreturn

      },  

      toupgrade(){

        this.$emit('ontoupgrade')

      },

      updatelabel(ev){

        if (!ev.target.value.replace(/\s/g, '').length) {
          this.sectionnamehascontent = false
        } else {
          this.sectionnamehascontent = true
        }
      },
      tosection : function( direction, cockpitid, sectionid, objectiveid ){

            //console.log( direction, cockpitid, sectionid, objectiveid )

            let self = this
            
            if( direction == "add" ){

              Cockpitsapi.Sectionadditem( cockpitid, objectiveid, sectionid, this.token)
              .then( function(response){

                /*if( response.data.updatedobjectives ){

                  for( var i in  self.tempsections ){

                    if( self.tempsections[i]._id == sectionid ){
                      //self.sections[i].objectivesdata = response.data.updatedobjectives

                      self.tempsections[i].objectivesdata = response.data.updatedobjectives

                      console.log(  self.tempsections[i].objectivesdata )

                    }
                  }

                  //console.log( self.sections, response.data.updatedobjectives)
                  
                }*/

              })

            }

            if( direction == "remove" ){

              Cockpitsapi.Sectionremoveitem( cockpitid, objectiveid, sectionid, this.token)
              .then( function(response){

              })

            }


            this.$emit('onupdate')
            

          },

          bindsortables : function(){


            let self = this

            $('.sectionitem').each( function(i,o){

              new Sortable( o  , {
                handle: ".draghandle",
                group: 'shared',
                animation: 150,
                delay:function(){return draghandle ? 0:500},
                chosenClass:"shadow",
                ghostClass:"ghostClass",


                onUpdate:function( event ){   

                  var parent = $(event.item).parents('.sectionitem')
                  var items = $(".list-group-item", parent)

                  var sectionid = event.target.id.replace("section_","")
                         // var objectiveid = event.item.id.replace("listitem_","")

                         var newlist = []
                         $( items ).each( function( i,o ){
                          var objectiveid = o.id.replace("listitem_","")
                          newlist.push(objectiveid)
                        })                          


                         self.updatesection( null, sectionid, "objectives", JSON.stringify(newlist) )

                         self.$emit('onupdate')

                       },

                       onAdd:function( event ){

                          //alert('')
                          var items = []

                          $( '.list-group-item', event.target ).each( function( i,o ){

                            var oid = o.id.replace("listitem_","")

                            items.push(oid)

                          })

                          var sectionid = event.target.id.replace("section_","")
                          var objectiveid = event.item.id.replace("listitem_","")
                          self.object.measuressections  = items
                          self.tosection( "add", self.object.id, sectionid, JSON.stringify(items))

                          //console.log("aaaa")

                        },
                        onRemove:function( event ){

                          var sectionid = event.target.id.replace("section_","")
                          var objectiveid = event.item.id.replace("listitem_","")

                          self.tosection( "remove", self.object.id, sectionid, objectiveid)

                          //console.log("bbb")

                        },
                      });
            })


          },  

          getsections : function( cockpit , callback){

            let self = this

            this.sections = {}

            Cockpitsapi.Sectiongetall( cockpit._id, this.token )
            .then( function(response){

              if( response.data.ok ){            

                self.sections = data.sections
                self.object.measuresnotinsections =  {}

                self.$nextTick( function(){                  
                  self.object.measuresnotinsections = data.measuresnotinsections
                  self.bindsortables()
                })

                if( callback ){
                  callback()
                }


              }else{
                //alert("invalid link detected")
              }

            })


          },

          updatesectionlabel : function( section ){

            let self = this
            for( var i in this.object.sections ){
              this.object.sections[i].action = ''
            }

            $('html').on('click',function() {
              if(section.action == 'editlabel'){
                section.action = ""
                section.error = ""
                self.editsectionlabel = section.label
              }
            });
            this.$nextTick(function(){
             $('#sectionwrapper_' + section._id).on('click', function(){
              event.stopPropagation();
            })
           })
            section.action='editlabel'
            this.editsectionlabel = section.label
          },

          updatesection : function( section, sectionid, field , newvalue){

            if( section  ){
              section.error = ""  
            }
            

            let self = this
            if( field == 'label' ){

              if( this.editsectionlabel == "" ){
               // console.log( section.error )
               section.error = "Invalid section label"
               // console.log( section.error )
             }else{
              Cockpitsapi.Sectionupdate( this.object.id, sectionid, 'label', this.editsectionlabel , this.token)
              .then( function(response){
                section.label = self.editsectionlabel
                self.$emit('onupdate')

              }).finally(()=>{
                section.action=''
                self.editsectionlabel = ''
              })
            }

          }

          if( field == 'objectives' ){

            Cockpitsapi.Sectionupdate( this.object.id, sectionid, 'objectives', newvalue, this.token)
            .then( function(response){
              self.$emit('onupdate')
            })


          }


        },

        removesection : function( act, section , object ){



          let self = this

          if( act == 'try' ){
           $('html').on('click',function() {
            if(section.action == 'tryremove'){
              section.action = ""
            }
          });

           setTimeout( function(){
            section.action = 'tryremove'
          },100)

         }

         if( act == 'confirm' ){


          $('html').on('click',function() {
            if(section.action == 'tryremove'){
              section.action = ""
            }
          });


          var newsections = []
          $.each( object.sections, function( i,o ){
                  //console.log(  )
                  if( o._id != section._id ){
                    newsections.push(o)
                  }else{

                    var lis =  $( 'li.list-group-item' , '#section_' + section._id )
                    $('#list-objlist').prepend( lis )
                      //console.log( )


                    }
                  })

          object.sections = newsections



              // update data     
              
              Cockpitsapi.Sectionremove( object.id, section._id, this.token )
              .then( function(response){
                if( response.data.ok ){
                  self.$emit('onupdate')                    
                }
              })
              

              


            }

            
          },

          resetsectionerrors(){

            for( var i in this.object.sections ){

              this.object.sections[i].error = ""
            }


          },

          addsection : function( cockpit ){

            this.resetsectionerrors()

            let self = this                      

            // update data

            //this.sectionerror = ""

            var newid = null
            Cockpitsapi.Sectionadd( cockpit.id, this.token )
            .then( function(response){
              if(response.data.ok ){
                newid = response.data.newid

                var section = { "_id": newid , "action": "", "cockpitid": cockpit.id, "label": "Default", "objectivesdata": [], "objectives": [], "sortorder": 0, "error":"" } 


                if( self.object.sections ){

                  self.object.sections.push(section)

                  self.sections = self.object.sections

                  self.tempsections.push(section) 

                  self.$nextTick( function(){
                   self.bindsortables()
                   self.$emit('onupdate')
                 }) 


                }


              }
            })


          },

          updatelist( newlist ){

            this.object.measureids = newlist
            this.$emit('onupdated', "measures", newlist )

          },

          addsortables(){

            let self = this

            Sortable.create( document.getElementById('list-objlist'), {handle:'.draghandle',sort: true, ghostClass:"ghostClass", group: 'shared', animation: 150, delay:function(){return draghandle ? 0:500}, chosenClass:"shadow", onRemove: function( event ){ 

               /*var items = $(".list-group-item", event.target)
                       
               //console.log( items , self.object.measuresnotinsections )

               self.newmeasuresnotinsections = []
               $( items ).each( function( i,o ){
                  var objectiveid = o.id.replace("listitem_","")

                  $( self.object.measuresnotinsections ).each( function( j,k ){

                    if( k._id == objectiveid ){

                      self.newmeasuresnotinsections.push(k)
                    }
                  })
                  
               })

               //console.log( self.newmeasuresnotinsections )


               self.object.measuresnotinsections = self.newmeasuresnotinsections*/

               



             }, onUpdate: function ( event ) {

               var items = $(".list-group-item", event.target)

               var newlist = []
               $( items ).each( function( i,o ){
                var objectiveid = o.id.replace("listitem_","")
                newlist.push(objectiveid)
              })
               for (var i in self.object.measuressections){
                newlist.push(self.object.measuressections[i])
              }

              self.updatelist( newlist )


            }, });

          },

          initcomponent( object ){

            let self = this
            this.object = object
            this.sections = this.object.sections
            this.loadobjectives( function(){
              self.addsortables()
              self.bindsortables()
            })
          },

          verifylink( selectedid , callback ){

        //let self = this

        Cockpitsapi.Verifylink( this.object._id, selectedid )
        .then( function(response){

          if( response.data.ok ){            
            //self.availableobjectives = response.data.measures

            if( callback ){
              callback()
            }
          }else{
            alert("invalid link detected 2")
          }

        })


      },

      doselected( selectedid){

        var newavailableobjectives = []
        for( var i in this.availableobjectives ){
          if( this.availableobjectives[i]._id != selectedid ){
            newavailableobjectives.push( this.availableobjectives[i] )
          }else{

            if( this.object.measuresnotinsections ){
              this.object.measuresnotinsections.push( this.availableobjectives[i] )
            }

            this.object.measureids.push( this.availableobjectives[i]._id )
            this.object.measures.push( this.availableobjectives[i] )

          }
        }

        this.availableobjectives = []
        this.availableobjectives = newavailableobjectives

        this.$emit('onupdated', "measures", this.object.measureids )

      },

      objectiveSelect( selected ){

        let self = this

        if( selected ){

          if( this.checkforinvalidlink ){
            this.verifylink( selected._id, function(){
              self.doselected( selected._id )
            })
          }else{
            self.doselected( selected._id )
          }          

        }     
      },

      availableobjectivesupdate( search, loading ){

        loading(true)

        let self = this

        var mids = this.object.measureids

        if( this.ttype == 'objectives' ){
          mids.push( this.object._id )
          // console.log(mids)          
        }


        Cockpitsapi.Getobjectives( search, mids, this.token, this.orgkey )
        .then( function(response){


          if( response.data && response.data.measures ){
            self.availableobjectives = response.data.measures
            //  console.log(self.availableobjectives)     
          }

        })
        .catch( function(){
        })
        .finally( () => {
          loading(false)
        })

      },

      tryremoveobjective( measure, action ){


        //let self = this

        if(this.activemeasure && this.activemeasure.mode){
          this.activemeasure.mode='idle'
        } 

        if( action=='tryremove'){
          this.activemeasure = measure
          measure.mode = "deleting"

          $('html').on('click',function() {
            measure.mode = "idle"
          });
        }

        if( action=='cancel'){
          this.activemeasure = measure
          measure.mode = "idle"
        }

        if( action=='confirm'){


         $('html').off('click',function() {
          measure.mode = "idle"
        });

         var newmeasures = []
         var newmeasureids = []
         var newmeasuresinsectionsids = []
         var newmeasuresinsections = []

         var removed = []
         var removedfromsection = []

         for( var i in this.object.measuresnotinsections ){

          if( this.object.measuresnotinsections[i]._id != measure._id ){
            newmeasureids.push( this.object.measuresnotinsections[i]._id )
            newmeasures.push( this.object.measuresnotinsections[i] )
          }else{
            removed = this.object.measuresnotinsections[i] 
          }

        }


        for(var i in this.object.sections){

          if( this.object.sections[i].objectivesdata ){

            var newsection = []

            for(var o in this.object.sections[i].objectivesdata){

                //console.log( this.object.sections[i].objectivesdata[o]._id , measure._id)


                if( this.object.sections[i].objectivesdata[o]._id != measure._id ){
                  newmeasuresinsectionsids.push( this.object.sections[i].objectivesdata[o]._id )
                  newmeasureids.push( this.object.sections[i].objectivesdata[o]._id )
                  newsection.push( this.object.sections[i].objectivesdata[o] )

                  //this.object.sections[i].objectivesdata
                }else{
                  removed = this.object.sections[i].objectivesdata[o]
                  removedfromsection = this.object.sections[i]
                }

              }

              this.object.sections[i].objectivesdata = newsection

            }

          }

          if(removedfromsection){

            this.tosection( "remove", this.object.id, removedfromsection._id, removed._id)

            //console.log("cccc")
          } else {
            this.tosection( "remove", this.object.id, removedfromsection, removed._id)
           // console.log("dddd")
         }
         this.availableobjectives.push( removed )

         this.object.measuresnotinsections = newmeasures
          //this.object.sections = newmeasuresinsections

          //this.object.measures = newmeasures
          
          this.object.measureids = newmeasureids
          this.$emit('onupdated', "measures", newmeasureids )

        }
        
      },

      searchObjectives( search){

        //loading(true)

        let self = this
        
        Cockpitsapi.Getobjectives( search, this.object.measureids, this.orgkey )
        .then( function(response){

          alert('x')

          if( response.data ){
            self.availableobjectives = response.data.measures
          }

        })
        .catch( function(){
        })
        .finally( () => {
           // loading(false)
         })
      },

      loadobjectives( callback ){

        let self = this        

        var mids = this.object.measureids

        if( this.ttype == 'objectives' ){
          mids.push( this.object._id )          
        }


        Cockpitsapi.Getobjectives( "", mids, this.token, this.orgkey )
        .then( function(response){
          if( response.data ){

            if(  response.data.measures ){
              self.availableobjectives = response.data.measures
            }           


            if( callback ){
              self.$nextTick( callback() )
            }
            
          }
        })

      },
      ajustlengthdown(){
        /*this.$nextTick(function(){
          var height = $('.vs__dropdown-menu').height()
          //console.log(height )
          var modalheight = $('.modal-body').height()
          $('.modal-body').height(height + modalheight + 'px')

          
        })*/
      },
      ajustlengthup(){
       //$('.modal-body').height('auto')
     }

   }

 }

</script>