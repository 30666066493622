<script type="text/javascript"></script>
<template>
    <div :id="'statisticitem_' + objective._id"  >
          <div  v-if="!objective.name" > 
            <div  class="card mb-2">
              <div class="card-body">
                <h4> {{ placeholder }} </h4> 
                <i class="fa fa-circle-o-notch fa-spin"></i> 
             </div>
            </div>
          </div>

          <div  v-if="objective.name && objective.isactive == true || ( objective.isactive == false && objective.myrole == 'owner' )" > 

            <div v-if="objective.name"> 
                  <div class="card mb-2">  
                    <div class="card-body">
                      <h4 class="my-2 ml-2"> 

                          {{objective.name}} <small v-if="objective.noaccess == false"> ( {{ objective.numofusers }}  <span v-if="objective.numofusers>1">members</span> <span v-if="objective.numofusers<=1 ">member</span> ) </small>
                          
                          <span class="badge badge-light" v-if="objective.isactive==true && objective.noaccess == true && objective.userstatus != 'pendingjoin'">No access</span>
                          <span class="badge badge-light" v-if="objective.isactive==true && objective.noaccess == true && objective.userstatus == 'pendingjoin'">Approval pending</span>
                          <span class="badge badge-light" v-if="objective.isactive==false">Inactive</span>

                      </h4>

                      <template v-if="objective.noaccess == false">

                        <div> 
                          <b-table-lite :table-class="'tablelite'" @row-hovered="oncellhovered" @rrow-unhovered="oncellunhovered" :sticky-header="true"
      :no-border-collapse="true" hover borderless :items="stattable" :fields="fields">

        
                          <template #cell()="data">
                           

                           <template v-if="data.value.value=='green'||data.value.value=='yellow'||data.value.value=='red'||data.value.value=='blue'">

                            <div data-boundary='viewport' :data-toggle="hastooltips" data-placement="top" v-bind:title="data.value.date | dateformatstr" data-trigger="hover" v-on:click="$emit('oncolumnclick', $event, data.value.indicatorid, objective._id, objective)">
                           <base-icon class = "showdatetooltip">
                                <template slot = "icon">
                                  <span v-html="$options.filters.statusoptionicon({'color':data.value.value})"/>
                                </template>
                            </base-icon>
                          </div>

                            </template>

                            <template v-else>

                              <div v-if="data.value.indicatorid" v-on:click="$emit('oncolumnclick', $event, data.value.indicatorid, objective._id, objective)">
                                {{ data.value.value }}
                              </div>
                              <div v-else>
                                {{ data.value.value }}
                              </div>
                              
                              

                            </template>


                          </template>
                          </b-table-lite>
                        </div>

                      <div class = "row" style = "flex-wrap: nowrap;" :id="'DataTables_Table_'+objective._id" v-if="1==2 && objective.isactive == true || ( objective.isactive == false && objective.myrole == 'owner' )"> 
                        <div class = "d-flex flex-column colname" width="155">
                          <div class=" ml-auto headcol top-col" height="31" style="background-color: #ffffff;">&nbsp;</div>
                          <div class=" ml-auto headcol"   height="31" style="background-color: #ffffff;">Views/Users</div>
                          <div class=" ml-auto headcol"  height="31" style="background-color: #ffffff;">Likes/Dislikes</div>
                          <div class=" ml-auto headcol"   height="31" style="background-color: #ffffff;">Comments</div>  
                        </div>
                        <div class = "clearfix"></div>
                        <div class ="mainstatistics statstable row m-0 pb-2" style = "flex-wrap: nowrap;">

                          <div class = "stautsindicator" data-boundary='viewport'  :data-toggle="hastooltips" data-placement="top" v-bind:title="indicator.indicatordate | dateformatstr" data-trigger="hover" v-on:click="$emit('oncolumnclick', $event, indicator._id, objective._id, objective)" v-for="indicator in objective.indicators" :key="indicator._id">

                              <base-icon class = "showdatetooltip">
                                <template slot = "icon">
                                  <span v-html="$options.filters.statusoptionicon(indicator)"/>
                                </template>
                              </base-icon>



                              <div class ="stauts">
                                <span v-if="indicator" style="position: relative;">
                                  <span v-text="indicator.viewcount == 0 ? '-': indicator.viewcount">-</span>/<span v-if="indicator.viewuserscountiscomplete" v-text="indicator.viewuserscount == 0 ? '-': indicator.viewuserscount">-</span><span v-if="indicator.viewuserscountiscomplete == false">-</span>
                                  <span v-if="indicator.viewsactivity.ishigh" class="notify"> <span class="heartbit"></span> <span class="point"></span> </span>
                                </span>
                                <span v-if="!indicator">&nbsp;
                                </span>
                              </div>

                              <div class ="stauts" nowrap ="">
                              
                              <span v-if="indicator" style="position: relative;" >
                                <span v-text="indicator.likescount == 0 ? '-': indicator.likescount">0</span>/<span v-text="indicator.dislikescount  == 0 ? '-': indicator.dislikescount">0</span><span v-if="indicator.numlikesindicator.ishigh" class="notify"> <span class="heartbit"></span> <span class="point blue"></span></span>
                              </span>
                              <span v-if="!indicator">&nbsp; 
                              </span>
                                
                              </div>

                              <div class ="stauts">
                                <span v-if="indicator" style="position: relative;" >
                                <span v-text="indicator.commentscount == 0 ? '-': indicator.commentscount">0</span>
                                <span v-if="indicator.commentactivity.ishigh" class="notify"> <span class="heartbit"></span> <span class="point"></span> </span>
                                </span>
                                <span v-if="!indicator">&nbsp;
                                </span>

                              </div>
                            </div>

                            <div id ="end" class = "clearfix"></div>
                        </div>
                      </div>

                      </template>
                    </div></div></div>

              </div>

      </div>        
</template>

<style type="text/css">
  
    .table.tablelite{

      width: auto !important;
    }
    .table.tablelite th{
       padding: 0rem !important;
      text-align: left !important;
      width: 35px !important;
      padding-right: 10px;
    }
    .table.tablelite td {
      padding: 0rem !important;
      text-align: center !important;
      width: 35px !important;
    }

     .statstable-selected{
      background-color: #f5f6f8 !important;
      cursor: pointer;
    }

</style>

<style scoped lang ="scss">
    @import '@/scss/abstracts/variable.scss';
    @import'@/scss/abstracts/mixin.scss';
    .top-col{
      margin-top: 21px;

    }
    .colname{
      width: 110px;
    }




    @include media-max-width($breakpoints-sm, $breakpoints-md){

        .top-col{
           margin-top: 33px;
        }
    }
        .statstable td{
          width: 50px;
          text-align: right;
        }
</style>

<script>

  import Cockpitsapi from '@/services/api/cockpits'

  import moment from 'moment';
  import {bus} from '@/main'

	export default{
		name:'statistic',
		props:{
      objectivedata:Object,
			objectiveid:String,
      placeholder:String
		},

		data(){
			return{
        
        objective:{},
        token:"",
        loading:true
			}
		},
    computed:{
      hastooltips(){
        return !this.$store.state.hasTouch ?  'nothing': 'tooltip'
      },

      fields(){

        let toreturn = []

        toreturn.push( { key: 'label', stickyColumn: true, isRowHeader: true, label: '' } )

//[ { key: 'label', stickyColumn: true, isRowHeader: true }, { key: 'label', stickyColumn: true, isRowHeader: true } ]
        
        let cols = 0
        this.objective.indicators.forEach(function (element) {
          toreturn.push( { key: "column_"+cols, label: '', class: 'selectablecol' } )

          cols += 1
        })

        return toreturn
      },

      stattable(){

        let toreturn = []

        let titles = [ "", "Views/Users", "Likes/Dislikes", "Comments"]

        for( let i=0; i < 4; i++ ){

           let row = {}

            row.label = {"value":titles[i]}

            let cols = 0

            this.objective.indicators.forEach(function (element) {


              if( i == 0 ){
                row["column_"+cols] = { "indicatorid":element._id, "date":element.indicatordate , "value":element.color}  
              }

              if( i == 1 ){

                let val = ""

                if( element.viewuserscountiscomplete == false ){
                
                  val = "-"
                
                }else{

                  val = element.viewcount == 0 ? '-': element.viewcount
                  val += "/"
                  val += element.viewuserscount == 0 ? '-': element.viewuserscount

                }               

                //row.push( val )  
                row["column_"+cols] = { "indicatorid":element._id, "date":element.indicatordate, "value":val}  
                 /*
                <span v-if="indicator.viewsactivity.ishigh" class="notify"> <span class="heartbit"></span> <span class="point"></span> </span>
                */
              }

              if( i == 2 ){

                let val = ""

                if( element.viewuserscountiscomplete == false ){
                
                  val = "-"
                
                }else{

                  val = element.likescount == 0 ? '-': element.likescount
                  val += "/"
                  val += element.dislikescount == 0 ? '-': element.dislikescount

                }               

                //row.push( val )  
                row["column_"+cols] = { "indicatorid":element._id, "date":element.indicatordate, "value":val} 
/*<span v-if="indicator.numlikesindicator.ishigh" class="notify"> <span class="heartbit"></span> <span class="point blue"></span></span>
                              </span> */
              }


               if( i == 3 ){

                let val = ""

                if( element.viewuserscountiscomplete == false ){
                
                  val = "-"
                
                }else{

                  val = element.commentscount == 0 ? '-': element.commentscount
                  

                }               

                //row.push( val )  
                row["column_"+cols] = { "indicatorid":element._id, "date":element.indicatordate, "value":val} 
/* 
                                <span v-if="indicator.commentactivity.ishigh" class="notify"> <span class="heartbit"></span> <span class="point"></span> </span>
                                </span>
                                <span v-if="!indicator">&nbsp;
                                </span> */
              }


              
              cols += 1

            })

           toreturn.push( row )

        }

        return toreturn
      }


    },
		watch:{
		},
		components:{
      
		},
		mounted(){

      this.token = localStorage.token

      
      //if( this.objectivedata != undefined && this.objectivedata.noaccess == false ){
       this.getstatistics()
      //}else{
      //  this.objective = this.objectivedata
     // }      
      
      bus.$on('updatedcommentscount',this.updatedcommentscount)     
      bus.$on('updatedlikecount',this.updatedlikecount)

		},
		methods:{

      oncellunhovered( i,o,p ){

        console.log( p.target.cells[o] )

      },

      oncellhovered( i,o,p ){

        //console.log(  )

        let element = null

        if( p.target.cells[o] ){
          element = p.target.cells[o]
          element.classList.add("rowhover");
        }

        

      },

      updatedlikecount(oid, iid, like, dislike){

          if(this.objectiveid == oid){
          this.objective.indicators.find(x => x._id == iid).likescount = like
          this.objective.indicators.find(x => x._id == iid).dislikescount = dislike
        }
      },
    updatedcommentscount(oid,iid, count){
        if( this.objectiveid == oid){

          this.objective.indicators.find(x => x._id == iid).commentscount = count
        }
      },
      getstatistics(){

        let self = this

        this.cockpitstatistics = []
        this.loading = true

        Cockpitsapi.Objectivestatistics( this.objectiveid, this.token )
        .then( function(response){
          
          if( response.data.ok ){    

           // console.log( response.data.measure, "emit1")

            self.objective = response.data.measure     

            if (self.objective){
              //alert('x')
              //self.$emit('onobjectivesstatisticsload', self.objective )

             // self.$store.dispatch('addObjectiveToStatistics',{ objective:self.objective })
            }
            

            setTimeout( function(){
                if(self.$store.state.hasTouch){
              var card = $('#statisticitem_' + self.objective._id)
              var tooltip = $('[data-toggle="tooltip"]',card)
               $(function () {
                  tooltip.tooltip({delay: {show:500, hide:0}})
                })

             tooltip.on('mouseenter', function(e){
              
                tooltip.each(function(){
                  var newself = this
                  setTimeout(function(){
                    $(newself).data('bs.tooltip').config.delay = {show:0, hide:0}
                       },300)                   
                  }) 
                
                }).on('mouseleave',function(e){
                tooltip.each(function(){
                  var newself = this
                  setTimeout(function(){
                    $(newself).data('bs.tooltip').config.delay = {show:500, hide:0}
                    })
                   })
              
              })
             

            }
                
                var taget = $('#end',card)

                if( taget.position() ){
                  $('.mainstatistics',card).animate({scrollLeft: taget.position().left}, 500);
                }
               


            }, 600)

            self.loading = false
             
            
          }

        }).finally(()=>{

           //setTimeout( function(){



              

             //alert('')

           // }, 200 )

          


          $('td').mouseover(function(){
            let self = this
            $('*').removeClass('statstable-selected')
            var tableC =  $(this).closest( "table" )
            var tableid = tableC.attr('id')
            var table = $('#'+tableid +' td').each(function(index){
              if(self.cellIndex == this.cellIndex){
                $(this).addClass('statstable-selected')
                 // console.log('this ',self.cellIndex, ':', ' td ', this.cellIndex, ':', ' td all ', this )
              }
              
              })
            })
           $('td').mouseout(function(){
           
            $('*').removeClass('statstable-selected')
          })
        })


      }

		}
	}
</script>