<template>
 
  <b-modal v-model="isshown" id ="membersmodal" size="sm"   hide-footer @hidden="modalhidden" @shown = 'modalshown'>

    <template v-slot:modal-header>
      <h3 class="modal-title">Members</h3>        

      <a class = "close-modal dialog-close-button">
        <i class="fal fa-times fa-lg " @click="isshown = false"></i>
      </a>
    </template>
    <!-- <div class="table-responsive" v-if="members.length && myrole != 'owner'">
     <div class="dataTables_wrapper">
      <table id="myTable" class="table table-basic table-hover">
        <thead>
          <tr>
            <th class="text-left" style= "border: none">Name</th> 
            <th class="text-center" style= "border: none">Role</th>
          </tr>
        </thead> 
        <tbody>
          <tr v-for = "member in members" :key ="member.userid" >
            <td class="text-left" v-if="member.user.firstname"><span>{{member.user.firstname}}</span> <span>{{member.user.lastname}}</span></td>
            <td class="text-left" v-else><span>{{member.user.email}}</span></td>
            <td class="text-center"><span>{{member.role}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> 
  <pre> {{ members }} </pre>-->



 

   <usersTable :working="isworking" :members="members"  @onupdated="oncockpitupdated" @onusersupgraded="usersupgraded" :membersmodaltype="membersmodaltype"></usersTable>

   <p class="clearfix">&nbsp;</p>
</b-modal>

</template>

<script>
    import usersTable from './usersTable'
    import {bus} from '@/main'
    import Cockpitsapi from '@/services/api/cockpits'
    import Teamsapi from '@/services/api/teams'
  

  export default {
    name:'membersModal',
    props:{
      members:{
        type:Array,
        default:()=>[]
      },
      checkforinvalidlink: Boolean,
      membersmodaleid:String,
      membersmodaltype:String,
      shown:Boolean,
      myrole:String,
      loggedinuser:Object,
      cockpitid:String,
      token:String,
      isactive:Boolean
      
    },
    data(){
      return{
        isshown : false,
        cockpit : {},
        objective: {},
        showbanner:true,
        isworking:false
      }
    },
    watch:{

      shown : function( e ){
        this.isshown = e
      }
    },
    components:{      
      usersTable
    },
    mounted(){ 

      this.cockpit = {}

    },

    computed:{

      orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      }
      
    },

    methods:{

      usersupgraded(){
        //onusersupgraded
        this.isshown = false
      },

       oncockpitupdated( field, measureids, removed ){

        //console.log( removed , "removed")

        if(this.membersmodaltype == "cockpit"){
          this.cockpitupdate( field, measureids, removed);
        } else if(this.membersmodaltype == "objective"){
          this.objectiveupdate( field, measureids, removed)
        } 

      },

      cockpitupdate( field, value, removed, callback ){

        this.isworking = true
        
        var savedvalue = JSON.stringify(value)      
        let self = this

        Cockpitsapi.Cockpitsave( { "t":this.token, "field":field, "value":savedvalue, "cid":this.cockpit.id, "context":this.orgkey , "removed":JSON.stringify(removed) } )
        .then( function(response){
          //console.log(response)
          if( response.data.ok ){

            self.isworking = false

            /*var userwithupdate = removed.added ? removed.added : ( removed.remove ?  removed.remove : ( removed.updated ? removed.updated : null ) )

            var action = ""

            if( removed.added ){
              action = "add"
            }
            if( removed.remove ){
              action = "remove"
            }
            if( removed.updated ){
              action = "update"
            }*/

            if( removed.remove ){
              Teamsapi.Updateuserstatus( removed.remove, self.orgkey, "remove" )
            }

            if( removed.added ){
              Teamsapi.Updateuserstatus( removed.added, self.orgkey, "add" )
            }

            console.log( removed, "pppoopppo")


            if( removed.remove && removed.action == 'removeuser'  ){
              self.$emit('oncockpitupdated' , { 'removed':removed.remove } )
            }if( removed.remove && removed.action == 'removeteam'  ){
              self.$emit( 'oncockpitupdated' , { 'removed':removed.remove } )
            }if( removed.added && removed.action == 'addteam'  ){
              self.$emit( 'oncockpitupdated' , { 'added':removed.added, 'selected':removed.selected } )
            }if( removed.approved && removed.action == 'approveuser'  ){
              self.$emit( 'oncockpitupdated' , { 'approved':removed.approved } )
            }if( removed.rejected && removed.action == 'rejectuser'  ){
              self.$emit( 'oncockpitupdated' , { 'removed':removed.rejected } )
            }else{
              self.$emit('oncockpitupdated')
            }
            

            

            if( callback ){
              callback()
            }
          }else{

            self.isworking = false
          }


        })
        .catch( function(){
            //self.cockpitsettingsmodalshown = false

            self.isworking = false

          })
        .finally( () => {

          self.isworking = false
        })
        

      },

     modalhidden(){


      //history.pushState("", document.title, "/dashboard/cockpit/id_" + this.cockpit.id );

      bus.$off('userstableloadusers')
      bus.$off('onafterupdated')

      this.$emit('onmodalhidden')
      this.$func.removeQuery(['page','oid'],this.$route,this.$router)
      this.isshown = false
      this.cockpit={}
      this.objective={}
      },


  modalshown(){

      let self = this
      if(this.membersmodaltype == 'cockpit'){

        this.cockpitsettings( function(){         

          /* for( var i in self.cockpit.users ){
            console.log( i, self.cockpit.users[i] , "ppppp")
          }*/


              bus.$emit('userstableloadusers', self.cockpit, self.token, self.loggedinuser, self.myrole, 'cockpit', self.isactive)          
        })

      }else{

        this.objectivesettings( function(){


          /*         for( var i in self.objective.users ){
            console.log( i, self.objective.users[i] , "ppppp")
          }*/

             bus.$emit('userstableloadusers', self.objective, self.token, self.loggedinuser, self.objective.myrole, 'objective', self.objective.isactive )
          })
       }
  },

  objectiveupdate( field, value, removed, callback ){

    //console.log( removed, 'membersmodal')

    this.isworking = true

      var  savedvalue = JSON.stringify(value)
      
      

      let self = this

      Cockpitsapi.Objectivesave( { "field":field, "value":savedvalue, "t":this.token, "mid":this.objective._id, "context":this.orgkey, "removed":JSON.stringify(removed) } )
      .then( function(response){

        if( response.data.ok ){

          self.isworking = false


         /* var userwithupdate = removed.added ? removed.added : ( removed.remove ?  removed.remove : ( removed.updated ? removed.updated : null ) )


            var action = ""

            if( removed.added ){
              action = "add"
            }
            if( removed.remove ){
              action = "remove"
            }
            if( removed.updated ){
              action = "update"
            }*/


          //self.members = []

          if( removed.remove ){
            Teamsapi.Updateuserstatus( removed.remove, self.orgkey, "remove" )
          }


          if( removed.added ){
            Teamsapi.Updateuserstatus( removed.added, self.orgkey, "add" )
          }  

          if( removed.remove && removed.action == 'removeuser'  ){
            self.$emit( 'oncockpitupdated' , { 'removed':removed.remove } )
          }if( removed.remove && removed.action == 'removeteam'  ){
            self.$emit( 'oncockpitupdated' , { 'removed':removed.remove } )
          }if( removed.added && removed.action == 'addteam'  ){
              self.$emit( 'oncockpitupdated' , { 'added':removed.added, 'selected':removed.selected } )
          }if( removed.approved && removed.action == 'approveuser'  ){
              self.$emit( 'oncockpitupdated' , { 'approved':removed.approved } )
          }if( removed.rejected && removed.action == 'rejectuser'  ){
            self.$emit( 'oncockpitupdated' , { 'removed':removed.rejected } )
          }else{
            self.$emit('oncockpitupdated')
          }
          

          if( callback ){
            callback()
          }
        }


      })
      .catch( function(){

        self.isworking = false
            //self.cockpitsettingsmodalshown = false
          })
      .finally( () => {

        self.isworking = false
      })
      

    },



    objectivesettings( callback ){

      let self = this
      
      Cockpitsapi.Objectiveedit( this.membersmodaleid,  this.token, this.orgkey )
      .then( function(response){
        // console.log(response)
        if( response.data ){


          self.objective = response.data.measure

          /*for( var i in self.objective.users ){
            console.log( i, self.objective.users[i] , "pppppa")
          }
**/
          if( callback ){
            callback()
          }

        }

      })
      .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
      .finally( () => {
      })

      
      
    },

    cockpitsettings( callback ){

        let self = this
        
        Cockpitsapi.Cockpitedit( this.membersmodaleid, "members", this.token, this.orgkey )
        .then( function(response){

          if( response.data ){

            self.cockpit = response.data.cockpits

          /* for( var i in self.cockpit.users ){
            console.log( i, self.cockpit.users[i] , "pppppa")
          }*/


            if( callback ){

              setTimeout( function(){
                callback()
              }, 200)
              
            }

            
          }

        })
        .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
        .finally( () => {
        })

        
        
      },

    }
}

</script>