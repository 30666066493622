<template>
  <b-modal id="addstatustoaggregator" v-model="isshown" size="md" no-close-on-backdrop no-close-on-esc @hidden="modalhidden" @shown="modalshown" hide-footer>
      <template v-slot:modal-header>
         <!-- <button v-if="!validforposting && !deviseDesktop" type="button" aria-label="Close" class="close" disabled style ="margin-left: auto auto auto 0;  padding:0; font-size:16px;"> Add indicator</button> -->
       
       
      <h3 class="modal-title"><span>Add status update</span></h3>
      <a class = "close-modal dialog-close-button mr-2">
        <i class="fal fa-times fa-lg" @click="isshown = false"></i>
      </a>
    </template>
        

      <div>
           

       <h3 class ="font-weight-bold">{{objective.name}}</h3>
       <div class ="diverder"></div>
         <h4 >Linked objectives</h4> 

        <table class="table response-table">
          
          <addstatustoaggregatoritem @onitemloaded="itemloaded" @onitemloadederror="itemloadederror" :measureid="measure._id" v-for="measure in objective.measures" v-bind:key="measure.id"></addstatustoaggregatoritem>

        </table>


      <h4 v-if="measuresloaded && !haserrors" >Status for this update (  <small v-html="optionshtml"></small> ):</h4>


      <div class = "indicaterselecter" :class ="aggragatedindex.color"  >
      <vueselect :searchable="false" :clearable = "false" v-model="aggragatedindex" disabled>
      
         <template #selected-option="{ refid, color, icon, indexvalue, label, weight }">
          <div class ="d-flex flex-row">
            <div class ="icon-holder d-flex">
              <span v-html="$options.filters.statusoptionicon(aggragatedindex, 'NA')"></span>
            </div>
            <div class ="plashoder-height"></div>
            <span class ="my-auto vs__text">{{label}}</span>
          </div>
        </template>  
      </vueselect>
    </div>


        <div v-if="measuresloaded">          


         <div class = "align-self-center m-2" v-if="haserrors">

          <div sizem="sm" class="base-icon-label danger nav-icon asalink" @click="moreerr()">
            <span class="iconlabelbefore overflow-string iconlabel">Unable to determine status</span>
            <div class="icon-md  forcesm">
              <span><i v-if="!showerror" class="fa-do-not-enter far" aria-hidden="true"></i> <i v-if="showerror" class="fa-do-not-enter fas" aria-hidden="true"></i>
              </span>
            </div>
          </div>
       </div>

       

        <div v-if="haserrors && error!='' && showerror" class="text-danger newpadding R100-b">

             <span  ><p>We are unable to determine the status of this update due to access reasons or because some linked objectives are missing status updates. </p> In order to proceed, you must have access to all linked objectives and they must be active and have at least one status update available.</span>
          
        </div>

        <basedonaggregation :ispadded="true" :haserrors="haserrors" :aggregationmethod="objective.aggregationmethod"></basedonaggregation>


        

        <div v-if="measureloadedwitherror" class="text-danger newpadding R100-b">We are unable to determine the status of this update, details are incomplete. Kindly refresh the page.</div>


        </div>
        
        <div class="form-group smt-2">
          <froala id="statuscommenteditor" :tag="'textarea'" :config="froalaconfig" class="mb-0" :onManualControllerReady="initializefroala" v-model="message"></froala>
        </div>


        </div>
        <div class = "form-group text-right">
            Note: Status updates cannot be changed once published.
            <span class="text-danger hide">Permission denied, status not posted.</span>
            <div class = "mt-2 mb-2">
              <button-gruppe default="none"  text1="Save and publish" :loading = "status == 'loading'" @no ="cancelloadautocomplete(); isshown = false" @yes = "addnewindicator()" :disabled2="validforposting == false || imageuploading == true"></button-gruppe>
            </div>

        </div>


        <b-modal v-model="autosavecontentfound" modal-class="" size="md" no-close-on-backdrop no-close-on-esc scrollable>
          <template v-slot:modal-header>
                <h3 class="modal-title">Autosaved content found</h3>
                <a class = "close-modal dialog-close-button mr-2">
            <i class="fal fa-times fa-lg " @click="autosavecontentfound = false"></i>
          </a>        
          </template> 

          <p> We recovered autosaved content from a previous session.
      Click ‘OK’ to load it, or ‘Cancel’ to discard it and start over. </p>

          <template v-slot:modal-footer="{ cancel, ok }">

            <button-gruppe @no ="cancelloadautocomplete(); isshown = false" @yes = "loadautocomplete()"></button-gruppe>
            
          </template>

        </b-modal>


      </b-modal>
</template>
<style lang ="scss">

  .asalink{
    cursor: pointer;
  }
  .vs--disabled{
   .vs__dropdown-toggle, .vs__search{

    background:#fff !important;
   }

  }
  


</style>
<script type="text/javascript"></script>

<script>

  import Cockpitsapi from '@/services/api/cockpits'
  import vueselect from '@/views/tools/vueselect'
  import addstatustoaggregatoritem from '@/views/cockpit/components/addstatustoaggregatoritem.vue'
  import basedonaggregation from '@/views/cockpit/components/basedonaggregation'

  export default {
    name:'addStatustoaggregator',
    props:{
      shown:Boolean,
      cockpitid:String,
      token:String,
      objectiveid:String,
      deviseDesktop: Boolean,
      objectivedata:Object
    },

    data(){
      return{
        aggragatedindex : {},
        editorvalue : "",
        isshown : false,
        showinstruction : false,
        showerror : false,
        objective : {},
        measuresloaded: false,
        statustext : "",
        measurelisted : 99999999999,
        measureverified : 0,
        measureloaded : false,
        measureloadedwitherror : false,
        froalaconfig: {},
        measures : [],
        validmeasures : [],
        noaccessmeasures : [],
        optionshtml : "",
        statusoptionicons : [],
        indicatorids : [],
        indicators : [],
        isworking : false,
        linkInitControls : null,
        message :"",
        autosavecontentfound : false,
        autosave : {},
        status: "idle",
        newindicatorid:'',
        imageuploading : false,
        calculatedindex : "",
        calculatedindexcolor : "",
        haserrors : false,
        error : "",
        options:[
          {html: '<i class = "far fa-check">', label: 'On track', value:'green', key:1.20, classed:'green',icon:true},
          {html: '<i class = "far fa-times">', label: 'Off track', value:'red', key:0.80, classed:'red', icon:true}
        ],
        
      }
    },
    
    computed:{

      organization(){
        return this.$store.state.organizations.selectedorganizations
      },

      validorg(){

        if( this.organization.key && ( this.$route.params.orgname != this.organization.key )  ){
          return false
        }else{
          return true
        }


      },

      orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      },
      
      calculatedmethod(){
        let aggregationmethod = 'Best'
        if(this.objective.aggregationmethod == 'average'){aggregationmethod = 'Average/Low'}
        else if(this.objective.aggregationmethod == 'optimisticaverage'){aggregationmethod = 'Average/High'}
        else if(this.objective.aggregationmethod == 'worst'){aggregationmethod = 'Worst'}
        return aggregationmethod
      },

      userid(){
        return this.$store.state.user.userInfo._id
      },
            
      preselecter(){
        return this.indicatoroptions[0] 
      },


      validforposting(){

        //var isvalid = false
        //var proceed = true

        return !this.haserrors && this.measuresloaded
      }
    },
    watch:{

        shown : function( e ){
          this.isshown = e
        }
    },
    components:{addstatustoaggregatoritem,vueselect,basedonaggregation },
    mounted(){     
        let self = this
        this.froalaconfig = this.$store.state.config
        this.froalaconfig.placeholderText = 'Add description…'
        this.froalaconfig.heightMax = 400
        this.froalaconfig.events = {
          'image.beforeUpload': function (images, lo ) {
            self.imageuploading = true        
          },
          'image.inserted': function ($img, response) {
            self.imageuploading = false
          }
        }

        $.getJSON( "https://s3token.flowe.io/", function(data){
          self.froalaconfig.imageUploadToS3 = data        
        })
      
    },

    methods:{

      moreerr(){


        if( this.showerror == true ){
          this.showerror = false
        }else{
          this.showerror = true
        }
      },

      itemloadederror(){

        this.measureloadedwitherror = true

      },

      itemloaded( measure ){

        //let self = this

        var color = ""
        var indicatorid = ""

        this.measuresloaded = false

        if( measure.noaccess == true ){
          
          this.indicators.push( "noaccess" )  
        
        }else if( measure.isactive == false ){

          this.indicators.push( "inactive" ) 

        }else{

          if( measure.indicator ){


            if( measure.indicator.altindicator ){

              if( measure.indicator.altindicator.statusoption ){
                color = measure.indicator.altindicator.statusoption.color
              }else{
                color = measure.indicator.altindicator.color
              }

              indicatorid = measure.indicator.altindicator._id

            }else{

              if( measure.indicator.statusoption ){
                color = measure.indicator.statusoption.color
              }else{
                color = measure.indicator.color
              }

              indicatorid = measure.indicator._id

            }


            this.indicatorids.push( indicatorid )
            this.indicators.push( color )          

          }else{
            this.indicators.push( "empty" )
          }

        }

        //console.log(this.indicators.length, this.objective.nodes.length)
        if( this.indicators.length == this.objective.nodes.length ){

          this.getaggragatedindex()
          this.measuresloaded = true
        }else{
          this.error = "nolinkedobjective"
          this.measuresloaded = false
        }

      },

      getaggragatedindex(){


        this.haserrors = true
        this.calculatedindex  = ""
        this.calculatedindexcolor = ""

        var options = []

        var statusoptionicons = []

        let self = this 

       $.each( this.objective.statusoptions, function(i,o){

        if( o.isdefault && o.indexvalue != 0 ){
          options.push(o.color)
          statusoptionicons.push( self.$options.filters.statusoptionicon(o) ) 
        }
       
       })

       this.optionshtml = statusoptionicons.join(", ")

       //console.log(this.optionshtml)

        
        Cockpitsapi.Aggregatedindex( this.indicators.join(','), options.join(','), this.objective.aggregationmethod,this.token )
        .then( function(response){

          if( response.data.ok ){
            if(response.data.indexvalue == null){
              self.aggragatedindex = {color: null, label: ""}
            }else{
              self.aggragatedindex = response.data.indexvalue
            }
            

            self.haserrors = false
            self.calculatedindex  = response.data.indexvalue.indexvalue
            self.calculatedindexcolor = response.data.indexvalue.color

          }else{

            self.error = response.data.error
            self.haserrors = true
          }

        })
        .catch( () => {

          self.haserrors = true
        })
        .finally( () => {

        })


      },

      tofixednumber( value){

        var toreturn = ""

        if( isNaN( parseFloat(value) ) == false ){
          toreturn = value.toFixed(2)
        }else{
          toreturn = value
        }

        return toreturn
      },

      modaldismissed(){

        this.isshown = false
        //this.cancelloadautocomplete()

      },

      cancelloadautocomplete(){

        this.autosave = {}
        Cockpitsapi.Autosave( "status_" + this.objective._id, "", localStorage.token )
        this.autosavecontentfound = false
    
      },

      loadautocomplete(){

        this.message = this.autosave.content
        
        //Cockpitsapi.Autosave( "status_" + this.objective._id, "", localStorage.token )
        //this.autosave = {}

        this.autosavecontentfound = false

      },

      initializefroala: function(initControls) {
        this.linkInitControls = initControls;
      },



      addnewindicator(){

        if( this.validorg ){        

          this.status = 'loading'
          var editorvalue = this.linkInitControls.getEditor().html.get()
          //console.log( , 'add status')

          var optionid = this.aggragatedindex._id
          var postdata = { "statusoptionid": optionid , "cockpitid":this.cockpitid, "userid": this.userid, "isaggregator":true, "measureid": this.objective._id, "calculatedindex":this.calculatedindex, "calculatedindexcolor":this.calculatedindexcolor, "statusmessage":editorvalue, "statusmessagetext": editorvalue,"includedids":JSON.stringify(this.indicatorids) }

          //console.log( postdata )

            let self = this 
            Cockpitsapi.Objectiveindicatorsadd( postdata )
                  .then( function(response){

                    if( response.data.ok ){

                      self.$emit('onaddnewindicatorsuccess', self.objective._id, response.data.ind )
                    }

                  })
                  .catch(error => console.log(error))
                  .finally( () => {
                    this.status = 'idle'
   

                  })


          }

        },

      modalshown(){

        let self = this

        this.froalaconfig = this.$store.state.config
        this.froalaconfig.heightMax = 400
        this.froalaconfig.placeholderText = 'Add status description…'
        this.froalaconfig.events = {
          'image.beforeUpload': function (images, lo ) {
           // alert('vv')
            self.imageuploading = true        
          },
          'image.inserted': function ($img, response) {
            self.imageuploading = false
          }
        }

        $.getJSON( "https://s3token.flowe.io/", function(data){
          self.froalaconfig.imageUploadToS3 = data        
        })


        this.measures = []
        this.indicatorids = []
        this.indicators = []
        this.isworking = false
        this.imageuploading = false
        this.measureloaded = 0 
        this.measureverified = 0

        this.showinstruction = false
        this.statustext = ""

        this.numowners = 0
        document.title ='Add status update | Flowe'
        if( !this.editorinit ){

          this.linkInitControls.initialize()

          this.froalaconfig.events.contentChanged = function () {

            var editorvalue = self.linkInitControls.getEditor().html.get()
            
            Cockpitsapi.Autosave( "status_" + self.objectiveid, editorvalue, localStorage.token )
            .then( function(response){

            })

            
          }

        }


       // this.linkInitControls.initialize()
        
        Cockpitsapi.Objectiveedit( this.objectiveid, this.token, this.orgkey )

        .then( function(response){
          //console.log(response, "cccc")
          if( response.data ){
            
            self.objective = response.data.measure
            self.measurelisted = self.objective.measures.length
            self.measureverified = self.measurelisted

            /*for( var i in self.objective.measures ){

              self.recalculate( self.objective.measures[i]._id )
            }*/
            self.pagemode = "ready"
            //self.measuresloaded = true
            if( response.data.autosave._id ){

              if( response.data.autosave.content != "" ){
                self.autosave = response.data.autosave
                self.autosavecontentfound = true
              }
              
            }
            

          }

        })
        .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
        .finally( () => {
        })


      },
      
       modalhidden(){

        this.noaccessmeasures = []
        this.validmeasures = []
        this.measurelisted = 99999999999
        this.measureloaded = 0
        this.measureverified = 0
        this.objective = {}
        this.message = ""
        this.measuresloaded = false
        this.$emit('onmodalhidden')
        this.$func.removeQuery(['page'],this.$route, this.$router)
        this.isshown = false
        
      }
    }

  }

</script>