<template>
	<div>





		<b-modal v-model="exportloading" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc>
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Export stakeholder management document</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="exportloading = false"></i>
	          </a>
	        </template>
	        <div>
	        	<p>Create a stakeholder report by exporting stakeholder information to a Microsoft Word document using a standardized project management framework template.</p><p>Select a template and click export to proceed.</p>


				<div class="dropdown">
				  <button class="btn btn-block text-left btn-secondary-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  	<div style="position:relative;">
				    <span>{{ selectedforexport.label }}</span> 
				    <span style="position:absolute; right: 10px">
				    	<i class="fas fa-caret-down"></i>
				    </span>
					</div>
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <div style="color:#000;" class="dropdown-item text-black" :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)">{{ template.label }}</div>
				  </div>
				</div>


				<p>&nbsp;</p>
				
	        </div>

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Export" @no ="cancel" @yes = "doexport(selectedforexport.key)"></button-gruppe>
	        </template>
        </b-modal>

				<b-modal v-model="confirmstakeholder" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Confirm delete stakeholder</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="confirmstakeholder = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> You are about to remove a stakeholder, this contacts may loose access to some of your boards?
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Yes proceed" @no ="cancel" @yes = "confirmdeletestakeholder(activestakeholder)"></button-gruppe>
	        </template>
        </b-modal>

        <b-modal @hide="addnewstakeholderformhidden" @shown="addnewstakeholderformshown" v-model="addnewstakeholder" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Add stakeholder</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addnewstakeholder = false"></i>
	          </a>
	        </template>
	        <form onsubmit="return false" v-if="canwrite" class="mt-2">
						

		  			<div class="mb-3">
					    <label class="form-label">Stakeholder name</label>
					    <input class="form-control card p-2 noshadow readonly titletext mb-0" type="text" v-model="stakeholdername" ref="newstakeholdernameinput"> 
					    <span class="text-danger hide" v-if="!allowsave">name is required</span>
					  </div>

						<div class="mb-3">
					    <label class="form-label">Description</label>
					    <textarea class="form-control card p-2 noshadow readonly titletext mb-0 autogrow"  style="overflow: hidden; overflow-y: auto; height: auto; height: 72px; max-height: 200px;" v-model="stakeholderdescription" ref="newstakeholderdescription"></textarea>
					  </div>


		  			<button @click="createnewstakeholder()" style="position: absolute; left: -100000px;" type="submit"></button>
		  			
		  		</form>
	        <template v-slot:modal-footer="{ cancel, ok }">
	        	<button class="ml-1 btn btn-secondary" @click="addnewstakeholder = false" type="button">Cancel</button>

	        	
	        	<button v-show="allowsave" class="ml-1 btn btn-secondary" @click="createnewstakeholder(false)" type="button">Save and close</button>

	        	<button v-show="allowsave" class="ml-1 btn btn-primary" @click="createnewstakeholder(true)" type="button">Save and add another</button>
	          	
	        	<button v-show="!allowsave" class="ml-1 btn btn-secondary" type="button" disabled="disabled">Save and close</button>
	        	<button v-show="!allowsave" class="ml-1 btn btn-primary" type="button" disabled="disabled">Save and add another</button>
	          	
	        </template>
        </b-modal>
	       	
	       	<div class="mb-3 mt-3" :class="'xwidth_'+windowspec" > 
	       	<button v-if="canwrite" class="btn btn-primary" @click="addnewstakeholder=true"> <i style="font-size:18px" aria-hidden="true" class="fa fa-plus-circle mr-2 "></i> Add stakeholder</button>

	       	<button class="btn btn-secondary ml-2" @click.stop.prevent="doinitexport()">Export</button>
	        </div>


	       <div class="mb-5">

	       	<pre v-if="1==2" style="position:absolute; right: 20; width: 300px;">{{ nearstakeholders }}</pre>

         <div id="interactivepowerslider" :class="'minwidth_'+windowspec+' lwidth_'+windowspec" @touchstart="viewtouch">


					<table style="float: left;" :class="'x_'+windowspec" class="axis">
				      <tr style="height:33%">
				        <td style="text-align: center; vertical-align: top;"><p style="margin-top:10px;">
				        	<span v-if="windowspec!='sm'">High</span>
				        	<small v-else>High</small>
				        </p></td>
				      </tr>
				      <tr style="height:33%; position: relative;">
				        <td style=" text-align: center; transform: rotate(-90deg); position: absolute; top: 50%; " class="trait">Power</td>
				      </tr>
				      <tr style="height:33%">
				        <td style="text-align: center; vertical-align: bottom;"><p style="margin-bottom:10px;"><span v-if="windowspec!='sm'">Low</span>
				        	<small v-else>Low</small></p></td>
				      </tr>
				    </table>

         <div :class="'x_'+windowspec+' y_'+windowspec" class="graph mb-1" style="float:left; border: solid 1px #000; position: relative;">

			    <table class="table-bordered" width="100%" height="100%" align="center">      
			      <tr>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Keep satisfied</td>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Manage closely</td>
			      </tr>
			       <tr>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Monitor</td>
			        <td class="text-muted action" width="50%" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;">Keep informed</td>
			      </tr>           
			    </table>


         	<div @touchmove.prevent.stop="touchhandler" @touchend.prevent.stop="touchhandler" @touchstart.prevent.stop="touchhandler" @click.prevent.stop="clickhandler(sh)" :id="'drag_'+sh._id" :key="sh._id" v-for="sh in sortedstakeholders" :style="'position:absolute; top:'+ powerposition(sh.power)+'px; margin-left:-7px; margin-top:-7px; left:'+ interestposition(sh.interest) + 'px;'" class="draggable touchable">
         		<div style="position: relative;">


         		<span v-if="sh.interest>65" :ref="'flipshlabel_'+sh._id" class="shlabel left" :style="'left:'+myleft(sh._id)+ '; width:'+mywidth(sh._id)" >
			      	<span v-if="sh._id==activestakeholder._id" class="p-1"><span style="color: #000; background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			      	<span v-else class="p-1"><span style="color: #000;background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			    	</span>

         		<i  @mouseover="onmouseover( 'in', sh)" @mouseleave="onmouseover( 'out', sh)" class="fas fa-circle marker float-right" :class="mood(sh.mood)" ></i>

         		<span v-if="sh.interest<=65" :ref="'shlabel_'+sh._id" class="shlabel">
			      	<span v-if="sh._id==activestakeholder._id" class="p-1"><span style="color: #000;background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			      	<span v-else class="p-1"><span style="color: #000;background-color: rgba(255, 255, 255, .9);">{{ sh.label }}</span></span>
			    	</span>

			    	
			    </div>
        	</div>

					<b-popover custom-class="popoverstakeholders" :show.sync="showpopover" :placement="focusstakeholder.interest<=65?'right':'left'" animation="false" :target="'drag_'+focusstakeholder._id" triggers="manual" v-if="focusstakeholder._id"> 

						<div @mouseover="onmouseover( 'in', focusstakeholder)" @mouseleave="onmouseover( 'out' )"  v-if="nearstakeholders.length > 1 || isTruncated( focusstakeholder._id )">
								<template v-for="nsh in nearstakeholders"> 
		          				<a v-if="focusstakeholder._id == nsh._id" :key="nsh._id" class="dropdown-item pl-1"  @click="onselected(nsh,true,true)"> 
									<i class="fas fa-circle marker" :class="mood(nsh.mood)"></i> {{ nsh.label }}</a>
								<a v-else :key="nsh._id" class="dropdown-item pl-1" @click="onselected(nsh,true,true)"><i class="fas fa-circle marker" :class="mood(nsh.mood)"></i> {{ nsh.label }}</a>	
								</template>
			       		</div>

					</b-popover>

        	
         	
         </div>

         <table style="clear: both;" :class="'y_'+windowspec" class="axis">
			      <tr>
			        <td width="33%" style="text-align: left;"><p style="margin-left:10px"><span v-if="windowspec!='sm'">Low</span>
				        	<small v-else>Low</small></p></td>
			        <td width="33%" style="text-align:center;" class="trait">Interest</td>
			        <td width="33%" style="text-align: right;"><p style="margin-right:10px"><span v-if="windowspec!='sm'">High</span>
				        	<small v-else>High</small></p></td>
			      </tr>
			    </table>

				

       	 </div>



       	 <div :class="'rwidth_'+windowspec +' '+singlecol+'_'+windowspec">					


					<template v-if="activestakeholder._id && mode == 'edit'">

          <div :class="{'card':!ismobile}"> 

         	<div :class="{ 'card-body':!ismobile, 'p-0':ismobile }" >


         	<div v-if="canwrite" class="editable" @click="doeditlabel">


         		<div v-if="!editlabel">
         			<h3 class="mb-2" v-if="!activestakeholder.label">--</h3>
         			<h3 class="mb-2" v-else-if="activestakeholder.label==''">--</h3>
         			<h3 class="mb-2" v-else>{{ activestakeholder.label }} <i class="fa fa-pencil editableicon"></i></h3>
         			<span class="text-danger" v-if="activestakeholder.label==''">name is required</span>
         		</div>


         		<div class="mb-3 has-success" v-else>

	         	<input type="" name="stakeholdername" class="form-control card p-2 noshadow readonly titletext mb-0" v-model="activestakeholderlabel" ref="inputstakeholderlabel">

	         	<div class="text-right mt-1">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary btn-sm mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="updatestakeholder(activestakeholder,'label', activestakeholderlabel)" class="btn btn-primary btn-sm" type="button">Save</button>
	         	</div>

	         	</div>

	         	
	         	
         	</div>
         	<div v-else>
         		<h3 class="mb-2">{{ activestakeholder.label }} </h3>
         	</div>
         	
         	

         	<div class="mb-1 has-success editable" v-if="canwrite" @click="doeditdescription">

         	<label class="form-label">Description</label>         	


         	<div id="inputstakeholderdescriptionviewer" ref="inputstakeholderdescriptionviewer" v-if="editdescription == false" class="card p-2 noshadow readonly titletext mb-0 description" style="min-height: 72px;max-height: 200px; overflow-y: auto; white-space: pre-wrap;">{{ activestakeholderdescription }} 

         	<i class="fa fa-pencil editableicon"></i>	
         	</div>

         	<textarea id="inputstakeholderdescriptioneditor" style="overflow: hidden; overflow-y: auto; height: auto; min-height: 72px; max-height: 200px;" v-if="editdescription==true" name="stakeholderdescription" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activestakeholderdescription" ref="inputstakeholderdescription"></textarea>

         	<div class="text-right mt-1"  v-if="editdescription==true">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary btn-sm mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="updatestakeholder(activestakeholder,'description', activestakeholderdescription)" class="btn btn-primary btn-sm" type="button">Save</button>
	         	</div>


         	</div>

         	<div v-if="!canwrite" class="mt-1">

         		<label class="form-label">Description</label> 

         		<div id="inputstakeholderdescriptionviewer" ref="inputstakeholderdescriptionviewer" v-if="editdescription == false" class="card p-2 noshadow readonly titletext mb-3 " style="min-height: 72px;max-height: 200px; overflow-y: auto; white-space: pre-wrap;">{{ activestakeholderdescription }} 

         		
         	</div>
         		
         	</div>

         	<hr>

         	<div class="row mb-1 mt-3"> 


         	<div class="col-12">
         		<label>Stakeholder Attitude</label>
         	</div>

         	<div class="col-7 d-flex">        		

         		

         		<div v-if="!canwrite" class="align-self-center">
         		<span class="m-2">         			
         			<i :class="'iconactive_'+windowspec" v-if="activestakeholder.mood=='green'" class="fas fa-smile green"></i>
         			<i :class="'icon_'+windowspec" v-else class="far fa-smile green"></i>
         		</span>
         		<span class="m-2"> 
         			<i :class="'iconactive_'+windowspec" v-if="activestakeholder.mood=='yellow'" class="fas fa-meh yellow"></i>
         			<i :class="'icon_'+windowspec" v-else class="far fa-meh yellow"></i>
         		</span>
         		<span class="m-2"> 
         			<i :class="'iconactive_'+windowspec" v-if="activestakeholder.mood=='red'" class="fas fa-frown red"></i>
         			<i :class="'icon_'+windowspec" v-else class="far fa-frown red"></i>
         		</span>
         		</div>
         		
         		<div v-if="canwrite" class="align-self-center">
         		<a href="#" @click.prevent="updatestakeholder(activestakeholder,'mood', 'green')" class="m-2">         			
         			<i :class="'iconactive_'+windowspec" v-if="activestakeholder.mood=='green'" class="fas fa-smile green"></i>
         			<i :class="'icon_'+windowspec" v-else class="far fa-smile green"></i>
         		</a>
         		<a href="#" @click.prevent="updatestakeholder(activestakeholder,'mood', 'yellow')" class="m-2">
         			<i :class="'iconactive_'+windowspec" v-if="activestakeholder.mood=='yellow'" class="fas fa-meh yellow"></i>
         			<i :class="'icon_'+windowspec" v-else class="far fa-meh yellow"></i>
         		</a>
         		<a href="#" @click.prevent="updatestakeholder(activestakeholder,'mood', 'red')" class="m-2">
         			<i :class="'iconactive_'+windowspec" v-if="activestakeholder.mood=='red'" class="fas fa-frown red"></i>
         			<i :class="'icon_'+windowspec" v-else class="far fa-frown red"></i>
         		</a>
         		</div>

         	</div>

         	<div class="col-6=5">

         		<table class="float-right">
         			<tr>
         				<td class="text-left">Power :</td>
         				<td class="text-right" width="40px" nowrap="nowrap">{{ Math.floor( activestakeholder.power)  }} %</td>
         			</tr>
         			<tr>
         				<td class="text-left">Interest :</td>
         				<td class="text-right" width="40px" nowrap="nowrap">{{ Math.floor(activestakeholder.interest) }} %</td>
         			</tr>
         		</table>


         	</div>

         	</div>

         	<hr>

         	<div class="mb-1">


         		<label>Contacts</label>
        		

         		<contactsTable :contact="activestakeholder" :activestakeholder="activestakeholder" :team="team" @onupdatestakeholder="updatestakeholder" :canwrite="canwrite" :boardusers="boardusers"></contactsTable>
 
         	</div>

         	<hr>

         	<div class="mb-1" v-if="canwrite">
         	<button class="btn btn-danger" @click="trydeletestakeholder()">Delete stakeholder</button>
         	</div>

         	</div>
         </div>

         </template>

       </div>

         


     		<p class="clearfix">&nbsp;</p>
     </div>





      </div>
</template>

<style type="text/css">

	/*tooltip.light .tooltip-inner, .tooltip.dark .tooltip-inner, .tooltip .tooltip-inner {
    font-size: 14px;
    font-weight: 400;
    height: 1px;
	}

	.tooltip .tooltip-inner {
    background-color: rgba(255, 255, 255, .7) !important;
    color: var(--N900);
	}

	.arrow {
    display: none !important;
	}*/

	.popoverstakeholders .popover-body{
		padding: 0 !important;
	}

	.shlabel.left{
		text-align: right;
		position:absolute; 
		width: 100px;
		left: -100px; 
		top: -5px; 
		overflow: hidden; 
		text-overflow: ellipsis; 
		white-space: nowrap;
		display: block;
		offset-anchor: right;
	}

	.shlabel{
		position:absolute; 
		width: 100px;
		left: 16px; 
		top: -5px; 
		text-align: center; 
		overflow: hidden;
		text-overflow: ellipsis; 
		white-space: nowrap; 
		text-align: left;
	}

	.ui-selected .shlabel{
		width: auto;
		font-weight: bolder;
	}


	.ui-draggable-dragging .shlabel{
		width: auto;
	}

	.ui-selected .shlabel.left, .ui-draggable-dragging .shlabel.left{
		width: auto;
		text-align: right;
		/*left: myleft;*/
	}

	.draggable{
		cursor: pointer;
	}


</style>
<style type="text/css" scoped>

		.trait{
			font-weight: bold;
		}

		.lwidth_lg{
			float: left;
		}
		.rwidth_lg{
			float: left;
			width: 390px;
		}

		.lwidth_md{
			width: 400px;
			/*margin: auto;*/
		}
		.rwidth_md{
			/*margin: auto;*/
			width: 390px;
		}

		.lwidth_sm{
			/*margin: auto;*/
		}
		.rwidth_sm{
			margin: auto;
			/*width: 336px;*/
		}

		.iconactive_lg{
			font-size: 40px;
		}

		.iconactive_md{
			font-size: 36px;
		}
		
		.iconactive_sm{
			font-size: 30px;
		}

		.icon_lg{
			font-size: 32px;
		}

		.icon_md{
			font-size: 28px;
		}
		
		.icon_sm{
			font-size: 22px;
		}

		.x_sm{
			height: 300px;
		}
		.x_sm td{
	/*		font-size: 10px;*/
		}
		.x_sm.axis{
			width: 35px;
		}
		.x_sm.axis td{
			padding-right: 0px;
			/*transform: rotate(-90deg);*/
		}
		.x_sm .trait{
/*			font-size: 12px;*/
		}

		.y_sm{
			width: 300px;
		}
		.y_sm td{
	/*		font-size: 10px;*/
		}
		.y_sm.axis{
			margin-left: 35px;
		}
		.y_sm.axis td{
			/*padding-right: 10px;*/
		}
		.y_sm .trait{
/*			font-size: 12px;*/
		}
		.y_sm td.action{
/*			font-size: 14px;*/
		}


		.x_md{
			height: 400px;
		}
		.x_md td{
/*			font-size: 12px;*/
		}
		.x_md.axis{
			width: 40px;
		}
		.x_md.axis td{
			/*padding-right: 10px;*/
		}
		.x_md .trait{
/*			font-size: 14px;*/
		}


		.y_md{
			width: 400px;
		}
		.y_md td{
/*			font-size: 12px;*/
		}
		.y_md.axis{
			margin-left: 40px;
		}
		.y_md.axis td{
			/*padding-right: 10px;*/
		}
		.y_md .trait{
/*			font-size: 14px;*/
		}
		.y_md td.action{
/*			font-size: 18px;*/
		}

		.x_lg{
			height: 500px;
		}
		.x_lg td{
/*			font-size: 14px;*/
		}
		.x_lg.axis{
			width: 45px;
		}
		.narrow_lg{
			margin-top: 15px;
			margin-left: 10px;
		}
		.narrow_md{
			margin-top: 15px;
			margin-left: 10px;
		}
		.narrow_sm{
			margin-top: 15px;
		}

		.x_lg.axis td{
			/*padding-right: 10px;*/
		}
		.x_lg .trait{
/*			font-size: 20px;*/
		}


		.y_lg{
			width: 500px;
		}
		.y_lg td{
			font-size: 14px;
		}
		.y_lg.axis{
		margin-left: 55px;
		}
		.y_lg.axis td{
			/*padding-right: 10px;*/
		}
		.y_lg .trait{
	/*		font-size: 20px;*/
		}
		.y_lg td.action{
	/*		font-size: 22px;*/
		}


		.minwidth_sm{
			max-width: 350px;
		}

		.minwidth_md{
			min-width: 510px;
		}

		.minwidth_lg{
			min-width: 580px;
		}

		.action{
			font-size: 140%;
			text-align: center;
		}


	 .green{ color: $G500; }
   .red{ color: $R700; }
   .yellow{ color: $Y500; }

   .contact-selected{
   	border: solid 2px blue;
   }

	.stakeholder{
		border: solid 1px #000;
		padding: 3px;
		background-color: #fff;
	}

	.dragcontent{
		position:absolute; 
		width: auto;
		left: 20px;
		min-width: 100px;
	}

	.vdr.active::before{
		outline: none;
	}

	.ui-selectable:hover{
		cursor: pointer;
	}


	.highlight{
		border: solid 1px #000;
		padding: 5p ;
	}

	.table th, .table td{
		padding: 0;
	}

	.vs__selected{
	  display: none !important;
	}


	/*.ui-draggable-dragging{
		transform: scale(1.7);
	}*/

	.ui-selected .marker{
		transform: scale(1.2);
	}

	.touch-start{
		transform: scale(1.5);
	}

	/*.ui-selected span{
		position: relative;
		width: auto;
	}*/
</style>
<script type="text/javascript"></script>

<script>

	import Userapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'

	import Emailsapi from '@/services/api/emails'
	import {bus} from '@/main'

	import contactsTable from './contactsTable'
	import memberlistfull from '@/components/members/memberlistfull'
	
	//import autosize from 'autosize'

/*var Arc = function(x, y, radius, radians) {
  this.x = x;
  this.y = y;
  this.radius = radius;
  this.radians = radians;
  this.isDragging = false;

  this.render = function(ctx) {
    ctx.save();


    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, this.radians, false);
    ctx.fillStyle = '#2793ef';
    ctx.fill();

    ctx.restore();
  }
}*/

	export default{
		name: 'stakeholders',
		data(){
			return{
				selectedforexport : { 'label':"(DK) Projektgrundlag_interresentstyring", 'key':"dk1" } ,
				exporttemplates : [ { 'label':"(DK) Projektgrundlag_interresentstyring", 'key':"dk1" } , { 'label':"(EN) Stakeholder_management", 'key':"en1"  } ],
				stakeholdername:"",
				stakeholderdescription:"",
				width: 100,
				height: 0,
				top: 0,
				left: 0,
				isdragging: false,
				stakeholders : [],
				activestakeholder : {},
				tempstakeholder : {},
				focusstakeholder : {},
				activestakeholderdescription : "",
				activestakeholderlabel : "",
				textcontact:"",
				textemail:"",
				windowsize:0,
				showtooltip:true,
				showpopover:false,
				nofade:true,
				sortedstakeholders:[],
				mode:"idle",
				confirmstakeholder:false,
				mouseovertimer:0,
				mouseovert : null,
				addnewstakeholder: false,
				addanother: false,
				boardusers : [],
				editlabel: false,
				editdescription: false,
				exportloading: false,
				imgbody : null,
				touchevent : "",
				touchtimer : null,
				apidomain:""
			}
		},

		props:{
		},

		watch:{

			'activestakeholder.label' : function( e, ev ){

				if( e == '' ){

					this.activestakeholder.label = ev
				}

			},

			addnewstakeholder : function( e ){
				this.activestakeholder = {}
				this.showpopover = false
			},

			stakeholders : function( e ){

				this.sortedstakeholders = e 
			},
			activestakeholder : function( e ){

   			this.updateboardusers()
			},

			cockpit : function( e ){

				if( !e.stakeholders ){

					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("stakeholders", "board")
					location.href=newroute

					//this.$router.push( 'board' )
				}
			}
		},

		computed:{

			ismobile(){

				return this.$store.state.isMobile
			},
			

			/*descheight(){
					return $('#inputstakeholderdescriptionviewer').height()
			
			},/**/

			allowsave(){

				let notallowed = 0 
				if( this.stakeholdername == '' ){
					notallowed += 1
				}

				if( notallowed == 0 ){
					return true
				}else{
					return false
				}
			},

			nearstakeholders(){

				let newstakeholders = []

				////////console.log( this.focusstakeholder )

				for( var i in this.sortedstakeholders){

					let power = this.focusstakeholder.power - this.sortedstakeholders[i].power
					let interest = this.focusstakeholder.interest - this.sortedstakeholders[i].interest

					//console.log( power, interest)

					let proceed = 0

					let gap = 5

					if( power < gap && power > -(gap) ){
						proceed += 1
					}

					if( interest < gap && interest > -(gap) ){
						proceed += 1
					}

					let sh = this.sortedstakeholders[i]
					//{ power:this.sortedstakeholders[i].power, interest:this.sortedstakeholders[i].interest, label:this.sortedstakeholders[i].label}

					if( proceed == 2 && this.focusstakeholder._id != this.sortedstakeholders[i]._id){
						newstakeholders.push( sh )
					}

					if( this.focusstakeholder._id == this.sortedstakeholders[i]._id ){
						newstakeholders.push( sh )
					}
					
				}

				if( newstakeholders.length > 0 ){
					return newstakeholders
				}else{
					return []
				}				

			},

			canwrite(){

				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()=='owner' || this.cockpit.myrole.toLowerCase()=='contributor')){
					return true
				}
				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()!='owner' && this.cockpit.myrole.toLowerCase()!='contributor')){
					return false
				}


			},

			loggedinuser(){
			    return this.$store.getters.user
			},

			team(){
				return this.cockpit.stakeholders
			},
		

			activestakeholdercontacts(){

				var toreturn = this.activestakeholder.contacts

				toreturn.push( { _id:"createnewcid", state:'idle', firstname:"", lastname:"", email:"", externalemail:"", "status":"stakeholder" } )

				return toreturn
			},

			singlecol(){

				if( this.windowsize < 1087 ){
					return 'narrow'
				}else{
					return 'wide'
				}
				 
			},

			windowspec(){

				var ismobile = this.$store.state.isMobile

				var winsize = ""

				if(  ismobile || this.windowsize < 460 ){
					winsize = 'sm'
				}else if( this.windowsize >= 461 && this.windowsize < 768 ){
					winsize = 'md'
				}else{
					winsize = 'lg'
				}

				return winsize

			},

			cockpitid(){
        return this.$route.params.cid
      },

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

      token(){
        return this.$store.getters.token
      },

      cockpit(){
				return this.$store.getters.GetCockpit
			},

		}, 

		created() {
		  window.addEventListener("resize", this.windowOnresize);
		},
		destroyed() {
		  window.removeEventListener("resize", this.windowOnresize);
		},

		mounted(){


			this.stakeholdername = ""
			this.stakeholderdescription = ""


		/*this.$root.$on('bv::tooltip::hide', bvEvent => {
			//this.showtooltip = true
      //falert('')
      //this.$root.$emit('bv::show::tooltip')
      bvEvent.preventDefault()
    })*/

			this.windowOnresize()


			this.getallstakeholders()



			

/*
			setTimeout( function(){


			var canvas = document.getElementById("mycanvas");
			var con = canvas.getContext("2d");

      con.fillStyle = "#000";
      con.fillRect(50,50, 400, 400);
      con.fillStyle = "#fff";
      con.fillRect(51,51, 398, 398);
      // draw font in red
      con.fillStyle = "#000";
      con.font = "16px sans-serif";
      con.fillText("High", 10, 90);
      con.fillText("High", 400, 480);
      con.fillText("Low", 10, 420);
      con.fillText("Low", 80, 480);	
	

      con.fillText("Keep satisfied", 120, 120);
      con.fillText("Monitor closely", 280, 120);
      con.fillText("Monitor", 120, 380);
      con.fillText("Keep informed", 280, 380);

      con.fillStyle = "#000";
      con.font = "24px sans-serif";

      con.fillText("Interest", 220, 480);

      con.save()
      con.rotate(-Math.PI/2)
      con.fillText("Power", -280, 30);
      con.restore()


      var circle = new Arc(200, 140, 6, Math.PI * 2);
			circle.render(con);

			},10)*/


				if( !this.cockpit.stakeholders ){

					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("stakeholders", "board")
					location.href=newroute
					//this.$router.push( 'board' )
				}


		},

		filters: {
  			nl2br : function( str ){

  				if( str ){
  					return str.replace(/(\\r)*\\n/g, '<br>')
  				}else{
  					return str;
  				}

				

			},
		},

		methods:{

			setforexport( template ){

				this.selectedforexport = template


				console.log( template )

			},

			mywidth( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el && el[0] ){

				return el[0].scrollWidth + 'px'
					
				}

				
			},

			myleft( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el &&  el[0] ){

				return '-' + el[0].scrollWidth + 'px'
					
				}

				
			},

			viewtouch(){

				this.showpopover = false
			},

			clickhandler( sh ){

				var ismobile = this.$store.state.isMobile
				if( ismobile )
				return false


				this.onselected( sh , true )

			},

			touchhandler( touch ){

				this.touchevent = touch.type
						

				let self = this

				let id = $(touch.target).parents('.touchable').get(0).id.replace('drag_','')			


				//this.showpopover = false
				

				if( touch.type == "touchstart" && touch.currentTarget ){

					//var ismobile = this.$store.state.isMobile
					/**/

					self.focusstakeholder = {}
					self.showpopover = false

					for( var i in self.stakeholders ){

						//////console.log( self.stakeholders, self.stakeholders[i]._id, id )

						if( self.stakeholders[i]._id == id ){

							let sh = self.stakeholders[i]

							this.focusstakeholder = sh

							setTimeout( function(){
								self.showpopover = true
							}, 500)

							
 //
							//if( this.nearstakeholders.length > 1 ){												
							//	self.showpopover = true
								//return false
							//}else{

								self.onselected( self.stakeholders[i] , true )
							//}
							
						}						
					}



					this.touchtimer = setTimeout( function(){						

						$(touch.target).parents('.touchable').addClass("touch-start")
						self.touchevent = "touchhold"
						self.showpopover = false

					}, 900)


					
				}

				if( touch.type == "touchend" && touch.currentTarget ){

					clearTimeout(this.touchtimer)

					$('.touch-start').removeClass("touch-start")

				}
				
				if( touch.type == "touchmove" && touch.currentTarget ){
					this.showpopover = false
				}
				
			},

			doinitexport(){

				this.exportloading = true

			},

			doexport( template ){

				let self = this

				/*this.exportloading = true

				//window.location.href="/static/export.docx"

				var node = document.getElementById('interactivepowerslider');

				domtoimage.toPng(node)
				    .then(function (dataUrl) {
				        //var img = new Image();
				        //img.src = dataUrl;
				        //document.body.appendChild(img);
				        self.$refs.exportimg.src = dataUrl;
				    })
				    .catch(function (error) {
				        console.error('oops, something went wrong!', error);
				    });*/

				
				

				Userapi.Exportstakeholder( template, this.cockpitid, this.token ).then( function(response){

					self.exportloading = false
					self.apidomain = response.data.apidomain
					location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
				})
				


			},

			

			canceledit(){

				this.activestakeholderlabel = this.activestakeholder.label
				this.activestakeholderdescription = this.activestakeholder.description

				this.editlabel = false
				this.editdescription = false

			},

			doeditdescription(){

				let self = this

				let hh = $('#inputstakeholderdescriptionviewer').height()

				this.editdescription = true
				this.editlabel = false

				setTimeout( function(){

					//autosize($('.autogrow'));

					$('#inputstakeholderdescriptioneditor').height(hh+10)
					//alert(hh)
					//self.$refs.inputstakeholderdescription.offsetHeight = "500px"
					self.$refs.inputstakeholderdescription.focus()
				},50)

			},


			doeditlabel(){

				let self = this

				this.editlabel = true
				this.editdescription = false

				setTimeout( function(){
					self.$refs.inputstakeholderlabel.focus()


				},100)

				
			},

			updateboardusers(){


					let teamids = []

	   			for( var i in this.cockpit.sharedtoteams ){
						teamids.push( this.cockpit.sharedtoteams[i].userid )
					}

					 let boardusers = []

	        if( this.cockpit.sharedtoteams && teamids.indexOf( this.team._id ) > -1){	       

						for( var i in this.team.sharedtousers ){

								if( boardusers.indexOf( this.team.sharedtousers[i].userid ) == -1 ){
									boardusers.push( this.team.sharedtousers[i].userid )
								}
								
						}

	        }

					this.boardusers = boardusers

			},
			closeotherbox( el ){


				this.activestakeholder = {}
			},

			isTruncated( iid ) {

				let el = this.$refs['shlabel_'+iid]

				if( el && el[0] ){
					return el[0].offsetWidth < el[0].scrollWidth
				}else{
					return false
				}
			   
			},

			savestakeholder( sh ){

				//console.log( sh,  'savestakeholder')

				var updatevalue = { "t":this.token, "action":"updatestakeholder", "field":'updatestakeholder', "label":sh.label, "description":sh.description, "mood":sh.mood, "objectid": sh._id }

				Userapi.UpdateStakeholder( updatevalue ).then( function(response){

				})
	
			},

			addnewstakeholderformhidden(){

				if( this.stakeholders ){
					this.onselected( this.stakeholders[  this.stakeholders.length - 1 ], false )
				}
				
			},


			addnewstakeholderformshown(){

				this.stakeholdername = ""
				this.stakeholderdescription = ""

				this.$refs.newstakeholdernameinput.focus()

				//autosize($('.autogrow'));


				//alert('')
			},


			sortstakeholders(){

				let stakeholders = this.sortedstakeholders

				let newstakeholders = []

				
				let current = {}

				for( var i in stakeholders ){

					if( this.activestakeholder._id != stakeholders[i]._id ){
						newstakeholders.push( stakeholders[i] )
					}else{
						current = stakeholders[i]
					}
					
				}

				newstakeholders.push( current )

				

				this.sortedstakeholders = newstakeholders
				//return newstakeholders
			},

			mood( mood ){

				if(!mood || mood == ''){
					mood = 'green'
				}
				return mood

			},

			validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());

			},

			removeuserfromteam( userid, isforced , callback ){


					let self = this

					Teamsapi.Removeuser( this.token, this.team._id, userid, true, isforced, "checkstakeholders" ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtostakeholderteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey, "remove" ).then( function( response2 ){

								if( callback ){
									callback()
								}
							})
							
						}
						//Teamsapi.Updateuserstatus( option._id, self.orgkey , 'add').then( function( response2 ){						
						//		self.getteams(response.data.team._id)					
						//})

					})

			},

			addusertoteam( userid , callback){


					let self = this

					Teamsapi.Adduser( this.token, this.team._id, userid ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtostakeholderteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey , 'add').then( function( response2 ){	

								if( callback ){
									callback()
								}					
							//		self.getteams(response.data.team._id)					
							})

						}


					})

			},


			windowOnresize(){

				this.windowsize = window.innerWidth

			},

			trydeletestakeholder(){

				this.confirmstakeholder = true
			},



			confirmdeletestakeholder( sh ){

				let self = this


				var contacts = []

				if( sh.contacts ){
					for( var i in sh.contacts ){
						//alert( sh.contacts[i].userid )
						contacts.push( sh.contacts[i]._id )
					}
				} 

				Userapi.Deletestakeholder( this.cockpitid, sh._id, this.token ).then( function(response){

					if( response.data.ok ){
						self.stakeholders = response.data.stakeholders
						self.activestakeholder = {}
						self.confirmstakeholder = false


						for( var i in contacts ){
							self.removeuserfromteam( contacts[i], false )
						}

						setTimeout( function(){

							if( self.stakeholders[0] ){
								self.onselected( self.stakeholders[0] , true, true)
							}

						}, 200)

						
					}

				})
			},

			updatestakeholder( sh, act, value, callback){



				let self = this

				var updatevalue = { "t":this.token, "action":"update", "field":act, "objectid": sh._id }

				if( act == 'label' && value != '' ){
					updatevalue.label = value
					sh.label = value
				}

				if( act == 'description' ){
					updatevalue.description = value
				}

				if( act == 'mood' ){
					updatevalue.mood = value
				}

				if( act == 'contacts' ){

					/*var newcontacts = []

					if( sh.contacts != null )  {
						newcontacts = sh.contacts
					}					

					if( value.firstname && value.lastname && value.email && value.type ){
						newcontacts.push( value )

						this.textcontact = ""
						this.textemail = ""
					}
					*/

					
					//console.log( value._id , value.firstname ,value.lastname , value.externalemail ,value.type )

					if( value._id && value.firstname ){						

						let tosubmit = { "cid":value._id , "firstname":value.firstname , "lastname":value.lastname, "externalemail":value.externalemail, "email":"" } 

						updatevalue.contacts = JSON.stringify( tosubmit )
					}

					
				}

				if( act == 'removecontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				if( act == 'removefromboard' ){

					updatevalue.removefromboard = JSON.stringify( value )

					this.removeuserfromteam( value.userid, true , function(){

						self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
							if( response.data.ok){
								self.updateboardusers()
							}
						})

					})

				}


				if( act == 'replacecontact' ){

					updatevalue.replacecontact = JSON.stringify( value )

					


				}

				if( act == 'invitecontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				if( act == 'addcontact' ){


					updatevalue.replacecontact = JSON.stringify( value )


				}

				

				Userapi.UpdateStakeholder( updatevalue ).then( function(response){

					//if( act == 'contacts' ){



						if( response.data.stakeholder ){
							self.activestakeholder = self.formatstakeholder( response.data.stakeholder ) 


							for( var i in self.sortedstakeholders ){
								if( self.sortedstakeholders[i]._id == response.data.stakeholder._id ){
									self.sortedstakeholders[i] = response.data.stakeholder
								}
							}
						}

						if( act == 'label' ){

							self.editlabel = false

							$( self.$refs.inputstakeholderlabel ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputstakeholderlabel ).removeClass("form-control-success")
							}, 1000)
						}

						if( act == 'description' ){

							self.editdescription = false

							$( self.$refs.inputstakeholderdescription ).addClass("form-control-success")

							setTimeout( function(){
								$( self.$refs.inputstakeholderdescription ).removeClass("form-control-success")
							}, 1000)
						}	
					//}


						if( response.data.stakeholders ){
						//	self.newstakeholders( response.data )
						}

						//addusertoteam

						if( act == 'replacecontact' ){
							self.addusertoteam( value.userid , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.updateboardusers()
									}
								})

							})
							
						}

						if( act == 'invitecontact' ){
							

							if( response.data.contact && response.data.contact.externalemail ){

								self.addusertoteam( value.userid )
								Emailsapi.Invitationemail( self.loggedinuser._id, response.data.contact.externalemail, self.orgkey)
					     		var str = "We have sent an invitation to the email address you indicated."
					      		bus.$emit('craeteaToast', {content:str,title:'Invite sent', variant:'info',append:true, sup:''})

							}
							

						}

						if( act == 'removecontact' ){

							/*console.log(  )

							let numstakeholders = 0

							for( var i in this.stakeholders ){
								let sh = this.stakeholders[i]

								if( sh.contacts )
								for( var j in sh.contacts ){
									if( sh.contacts[j].userid == value.userid ){

									}
								}
							}*/

							self.removeuserfromteam( value.userid, false , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										self.updateboardusers()
									}
								})


							})
						}

						if( callback ){
							callback( {"ok":"isnow"} )
						}

				})


				//console.log( sh, act, value )
			},

			unselect(){
				this.activestakeholder = {}
			},


			onmouseover( act, sh ){

				var ismobile = this.$store.state.isMobile

				if( ismobile )
				return false

				clearTimeout(this.mouseovert)

				let self = this


				if( act == 'out' ){

					this.mouseovert = setTimeout( function(){

						self.focusstakeholder = {}
						self.showpopover = false

					}, 100)
					
				}				

				if( this.showpopover == false ){						

						this.mouseovert = setTimeout( function(){
							self.focusstakeholder = sh
							self.showpopover = true
							clearTimeout(this.mouseovert)
						}, 20)

				}
				
			},
			onselected( sh , showinfo, isforced ){	

				this.editlabel = false
				this.editdescription = false
				
				this.showpopover = false

				let self = this


				let obj = $('#drag_'+sh._id).get(0)


				$('.ui-selected').not(obj).removeClass('ui-selected').removeClass('bg-info')
				$(obj).addClass('ui-selected')


					// if( $(obj).hasClass('ui-selected') ){

						this.mode = "edit"	


						this.activestakeholder = sh			

						self.sortstakeholders()		

						if( showinfo ){
							Userapi.Stakeholder( sh._id, this.token ).then( function(response){

								if( response.data.ok ){

									self.activestakeholder = self.formatstakeholder( response.data.stakeholder ) 
									self.activestakeholderlabel = self.activestakeholder.label
									self.activestakeholderdescription = self.activestakeholder.description

									/*setTimeout( function(){
										autosize($('.autogrow'));
									}, 100)*/
													

								}

							})
						}		

					//}else{

						//this.activestakeholder = {}

						//$( '#drag_'+sh._id ).css('z-index', 2500)
					//}

					setTimeout( function(){
						//self.$root.$emit('bv::show::tooltip')
					}, 100)


			},

			positioninterest( pos ){

				var div = this.windowspec == 'sm' ? 3 : ( this.windowspec == 'md' ? 4 : 5 )

				return ( pos+0 ) / div

			},

			positionpower( pos ){

				var div = this.windowspec == 'sm' ? 3 : ( this.windowspec == 'md' ? 4 : 5 )
				return ( ( div * 100 ) - pos ) / div
			},

			interestposition( value ){

				var div = this.windowspec == 'sm' ? 3 : ( this.windowspec == 'md' ? 4 : 5 )
				return Math.round(value) * div

				//return value + "%; margin-left: -7px;"

			},
			powerposition( value ){

				var div = this.windowspec == 'sm' ? 3 : ( this.windowspec == 'md' ? 4 : 5 )
				return  ( div * 100 ) - ( Math.round(value) * div )

				//return value + "%; margin-left: -7px;"

			},

			ondragging( ){

				//console.log( i.width, "xxxx" )

				//this.isdragging = true
				//i.width = 20
			},

			offdragging( i, ev ){

				/////////console.log( ev, i, "xxxx" )

				/*Userapi.Stakeholder( ] ).then( function(response){

						console.log( response, "xxxxxxxx")
					})*/


			},

			formatstakeholder( stakeholder ){


				var newcontacts = []

				if( stakeholder.contacts != null ){
				
					var contacts = stakeholder.contacts
					
					for( var i in contacts ){
						contacts[i].errormessage = ""
						contacts[i].state = 'idle'
						newcontacts.push( contacts[i] ) 
					}

				}

				stakeholder.contacts = newcontacts

				return stakeholder
			},

			newstakeholders( data ){


				var newstakeholders = []
				for( var i in this.stakeholders ){																

					if( this.stakeholders[i]._id == data.stakeholder._id ){
						newstakeholders.push( data.stakeholder )
						this.activestakeholder = data.stakeholder
					}else{

						newstakeholders.push( this.stakeholders[i] )
					}
				}

				this.stakeholders = newstakeholders

			},

			bindDraggables(){

				if( this.canwrite ){

				let self = this


							setTimeout( function(){

								if( self.sortedstakeholders ){

									/////*//////for( var i in self.sortedstakeholders ){
										//grid: [ 32, 32 ],

										//alert( self.stakeholders[i]._id )

										//console.log( $( '#drag_' + self.sortedstakeholders[i]._id ) , "init")

										var ismobile = self.$store.state.isMobile

										$( '.draggable' ).draggable( {  delay: ( ismobile ? 800 : 100 ), zIndex: 2500, containment: "parent", drag: function( j,k ){

												const power = self.positionpower( k.position.top )
												const interest = self.positioninterest( k.position.left )

												if( self.activestakeholder ){

													self.activestakeholder.power = power
													self.activestakeholder.interest = interest

													if( self.activestakeholder._id == self.focusstakeholder._id ){
														self.focusstakeholder.power = power
														self.focusstakeholder.interest = interest
													}
													
												}

												//console.log( self.activestakeholder, power, interest )

											j.stopPropagation();

										}, start: function( j,k ){

											let currentsh = j.currentTarget.id.replace('drag_','')

											for( var i in self.sortedstakeholders ){
												if( currentsh ==  self.sortedstakeholders[i]._id ){
													self.onselected( self.sortedstakeholders[i], false )
												}
											}

											//self.onselected( self.sortedstakeholders[i] )

											//self.activestakeholder = self.sortedstakeholders[i]

											//console.log( j.currentTarget )

											//

											//alert('dragstart')

											

											$('.ui-selected').removeClass('ui-selected').removeClass('bg-info')

											$(j.currentTarget).addClass('ui-selected')

											/*if( self.stakeholders[i] ){

												if( self.$refs['tooltip_'+self.stakeholders[i]._id] ){

													//console.log(self.$refs['tooltip_'+self.stakeholders[i]._id][0]  )
													self.$refs['tooltip_'+self.stakeholders[i]._id][0].$emit('close')
												}

												//self.$refs['tooltip_'+self.stakeholders[i]._id].$emit('close')
												//self.$root.$emit( 'bv::hide::tooltip', 'tooltip_'+self.stakeholders[i]._id )
											}*/


											j.stopPropagation();
											

										}, stop: function( j,k ) {		

											const objectid =  j.target.id.replace('drag_', '')



												for( var i in self.sortedstakeholders ){
													if( objectid ==  self.sortedstakeholders[i]._id ){
														self.onselected( self.sortedstakeholders[i], true )
														self.focusstakeholder = self.sortedstakeholders[i]
													}
												}

												/*for( var i in self.stakeholders ){
													if( objectid ==  self.stakeholders[i]._id ){
														self.stakeholders[i] = self.focusstakeholder
													}
												}		*/

												
												const power = self.positionpower( k.position.top )
												const interest = self.positioninterest( k.position.left )

												Userapi.UpdateStakeholder( { "t":self.token, "action":"update", "field":"values", "objectid": objectid, "power":power, "interest":interest } ).then( function(response){

															if( response.data.ok ){



																//self.newstakeholders( response.data )


																//sh = response.data.stakeholder
															}


															//self.$root.$emit('bv::show::tooltip', 'tooltip_'+self.stakeholders[i]._id)

												})


												j.stopPropagation();



							      } } );

									//////*//}
								}							


								//self.$root.$emit('bv::show::tooltip')  

							}, 100 )

				}

			},

			getallstakeholders(  ){


				let self = this

				Userapi.Stakeholders( this.cockpitid, this.token ).then( function(response){

						//this.getallstakeholders

						if( response.data.stakeholders ){						

							self.stakeholders = response.data.stakeholders




							//var containment = document.querySelector('#interactivepowerslider');

							self.bindDraggables()

							setTimeout( function(){
								//self.$root.$emit('bv::show::tooltip')

								if( self.stakeholders[0] ){
									self.onselected( self.stakeholders[0] , true, true)
								}

							}, 200)
							

						}
				
					})




			},

			createnewstakeholder( addanother ){

				let self = this

				if( this.stakeholdername != '' ){

					let newstakeholder = { "label":this.stakeholdername, "description":this.stakeholderdescription, "power":50.0, "interest":50.0 }

					//this.stakeholders.push( newstakeholder )

					this.stakeholdername = ""
					this.stakeholderdescription = ""

					//NewStakeholder

					Userapi.NewStakeholder( { "t":this.token, "action":"new", "boardid":this.cockpitid, "label": newstakeholder.label, "description": newstakeholder.description, "power":newstakeholder.power, "interest":newstakeholder.interest } ).then( function(response){

						self.stakeholders = response.data.stakeholders

						self.bindDraggables()

						if( addanother == false ){
							self.addnewstakeholder = false
						}else{

							setTimeout( function(){

								self.$refs.newstakeholdernameinput.focus()
								
							}, 100)
						}

						for( var i in self.stakeholders ){

						}

						setTimeout( function(){

							if( self.stakeholders[ self.stakeholders.length - 1 ] ){
								self.onselected( self.stakeholders[ self.stakeholders.length - 1 ] , true, true)
							}
								
						}, 100)


						
						
						
						//console.log( response, "xxxxxxxx")
					})

				}

				//$('.tinydraggable').tinyDraggable();


			}
		},

		components:{ contactsTable }

	}
</script>