<template>
  <div> 
    <b-modal v-model="closewhilediting" no-close-on-backdrop scrollable centered size = "l-x">
    <template v-slot:modal-header>
      <h3 class="modal-title">Close without saving?</h3>
    </template>
    <div class="bootbox-body">Pending changes is not yet saved, press ok to close window</div>
    <template v-slot:modal-footer="{ ok,cancel}">
       <button-gruppe @no ="closewhilediting = false" @yes = "closewhileditingfunction"></button-gruppe>    
    </template>
   </b-modal>

   <upgradeMaxusersdialog :opendialog="toupgrade" :cockpitid="cockpit.id" :token="token" @onwaitingforupgrade="waitingforupgrade" @onUpgrademodalhidden="upgrademodalhidden"></upgradeMaxusersdialog>


  <b-modal 
  id="cockpitsettingsmodalid"
  v-model="isshown" 
  modal-class="modal-cockpit fullscreen-modal" 
  size="lg" 
  @hidden="modalhidden" 
  @shown="modalshown" 
  @hide = "hideModal"
  hide-footer no-close-on-backdrop >

  <template v-slot:modal-header>
    <h3 class="modal-title mb-1">Board settings</h3>

    <a class = "close-modal dialog-close-button">
      <i class="fal fa-times fa-lg fa-lg" @click="isshown = false"></i>
    </a>
     
  </template>

  <div :class="{'hide':pagemode=='ready'}" > 
    <i class="fa fa-circle-o-notch fa-spin"></i>
  </div>    
  <div :class="{'hide':pagemode=='notready'}">


   
<div class="d-flex flex-wrap align-items-center mb-2 mt-2">
  <div class="ml-2 regular-text">
    <h3 class="overflow-string font-weight-bold m-0">{{cockpit.name}} </h3>
  </div>
  <div class="ml-2 regular-text">

        <span class="badge badge-light">
          <span v-if="!cockpit.setmaxuser || ( cockpit.setmaxuser && cockpit.setmaxuser.licensetype != 'pro' && cockpit.setmaxuser.status != 'request' )">Free</span>

          <span v-if="cockpit.setmaxuser && cockpit.setmaxuser.licensetype != 'pro' && cockpit.setmaxuser.status == 'request'">Free - upgrade in progress</span> 
          <span  v-if="cockpit.setmaxuser && cockpit.setmaxuser.licensetype == 'pro' && cockpit.setmaxuser.status != 'request'">Pro</span>

          <span  v-if="cockpit.setmaxuser && cockpit.setmaxuser.licensetype == 'pro' && cockpit.setmaxuser.status == 'request'">Pro - upgrade in progress</span> 
        </span>

  </div>
  <div class="ml-2 regular-text">
    <span v-if="showupgrade">
        <a href="#" @click="dotoupgrade()"  class="upgradetoprolink actioninfo">Upgrade to Pro</a>
      </span>
  </div>
  <div class="ml-auto regular-text">


<button v-if="myrole && !isactive && myrole.toUpperCase() == 'OWNER'" class="btn btn-warning-secondary" href="#" v-on:click="isshown = false; $emit('onmakeactive', 'yes', 'cockpit', cockpitid )">Make board active</button>

    <button v-if="myrole && isactive && myrole.toUpperCase() == 'OWNER'" class="btn btn-warning-secondary" href="#" v-on:click="isshown = false; $emit('onmakeactive', 'no', 'cockpit', cockpitid )">Make board inactive</button>

  </div>
</div>


 <div class="diverder"></div>
   


    <h4 class="font-weight-bold">Enable/disable modules</h4>


    <div class="form-row">
      
       <div class="d-flex align-items-center pt-1 pb-2">

        <b-form-checkbox :disabled="false" v-model="masterplanswitch" inline name="check-button" class="sliders" id="masterplanswitch"  @change="enablemasterplan(cockpit , $event)"  :size="ismobile?'lg':'md'" switch > 
         </b-form-checkbox>

         <span class="mr-2">Master plan</span>

         <a @click="explainmasterplan = explainmasterplan ? false : true">
          <i v-if="!explainmasterplan" class="far fa-info-circle"></i>
          <i v-if="explainmasterplan" class="fas fa-info-circle"></i>
         </a>

       </div>

       <div class="pl-5 p-2 bg-light" v-if="explainmasterplan">

        <p>When activated, all board members can access the Master plan module from the ‘Master plan’ tab. Board owners and contributors can add and remove tracks to the Master plan.</p>
        <p>A Master plan objective is created and added to the board. By default, only board owners and track  owners have access to the Master plan objective. Access to the other users and teams, can be given from the objective settings page. </p>
         

       </div>
         
    </div>

     <div class="form-row">

       <div class="d-flex align-items-center pb-2">
      
       <b-form-checkbox :disabled="false" v-model="stakeholderswitch" inline name="check-button" class="my-auto sliders" id="stakeholderswitch"  @change="enablestakeholders(cockpit , $event)"  :size="ismobile?'lg':'md'" switch > 
         </b-form-checkbox>

         <span class="mr-2">Stakeholders</span>

         <a @click="explainstakeholders = explainstakeholders ? false : true">
          <i v-if="!explainstakeholders" class="far fa-info-circle"></i>
          <i v-if="explainstakeholders" class="fas fa-info-circle"></i>
         </a>

      </div>

       <div class="pl-5 p-2 bg-light" v-if="explainstakeholders">

        <p>When activated, the stakeholder management module becomes available to all board members. Board owners and board contributors can add and remove stakeholders.</p><p>A stakeholder team with view access to the board is created, and to which stakeholders can be added. To give stakeholders access to objectives, use the objective settings.</p>
         

       </div>
         
    </div>

     <div class="form-row">

        <div class="d-flex align-items-center pb-2">

       <b-form-checkbox :disabled="false" v-model="riskswitch" inline name="check-button" class="my-auto sliders" id="riskswitch"   @change="enableriskmanagement(cockpit , $event)" :size="ismobile?'lg':'md'" switch > 
         </b-form-checkbox>

        <span class="mr-2">Risks</span>
         
        <a @click="explainrisks = explainrisks ? false : true">
          <i v-if="!explainrisks" class="far fa-info-circle"></i>
          <i v-if="explainrisks" class="fas fa-info-circle"></i>
        </a>

      </div>

        <div class="pl-5 p-2 bg-light" v-if="explainrisks">
          
          When activated, all board members can access the risk module from the ‘Risks’ tab. Board owners, contributors, and risk owners can add and remove risks.<br>
          A risk objective is created and added to the board. Only board and risk owners have access to the risk objective. Additional access can be added through the objective settings page.

        </div>
         
    </div>

    <div v-if="1==2">
      
       <b-form-checkbox :disabled="true" v-model="newsletterswitch" inline name="check-button" class="my-auto" id="newsletterswitch"  @change="enablenewsletter(cockpit , $event)"  size="lg" switch :checked="cockpit.stakeholders ? true : false" > <small>Newsletters</small>
         </b-form-checkbox>

    </div>
<!--
     <div v-if="1==0">
      
       <b-form-checkbox :disabled="false" v-model="newsletterswitch" inline name="check-button" class="my-auto" id="signinemailswitch"  @click="enableriskmanagement(cockpit , $event)"  size="lg" switch > <small>Master Planning</small>
         </b-form-checkbox>
         
    </div>


    <div>
      <span id="settings"> 
        <input id="enablestakeholderscheckbox" type="checkbox"  name="enablestakeholders" :checked="cockpit.stakeholders ? true : false" value="on" @click="enablestakeholders(cockpit , $event)">
        <label id="enablestakeholderslabel" for="enablestakeholderscheckbox" tabindex=3>Stakeholders</label>
      </span>
    </div>
    
    <div>
      <span id="settings"> 
        <input id="enablerismanagementcheckbox" type="checkbox"  name="enablerismanagement" :checked="cockpit.risks ? true : false" value="on" @click="enableriskmanagement(cockpit , $event)">
        <label id="enablerismanagementlabel" for="enablerismanagementcheckbox" tabindex=4>Risk management</label>
      </span>
    </div>
-->


   
    

 <objectivesTable @ontoupgrade="dotoupgrade" @onupdated="oncockpitupdated" @onupdate="onupdate" :checkforinvalidlink="checkforinvalidlink" :ttype="'cockpits'" :setmaxuser="cockpit.setmaxuser" :myrole="myrole" :isactive="isactive" ></objectivesTable>


    <hr>


    <p class="learn-licensing"><a class="actionprimary" href="https://goflowe.atlassian.net/servicedesk/customer/portal/1/article/1293287492?src=-252592629" target="_blank">Learn about board licensing <i class="fal fa-external-link"></i></a></p>

  </div>
</b-modal>
</div>
</template>

<style scoped>
  
  .custom-control-inline{
    margin-right: 5px !important;
    margin-left: 5px;
  }
</style>

<style type="text/css">

  .sliders{
 /*     margin-right: 0;*/
      margin-bottom: -2px !important;
  }
  
  @media screen and (max-width: 400px) {
    .learn-licensing {
      text-align: center;
    }

    .sliders{
      margin-bottom: 5px !important;
    }
  }


</style>

<script type="text/javascript"></script>
<script>

  import Cockpitsapi from '@/services/api/cockpits'
  import objectivesTable from './objectivesTable'
  import usersTable from './usersTable'

  import upgradeMaxusersdialog from '@/views/cockpit/components/upgradeMaxusersdialog'

  import {bus} from '@/main'

  //dropdownParent: $('#myModal')
  export default {
    name:'cockpitsettingsmodal',
    props:{
      shown:Boolean,
      loggedinuser:Object,
      cockpitid:String,
      checkforinvalidlink:Boolean,
      myrole:String,
      isactive:Boolean
    },
    data(){
     return{
      explainmasterplan : false,
      explainstakeholders : false,
      explainrisks : false,
      riskteam : {},
      masterplanteam : {},
      masterplanswitch: false,
      newsletterswitch: true,
      stakeholderswitch: false,
      riskswitch: false,
      isshown : false,
      closewhilediting: false,
      cockpitusernames:[],
      activemeasure:null,
      activeuser:null,
      cockpit:{},
      editorstate: {name:{content:'',state: 'idle'}, description: {content:'', state: 'idle'}}, 
      userissearching : false,
      users: [ {mode: 'idle', status:'active'}, {mode: 'idle', status:'active'}, {mode: 'idle', status:'pendingjoin'}, {mode: 'idle', status:'invited'}],
      pagemode : "notready",
      errors: [],
      availableobjectives:[],
      availableusers:[],
      toupgrade : false,
      stakeholdersenabled : false,
      riskmanagementenabled : false,
      withchanges : 0
        //Search for Objective to link
      }
    },
    computed : {

      ismobile(){

        return this.$store.state.isMobile
      },

    showupgrade(){
      
      var toreturn = false

      if( !this.cockpit.setmaxuser || (this.cockpit.setmaxuser && this.cockpit.setmaxuser.licensetype != 'pro' && this.cockpit.setmaxuser.status != 'request') ){
        toreturn = true
      }

      if( this.cockpit.setmaxuser && this.cockpit.setmaxuser.status == 'limited' ){
        toreturn = true
      }

      if( this.cockpit.setmaxuser && this.cockpit.setmaxuser.licensetype == 'pro' ){
        toreturn = false
      }

      return toreturn

    },

     

      orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : this.$store.state.organizations.selectedorganizations.key
      },

      token(){
        return localStorage.token
      },

      /*cockpit(){
        return this.$store.getters.GetCockpit
      },*/

    },
    watch:{

      cockpit : function( e,v ){

       // console.log( e.risks._id , v.risks._id, "alert")


        if( e.stakeholders && e.stakeholders._id ){
          this.stakeholdersenabled = true
          this.stakeholderswitch = true
        }else{
          this.stakeholdersenabled = false
          this.stakeholderswitch = false
        }


        if( e.risks && e.risks._id ){
          this.riskmanagementenabled = true
          this.riskswitch = true
        }else{
          this.riskmanagementenabled = false
          this.riskswitch = false
        }

        if( e.masterplanobjective && e.masterplanobjective.isactive == true ){
          this.masterplanswitch = true
        }else{
          this.masterplanswitch = false
        }


        

      },
      shown : function( e ){
        this.isshown = e
      },

    },

    components:{objectivesTable, usersTable, upgradeMaxusersdialog },
    
    mounted() {

      let self = this
      this.$nextTick(function() {
        window.addEventListener("resize", function(){

        });
        //window.addEventListener(‘resize’, this.getWindowHeight);
      })
      
      this.cockpit = {}
    },

    methods:{

      /*stakeholdersisenabled( ev, indicator ){

      },

      stakeholdersisenabled( ev, indicator ){

      },*/


      disableobjectiveformasterplan( board, enable ){

        let self = this

        let disablevalue = false

        //if ( enable )  {
          disablevalue = enable ? enable : false
        //}

        let masterplanobjectiveid = this.cockpit.masterplanobjective ? this.cockpit.masterplanobjective._id : null;

        if( masterplanobjectiveid ){


          Cockpitsapi.Objectivesave( { "field":"isactive", "value":disablevalue, "t":this.token, "mid": masterplanobjectiveid } )
          .then( function(response){
            //console.log(response)
            if( response.data.ok ){
              
              self.withchanges += 1

              self.cockpitsettings( function(){
                bus.$emit('objectivetableloadobjectives', self.cockpit, self.token, self.loggedinuser )
              })
            }

          })
          .catch( function(){
                  //self.cockpitsettingsmodalshown = false
              })
          .finally( () => {
            //this.editorstate.description.content = this.cockpit.description
            //this.editorstate.name.content = this.cockpit.name
          })


        }

          
          

      },

      createanobjectiveformasterplan( board ){

        let self = this

        let masterplanobjectiveid = this.cockpit.masterplanobjective ? this.cockpit.masterplanobjective._id : null;

        if( !masterplanobjectiveid ){

          let bowners = []

          for( let i in board.users ){
            if( board.users[i].role == "Owner" ){
              bowners.push( board.users[i]._id )              
            }
          }

          let roles = JSON.stringify(bowners)
          
          bowners = []
          bowners.push( this.masterplanteam._id ) // add risk team as viewer

          let teams = JSON.stringify(bowners)

          Cockpitsapi.Objectivenew( {  "context":this.orgkey, "t":this.token, "cockpitid": board.id, "measuretype": "masterplan", "measuredescription":"", "measureshortdescription":"", "measurename": "Master plan - " + board.name , "measureindex":true, "aggregationmethod":"worst", "nodes": [], "sharedtousers": roles, "sharedtoteams": teams } )
          .then( function(response){

             self.cockpitsettings( function(){


                bus.$emit('objectivetableloadobjectives', self.cockpit, self.token, self.loggedinuser )
                // bus.$emit('userstableloadusers', self.cockpit, self.token, self.loggedinuser )

              })

          })

        }          

      },


      disableobjectiveforriskmanagement( board, enable ){

        let self = this

        let disablevalue = false

        //if ( enable )  {
          disablevalue = enable ? enable : false
        //}

        let riskobjectiveid = this.cockpit.riskobjective ? this.cockpit.riskobjective._id : "";

          Cockpitsapi.Objectivesave( { "field":"isactive", "value":disablevalue, "t":this.token, "mid": riskobjectiveid } )
          .then( function(response){
            //console.log(response)
            if( response.data.ok ){
              self.withchanges += 1

                   self.cockpitsettings( function(){


                                  bus.$emit('objectivetableloadobjectives', self.cockpit, self.token, self.loggedinuser )
                                  // bus.$emit('userstableloadusers', self.cockpit, self.token, self.loggedinuser )

                                })

              //self.$store.dispatch('fechtCockpit',{cid: this.cockpit.id, orgkey: self.orgkey, token: self.token})
              //self.$emit('cockpitforceupdates')
            }


          })
          .catch( function(){
                  //self.cockpitsettingsmodalshown = false
              })
          .finally( () => {
            //this.editorstate.description.content = this.cockpit.description
            //this.editorstate.name.content = this.cockpit.name
          })
          

      },

      createanobjectiveforriskmanagement( board ){

        let self = this

        let riskobjectiveid = this.cockpit.riskobjective ? this.cockpit.riskobjective._id : null;

        if( !riskobjectiveid ){

          let bowners = []

          for( let i in board.users ){
            if( board.users[i].role == "Owner" ){

              //let line = JSON.stringify( { "userid":board.users[i]._id, "role":board.users[i].role } )
              bowners.push( board.users[i]._id )              
            }
          }

          let roles = JSON.stringify(bowners)


          bowners = []

         // console.log(  this.riskteam )

          /*

          for( let i in board.tcusers ){
            //if( board.tcusers[i].type == "risk" ){

              //let line = JSON.stringify( { "userid":board.users[i]._id, "role":board.users[i].role } )
              bowners.push( board.tcusers[i]._id )              
            //}
          }*/

           bowners.push( this.riskteam._id ) // add risk team as viewer

          let teams = JSON.stringify(bowners)



          Cockpitsapi.Objectivenew( {  "context":this.orgkey, "t":this.token, "cockpitid": board.id, "measuretype": "risk", "measuredescription":"", "measureshortdescription":"", "measurename": "Risk - " + board.name , "measureindex":true, "aggregationmethod":"worst", "nodes": [], "sharedtousers": roles, "sharedtoteams": teams } )
          .then( function(response){




             self.cockpitsettings( function(){


                bus.$emit('objectivetableloadobjectives', self.cockpit, self.token, self.loggedinuser )
                // bus.$emit('userstableloadusers', self.cockpit, self.token, self.loggedinuser )

              })

          })

        }          

      },


      disableteamforriskmanagement( board ){

        let self = this

        this.$store.dispatch('updateTeams', {field:"active", newvalue:false, context:this.orgkey, "type":"risk", boardid: board.id ,t:this.token}).then((response) =>{

             self.cockpitsettings( function(){


                bus.$emit('objectivetableloadobjectives', self.cockpit, self.token, self.loggedinuser )
                // bus.$emit('userstableloadusers', self.cockpit, self.token, self.loggedinuser )

              })

        })
          

      },

      createateamforriskmanagement( board, callback ){

        let self = this

        if( this.riskswitch == true ){

          let newteam = { "boardid": board.id , "type":"risk", "name": "Risk - " + board.name, "context":this.orgkey, "t":this.token, "addtoboard":true }          

          this.$store.dispatch('createTeam', newteam, true ).then(res =>{

            self.riskteam = res.data.team
            self.withchanges += 1

            if( callback ){

              callback()
            }



          })

        }          

      },


      disableteamformasterplan( board ){

        let self = this

        this.$store.dispatch('updateTeams', {field:"active", newvalue:false, context:this.orgkey, "type":"masterplan", boardid: board.id ,t:this.token}).then((response) =>{
          self.withchanges += 1
        })
          

      },

      createateamformasterplan( board , callback ){

        let self = this

        if( this.masterplanswitch == true ){

          let newteam = { "boardid": board.id , "type":"masterplan", "name": "Master plan - " + board.name, "context":this.orgkey, "t":this.token, "addtoboard":true }          

          this.$store.dispatch('createTeam', newteam, true ).then(res =>{

            self.masterplanteam = res.data.team
            self.withchanges += 1

            if( callback ){

              callback()
            }

          })

        }          

      },


      disableteamforstakeholder( board ){

        let self = this

        this.$store.dispatch('updateTeams', {field:"active", newvalue:false, context:this.orgkey, "type":"stakeholder", boardid: board.id ,t:this.token}).then((response) =>{
          self.withchanges += 1
        })
          

      },

      createateamforstakeholder( board ){

        let self = this

        if( this.stakeholderswitch == true ){

          let newteam = { "boardid": board.id , "type":"stakeholder", "name": "Stakeholder - " + board.name, "context":this.orgkey, "t":this.token, "addtoboard":true }          

          this.$store.dispatch('createTeam', newteam, true ).then(res =>{

            self.withchanges += 1

          })

        }          

      },

      enableriskmanagement( board , ev ){

        

        let self = this


        let riskobjectiveid = this.cockpit.riskobjective ? this.cockpit.riskobjective._id : null;

        setTimeout( function(){

            if( self.riskswitch == true ){
              

              self.createateamforriskmanagement( board , function(){

                if( !riskobjectiveid ){
                  self.createanobjectiveforriskmanagement( board )  
                }else{
                  self.disableobjectiveforriskmanagement( board , true)
                }

              })

              //self.explainrisks = true

              
            }else{

              self.disableteamforriskmanagement( board )
              self.disableobjectiveforriskmanagement( board )

               //self.explainrisks = false
            }

        },100) 


      },


      enablemasterplan( board , ev ){


          let self = this

          let masterplanobjectiveid = this.cockpit.masterplanobjective ? this.cockpit.masterplanobjective._id : null;

          setTimeout( function(){

            if( self.masterplanswitch == true ){
             // self.explainstakeholders = true
              self.createateamformasterplan( board  , function(){

                if( !masterplanobjectiveid ){
                  self.createanobjectiveformasterplan( board )  
                }else{
                  self.disableobjectiveformasterplan( board , true)
                }

              })

            }else{
             //self.explainstakeholders = false
              self.disableteamformasterplan( board )
              self.disableobjectiveformasterplan( board )


            }


          }, 100)

      },


      enablestakeholders( board , ev ){

        let self = this

        setTimeout( function(){

          if( self.stakeholderswitch == true ){
           // self.explainstakeholders = true
            self.createateamforstakeholder( board )  
          }else{
           //self.explainstakeholders = false
            self.disableteamforstakeholder( board )
          }


        }, 100)


        

      },

      upgrademodalhidden : function( callback ){

        this.toupgrade = false

        if( callback == 'closemodal' ){

          if( this.cockpit.setmaxuser ){
            this.cockpit.setmaxuser.status = "request"
          }else{
            this.isshown = false
          }

        }
      },

      dotoupgrade(){

        this.toupgrade = true

      },

      waitingforupgrade : function( callback ){
        this.toupgrade = false
        //this.cockpit.setmaxuser.status = "request"

        if( this.cockpit.setmaxuser ){
          this.cockpit.setmaxuser.status = "request"
        }else{
          this.isshown = false
        }

        setTimeout( function(){
          $('.actioninfodark').blur()
        },500)

         
      },
      
      // canceltitel(){
      //   this.editorstate.name.state='idle'; 
      //    this.editorstate.name.content = this.cockpit.name
      // },

      // opentitel(){
      //   this.editorstate.name.state = 'editing'
      //   this.$nextTick(function() {
      //     this.savedescription()
      //   })
      // },

      // opendescription(){
      //   this.editorstate.description.state='editing'
      //   this.$nextTick(function() {
      //     this.savename()
      //   })
      // },

      // canceldescription(){
      //   this.editorstate.description.state='idle'
      //   this.editorstate.description.content = this.cockpit.description
      // },


      // savename(){
      //   this.cockpit.name = this.editorstate.name.content
      //   this.cockpitupdate( 'name', this.cockpit.name) 
      //   this.editorstate.name.state='idle'
      // },

      // savedescription(){
        
      //   this.cockpit.description = this.linkInitControls.getEditor().html.get()
      //   this.editorstate.description.content = this.cockpit.description
      //   this.cockpitupdate('description', this.cockpit.description ); 
      //   this.editorstate.description.state='idle'
      // },



      // initializefroala: function(initControls) {
      //   this.linkInitControls = initControls;
      // },

      onupdate(){

        this.$emit('oncockpitupdated')
      },

      oncockpitupdated( field, measureids ){
        
        let self = this

        this.cockpitupdate( field, measureids);
      },

      cockpitupdate( field, value, callback ){

        var savedvalue = value
        console.log()

        if( field == "measures"){
          savedvalue = JSON.stringify(value)
        }

        if( field == "users"){
          savedvalue = JSON.stringify(value)
        }
        

        let self = this

        Cockpitsapi.Cockpitsave( { "field":field, "value":savedvalue, "t":this.token, "cid":this.cockpit.id } )
        .then( function(response){
          
          if( response.data.ok ){
            self.$emit('oncockpitupdated')

            if( callback ){
              callback()
            }
          }


        })
        .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
        .finally( () => {
          this.editorstate.description.content = this.cockpit.description
          this.editorstate.name.content = this.cockpit.name
        })
        

      },

      modalshown(){

        let self = this

        // this.linkInitControls.initialize()

        this.cockpitsettings( function(){


          bus.$emit('objectivetableloadobjectives', self.cockpit, self.token, self.loggedinuser )
          // bus.$emit('userstableloadusers', self.cockpit, self.token, self.loggedinuser )

        })

      },

      cockpitsettings( callback ){
        let self = this
        
        Cockpitsapi.Cockpitedit( this.cockpitid, "objectives", this.token )
        .then( function(response){

          if( response.data ){

            self.cockpit = response.data.cockpits
            self.editorstate.name.content = self.cockpit.name
            self.editorstate.description.content = self.cockpit.description
            self.pagemode = "ready"

            if( callback ){
              callback()
            }

            
          }

        })
        .catch( function(){
            //self.cockpitsettingsmodalshown = false
          })
        .finally( () => {
        })

        
        
      },

      modalhidden(){

        bus.$off('objectivetableloadobjectives' )
        bus.$off('userstableloadusers' )
        bus.$off('onafterupdated' )

        this.pagemode = "notready"
        this.cockpit = {}
        this.$emit('onmodalhidden', this.withchanges)
        this.editorstate = {name:{content:'',state: 'idle'}, description: {content:'', state: 'idle'}};
        this.$func.removeQuery(['page'],this.$route,this.$router)
        this.isshown = false

      },

      hideModal(bvModalEvt){

        history.pushState("", document.title, window.location.pathname + window.location.search);

        if( this.editorstate.name.state == 'editing' || this.editorstate.description.state == 'editing' ){
          bvModalEvt.preventDefault()
          this.closewhilediting = true
          history.pushState("", document.title, window.location.pathname + window.location.search);
        }
      },

      
    }
  }
</script>