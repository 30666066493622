<template>
	<div>

		<b-modal v-model="closewhilediting" no-close-on-backdrop no-close-on-esc scrollable centered size = "l-x">
			<template v-slot:modal-header>
				<h3 class="modal-title closeconfirm">Close without saving?</h3>
			</template>
			<div class="bootbox-body">Your changes have not yet been saved. Press OK to close the window without saving.</div>
			<template v-slot:modal-footer="{ ok,cancel}">
				<button-gruppe @no ="closewhilediting = false" @yes = "closewhileditingmodal"></button-gruppe>               
			</template>
		</b-modal>

		<b-modal 
		id="cockpitdescriptionmodal" 
		size="lg"
		v-model="isshown" 
		@hidden="modalhidden" 
		@shown="modalshown" 
		@hide = "hideModal"
		hide-footer>
		<template v-slot:modal-header>
			<h3 class="modal-title">Description </h3>
			<a class = "close-modal dialog-close-button">
				<i class="fal fa-times fa-lg fa-lg" @click="isshown = false"></i>
			</a>
		</template>
		<div :class="{'hide':pagemode=='ready'}" > 
			<i class="fa fa-circle-o-notch fa-spin"></i>
		</div>
		<div :class="{'hide':pagemode=='notready'}">

			<div class="form-group" data-state="idle" >
				<label>Title</label>
				<div class="editable" v-if="(editorstate.name.state=='idle' && editorstate.description.state == 'idle') && myrole.toUpperCase() == 'OWNER'  && isactive" v-on:click=" opentitel()">
					<div class="card p-2 noshadow readonly titletext mb-0" >
						<span v-text="cockpit.name"></span>
						<i  v-if="myrole.toUpperCase() == 'OWNER'" class="fa fa-pencil editableicon"></i>
					</div>
					
				</div>

				<div class="" v-else-if="editorstate.name.state=='idle' ">
					<div class="card p-2 noshadow readonly titletext mb-0" v-text="cockpit.name">
					</div>
				</div>


				<input v-if="editorstate.name.state=='editing'" id="cockpittitle" type="text" class="form-control inputtext" name="name" placeholder="Enter a title (required)" maxlength="70" v-model="editorstate.name.content" >

				<div class="text-danger" v-if="errors.cockpitname">
					<span v-if="errors.cockpitname == 'titlerequired'" maxlength="150">Cockpit name is required</span>
				</div>
			</div>

			<div class="form-group text-right" v-if="editorstate.name.state=='editing' ">
				<button-gruppe text1 = "Save" @no ="canceltitel()" @yes = "savename()" :disabled2 ="!namehascontent"></button-gruppe>
			</div>

			<div class="form-group">

				<label>Description</label>

				<div class="editable" v-if="(editorstate.name.state=='idle' && editorstate.description.state == 'idle') && myrole.toUpperCase() == 'OWNER' && isactive" @click.prevent="opendescription()">
					<div class="card p-2 noshadow readonly descriptiontext mb-0 fr-view"   >
						<span v-html="cockpit.description"></span>
						<i class="fa fa-pencil editableicon"></i>
					</div>
					
				</div>

				<div v-else-if="editorstate.description.state == 'idle'" class="" >
					<div class="card p-2 noshadow readonly descriptiontext mb-0 fr-view" v-html="cockpit.description">
					</div>
				</div>
				<div v-if="myrole.toUpperCase() == 'OWNER' " :class = "{'hide':editorstate.description.state == 'idle'}">
					<froala id="cockpitdescription" :tag="'textarea'" :config="froalaconfig" v-model="editorstate.description.content" class="mb-0" :onManualControllerReady="initializefroala"></froala>
				</div> 


			</div>

			<div class="form-group text-right" v-if="editorstate.description.state=='editing'">
				<button-gruppe text1 = "Save" @no ="canceldescription()" @yes = "savedescription()" default = "none" :disabled="imageuploading == true"></button-gruppe>
			</div>
		</div>

	</b-modal>
</div>
</template>

<style lang ="scss">
	
.rotateimg90 {
-webkit-transform:rotate(90deg);
-moz-transform: rotate(90deg);
-ms-transform: rotate(90deg);
-o-transform: rotate(90deg);
transform: rotate(90deg);
}

.rotateimg180 {
-webkit-transform:rotate(180deg);
-moz-transform: rotate(180deg);
-ms-transform: rotate(180deg);
-o-transform: rotate(180deg);
transform: rotate(180deg);
}

.rotateimg270 {
-webkit-transform:rotate(270deg);
-moz-transform: rotate(270deg);
-ms-transform: rotate(270deg);
-o-transform: rotate(270deg);
transform: rotate(270deg);
}




</style>

<script type="text/javascript"></script>

<script>
	import Cockpitsapi from '@/services/api/cockpits'
	import {bus} from '@/main' 
	export default{
		name:'cockpitdescription',
		props:{
			shown:Boolean,

		},
		data(){
			return{
				isshown : false,
				closewhilediting: false,
				cockpitusernames:[],
				activemeasure:null,
				activeuser:null,
				cockpit:{},
				editorstate: {mode: 'idle', name:{content:'',state: 'idle'}, description: {content:'', state: 'idle'}}, 
				pagemode : "notready",
				errors: [],
				froalaconfig: {},
				availableobjectives:[],
				availableusers:[],
				loggedinuser:{},
				cockpitid:'',
				token:'',
				checkforinvalidlink:null,
				description:'',
				myrole:'',
				ttype:'',
				isactive:true,				
        		imageuploading : false
		        //Search for Objective to link
		    }
		},


		watch:{
			shown : function( e ){
				console.log(e)
				this.isshown = e
			},

		},

		mounted() {
			
			let self = this
			bus.$on('onopendescription',this.initcomponent)

				this.froalaconfig = this.$store.state.config
				this.froalaconfig.heightMax = 'none'
				this.froalaconfig.events =Object.assign(this.froalaconfig.events,
					
					{
					'image.beforeUpload': function (images, lo ) {
						self.imageuploading = true
					},

					'image.inserted': function ($img, response) {
						self.imageuploading = false
					},
					
				})

				$.getJSON( "https://s3token.flowe.io/", function(data){
					self.froalaconfig.imageUploadToS3 = data        
				})


				this.cockpit = {}
		},

		computed:{
			namehascontent(){
				var toreturn = true
				if (!this.editorstate.name.content.replace(/\s/g, '').length) {
				  toreturn = false
				}
				return toreturn
			},

			orgkey(){
		        return this.$route.params.orgname ? this.$route.params.orgname : null
		      }
		},

		methods:{
			initcomponent(iid, loggedinuser, token, myrole, ttype, checkforinvalidlink,isactive){

				this.cockpitid = iid;
				this.loggedinuser = loggedinuser;
				this.token = token;
				this.myrole = myrole
				this.ttype = ttype
				this.isactive = isactive
			},



			canceltitel(){
				this.editorstate.name.state='idle'; 
				this.editorstate.name.content = this.cockpit.name
			},

			opentitel(){
				if(this.editorstate.description.state == 'idle'){
					this.editorstate.name.state = 'editing'

					this.$nextTick(function() {
						setTimeout(()=>{
							$('#cockpittitle').focus()
						},100)
					})
		
				}
			},

			opendescription(){
				if(this.editorstate.name.state == 'idle'){
					this.editorstate.description.state='editing'
				}
			},

			canceldescription(){
				this.editorstate.description.state='idle'
				this.editorstate.description.content = this.cockpit.description
			},


			savename(){
				this.cockpit.name = this.editorstate.name.content
				this.cockpitupdate( 'name', this.cockpit.name) 
				this.editorstate.name.state='idle'
			},

			savedescription(){

				this.cockpit.description = this.linkInitControls.getEditor().html.get()
				this.editorstate.description.content = this.cockpit.description
				this.cockpitupdate('description', this.cockpit.description ); 
				this.editorstate.description.state='idle'
			},



			initializefroala: function(initControls) {
				this.linkInitControls = initControls;
				this.linkInitControls.initialize()
			},

			oncockpitupdated( field, measureids ){
				let self = this

				this.cockpitupdate( field, measureids);
			},

			cockpitupdate( field, value, callback ){

				var savedvalue = value

				let self = this
				if(this.ttype == 'cockpit'){
					Cockpitsapi.Cockpitsave( { "field":field, "value":savedvalue, "t":this.token, "authid":this.loggedinuser._id, "cid":this.cockpitid } )
					.then( function(response){
						
						if( response.data.ok ){
							self.$emit('oncockpitupdated')

							if( callback ){
								callback()
							}
						}


					})
					.catch( function(){
			        })
					.finally( () => {
						this.editorstate.description.content = this.cockpit.description
						this.editorstate.name.content = this.cockpit.name
					})

				} else if(this.ttype == 'objective'){

					Cockpitsapi.Objectivesave( { "field":field, "value":savedvalue, "t":this.token, "mid":this.cockpitid} )
					.then( function(response){
						console.log(response)
						if( response.data.ok ){
							self.$emit('oncockpitupdated')

							if( callback ){
								callback()
							}
						}


					})
					.catch( function(){
			            //self.cockpitsettingsmodalshown = false
			        })
					.finally( () => {
						this.editorstate.description.content = this.cockpit.description
						this.editorstate.name.content = this.cockpit.name
					})
				}
			},

			modalshown(){

				 let self = this

				
			

				this.cockpitsettings()
			},


			cockpitsettings( callback ){
				
				let self = this

				if(this.ttype == 'cockpit'){

					Cockpitsapi.Cockpitedit( this.cockpitid, "description", this.token )
					.then( function(response){
						console.log(response)
						if( response.data ){
							self.cockpit = response.data.cockpits
							self.editorstate.name.content = self.cockpit.name
							self.editorstate.description.content = self.cockpit.description
							self.pagemode = "ready"

							if( callback ){
								callback()
							}


						}

					})
					.catch( function(){
	           		 //self.cockpitsettingsmodalshown = false
	           		})
					.finally( () => {
					})

				} else if(this.ttype == 'objective'){
					
					Cockpitsapi.Objectiveedit( this.cockpitid,"description", this.token, this.orgkey )
					.then( function(response){

						if( response.data ){
							self.cockpit =  response.data.measure
							self.editorstate.name.content = self.cockpit.name
							self.editorstate.description.content = self.cockpit.description
							self.pagemode = "ready"
							self.description = self.cockpit.description

							if( callback ){
								callback()
							}


						}

					})
					.catch( function(){
	           		 //self.cockpitsettingsmodalshown = false
	           		})
					.finally( () => {
					})
				}
			},

			modalhidden(){

				bus.$off('objectivetableloadobjectives' )
				bus.$off('userstableloadusers' )
				bus.$off('onafterupdated' )

				window.removeEventListener("keyup", function(event) {
		              // Number 13 is the "Enter" key on the keyboard
		              if (event.keyCode === 13) {
		              	document.getElementById('savebutton').click()
		              	event.preventDefault()

		              }
		          });

				this.pagemode = "notready"
				this.cockpit = {}
				this.$emit('onmodalhidden')
				this.editorstate = {mode:'idle', name:{content:'',state: 'idle'}, description: {content:'', state: 'idle'}};

				this.$func.removeQuery(['page'],this.$route, this.$router)

				this.isshown = false

			},

			
			hideModal(bvModalEvt){
				if( this.editorstate.description.state == 'editing' || this.editorstate.name.state == 'editing'  ){
					bvModalEvt.preventDefault()

					this.closewhilediting = true
				}
			},

			closewhileditingmodal(){

				this.editorstate.name.state = 'idle' 
				this.editorstate.description.state = 'idle'
				this.closewhilediting = false;
				this.isshown = false;
			},

		}
	}
</script>