<script type="text/javascript"></script>
<template>
	<div>
		<b-modal ref = "newslettermodal" no-close-on-backdrop no-close-on-esc no-fade size="sm-p" centered v-model="showdeleteactivenewsletter ">
			
			<template v-slot:modal-header>


				<h3 class="modal-title"><span>Delete draft newsletter</span></h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg " @click="showdeleteactivenewsletter = false" ></i>
				</a>
			</template>
			Click 'OK' to permanently delete this draft newsletter. <br>Note that deleted newsletters cannot be recovered.


			<template v-slot:modal-footer="{ cancel, ok }" >
				<button-gruppe text1="Confirm"  @no ="showdeleteactivenewsletter = false" @yes = "deleteactivenewsletter(newsletter)"></button-gruppe>

			</template>



		</b-modal>
		<b-modal 
		id="newslettermodalid"
		v-model="isshown" 
		modal-class="" 
		size="xl"
		@hidden="modalhidden" 
		@shown="modalshown"
		hide-footer no-fade no-close-on-backdrop no-close-on-esc >

		<template v-slot:modal-header>
			<h3 class="modal-title" v-if="cockpit"> 
				<span 
				v-if="page=='editor'||page=='preview'||page=='publish'">Edit newsletter </span>
				<span v-else-if = "newsletter.name"> Newsletter </span> <span v-else>Newsletter </span>
			<span v-if="newsletter.name && page == 'overview'"></span></h3>
			<a class = "close-modal dialog-close-button mr-2">
				<i class="fal fa-times fa-lg " @click="isshown = false" ></i>
			</a>

		</template>


		<div :class="{'hide':pagemode=='ready'}" > 
			<i class="fa fa-circle-o-notch fa-spin"></i>
		</div>

		<div v-if="mode=='creating'">
			<p>A newsletter is great for giving all board members a periodic status on progress, successes, and issues.
			</p><p>
To create a newsletter choose the template that best fits your style, give the newsletter a name, eg. ‘January update’, and select a period the newsletter represents.
Flowe will pull data from your board for the selected period and create a draft newsletter with the data organized according to the template. </p>
		</div>

		<div v-if="activelockedto.islocked && ( activelockedto.userid != profile._id ) ">This draft newsletter is currently checked out by {{ activelockedto.user }}. Try again later, or ask them to close it.</div>
		<div v-else-if="cockpit">
		<div class="card noborder noshadow">
			<div class="card-body p-0">
				<div class="card-body" v-if="drafttingnewslettertabs == false && mode == 'creating' && pagemode == 'ready'">
					<form method="get" action="#" id="newslettercreateform" >
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<select v-model = "template" id="newslettertemplateselect" name="template" class="form-control">
										<option value="team1" selected="selected">Team newsletter template</option>
										<option value="blank">Blank template</option>
									</select>	
								</div>
							</div>
							<div class="col-md-5">
								<div class="form-group">
									<input type="text" id="newslettertitle"  name="title" v-model="newslettertitle" placeholder="Give the newsletter a title" onfocus="this.placeholder = '' " onblur="this.placeholder = 'Give the newsletter a title'" maxlength="80" class="form-control">

									<p class="text-danger" v-if="invalidtitle != '' && invalidtitle == 'invalid'">Title is empty</p> <p class="text-danger" v-if="invalidtitle != '' && invalidtitle == 'maxlength'">Title is more than 80 characters</p>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
		
									<flat-pickr readonly="readonly" :config="calendarConfigstart" v-model="periodStart" name='from' 
									id="newsletterdatefrom" placeholder="Period from" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Period from'" class="form-control" type="text"></flat-pickr>
									<p class="text-danger" v-if="invalidfrom == 'invalid'">Invalid date</p>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<flat-pickr readonly="readonly" :config="calendarConfigend" v-model="periodend" 
									id="newsletterdateto" placeholder="Period to" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Period from'"  class="form-control" type="text" name="to"></flat-pickr>

									<p class="text-danger" v-if="invalidto == 'invalid'">Invalid date</p>
								</div>
							</div>
						</div>


						<div class="row">
							<div class="col text-right">
								<div style="visibility: hidden; height: 10px;">
								<Boardcharts :size="120" @ondumppng="dumppng" ></Boardcharts>
								</div>

								<button type="button" :disabled = " newslettertitle == '' || periodend == null || periodStart == null " @click.prevent="createdraft(); " class="btn btn-primary" aria-selected="false">Import data and create newsletter</button>

							</div>
						</div>
					</form>
				</div>
				<!-- Nav tabs -->
				<div class="row newsletternavtabe" v-if="(cockpit.myrole == 'owner' || cockpit.myrole == 'contributor') && drafttingnewslettertabs && mode !='ispublished'" style = "padding-bottom: 20px">
					<div class="nav-center d-flex w-100 mx-3">
						<ul class="nav nav-tabs mx-auto customtab steps" role="tablist" id="newslettertab">
							<li class="nav-item">
								<a v-bind:class="{'hide':maxstep > 1}" class="nav-link disabled text-muted" @click ="page = 'editor'" data-toggle="tab" href="#" role="tab"> <span>Edit</span></a>
								<a v-bind:class="{'active':page=='editor'}" class="nav-link newslettersteps" @click ="page = 'editor'" data-toggle="tab" href="#edit" role="tab"><span >Edit</span></a> 
							</li>
							<li class="nav-item"> 
								<a v-bind:class="{'hide':maxstep > 2}" class="nav-link disabled text-muted" @click ="page = 'preview'"  data-toggle="tab" href="#" role="tab"><span >Preview</span></a> 
								<a v-bind:class="{'active':page=='preview', 'hide':maxstep <= 1}" @click ="page = 'preview'" class="nav-link newslettersteps" data-toggle="tab" href="#preview" role="tab"> <span >Preview</span></a> 
							</li>
							<li class="nav-item"> 
								<a v-bind:class="{'hide':maxstep > 3}" class="nav-link disabled text-muted" @click ="page = 'publish'"  data-toggle="tab" href="#" role="tab"><span>Publish</span></a> 
								<a v-bind:class="{'active':page=='publish', 'hide':maxstep <= 2}" @click ="page = 'publish'" class="nav-link newslettersteps" data-toggle="tab" href="#publish" role="tab"> <span >Publish</span></a> 
							</li>
						</ul>
						<button href="#" class="btn btn-danger mr-3 "  style ="margin-left: -180.017px" v-on:click="showdeleteactivenewsletter = true; activenewsletter = newsletter">Delete this newsletter</button>
					</div>
				</div>

				<!-- <div class = "newslettersmall">
					<span class = "mx-auto "> you don't have access to draftting newsletters on this type of devise </span>
				</div> -->
				<div class="tab-content" v-if ="drafttingnewslettertabs && pagemode =='ready'" > 

					<div class="tab-pane p-t-20" v-bind:class="{'active':page=='editor'}" id="edit" role="tabpanel">                            
						<!-- editor -->

						<div id="draftnewsletter">

							<!-- 
							<div v-bind:class="{hide:cockpit.myrole == 'owner' || cockpit.myrole == 'contributor'}">You don't have access to this functions</div> -->

							<div class="d-flex" :class ="{'flex-row':devisetype == 'desktop', 'flex-column':devisetype != 'desktop'}">
								<div id="newslettereditorstage" class="" v-bind:class="{hide:cockpit.myrole != 'owner' && cockpit.myrole != 'contributor', 'col-12': devisetype !='desktop', 'col-8': devisetype =='desktop' }">    

									<p v-if="errors.length > 0">{{ errors }}</p>

									<div class="card" id="newslettereditorcard">
										<div class="px-1 mx-3 py-2 d-flex flex-row">
											<div class ="w-100" >
												<h3 class ="m-0">{{newsletter.name}} ({{newsletter.periodstart | timeFormat}} - {{newsletter.periodend | timeFormat}})</h3>
												<h4 class="pt-2 font-weight-bold " style="display: inline;">{{cockpit.name}}  </h4>
													
												</div>
												
											<div class="issaving d-flex ml-auto mr-2 mt-1" style ="width: 120px" nowrap v-if="errorloading"> <span id ="errormessage" @click.prevent = "openpopoverinit" class =" ml-auto d-inline-block actiondelete mt-auto text-wrap"> Error loading</span></div>

											<div class="issaving d-flex ml-auto mr-2 mt-1" style ="width: 100px" nowrap v-else-if="savingnewsletter" > <span class = " ml-auto d-inline-block mt-auto text-wrap">Saving <i class="fa fa-circle-o-notch fa-spin"></i></span> 
											</div>
											<div class="issaving d-flex ml-auto mr-2 mt-1" style ="width: 100px" v-else> 
												<span class = "ml-auto mt-auto d-inline-block text-wrap" >Saved</span> 
											</div>

										</div>

										<froala id="newslettereditor" :tag="'textarea'" :config="froalaconfig" v-model="newsletter.contents" class="mb-0" :onManualControllerReady="initializefroala"></froala>



									</div>
 
								</div>

								<div  id="newslettersidebarwrapper" :class ="{'mt-2 col-12': devisetype !='desktop', 'col-4': devisetype =='desktop'}" >

									


									<h3 v-if="pagemode == 'ready'">Board objectives</h3> 
									<div class="newslettersidebarwrapperscroll" v-if="cockpit.sections">
										<div id="newslettersidebar" class="" v-bind:class="{ 'hide': cockpit.myrole != 'owner' && cockpit.myrole != 'contributor'}" style="margin-bottom: 10px;" >										

											<template v-for="(section, index ) in cockpit.sections" > 


												<h4 v-if="section.objectivesdata" :class ="{'pb-1 mb-0': !index, 'my-1':index}" style="width: 100%;" :key="section._id"><span style="background-color: #ffffff; padding-right: 20px;" v-text="section.label"></span><p class="border-top" style="margin-top: -9px;">&nbsp;</p></h4>

													<template v-for="objective in section.objectivesdata">

													<objectivesCard v-if="objective.noaccess == false" :activeCockpit="cockpit.isactive" ref="sideobjectivedata1"  :key="objective._id" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" :usemenu="false" :useswipe="false" :fulldesc="false" :scope="'newsletter'" :feedback="'readonly'" :perpage="8" ></objectivesCard>

													</template>
											</template>

											<h4 v-if="cockpit.sections.length" style="width: 100%;" class = "my-1 "><span style="background-color: #ffffff; padding-right: 20px;"> Not grouped</span><p class="border-top" style="margin-top: -9px;">&nbsp;</p></h4>

											<template v-for="objective in cockpit.measuresnotinsections">
											
											<objectivesCard v-if="objective.noaccess == false" :activeCockpit="cockpit.isactive" ref="sideobjectivedata2"  :key="objective._id" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" :usemenu="false" :useswipe="false" :fulldesc="false" :scope="'newsletter'" :feedback="'readonly'" :perpage="8" ></objectivesCard>

											</template>

											

										</div>
									</div>
								</div>
							</div>

						</div>

						<!-- end editor -->
					</div>

					<div class="tab-pane p-t-20" v-bind:class="{'active':page=='preview'}" id="preview" role="tabpanel">

						<div v-bind:class="{'hide':page!='stillloading'}">
							<i class="fa fa-circle-o-notch fa-spin"></i>
						</div>
						<div v-bind:class="{'hide':page=='stillloading'}">
							<!-- preview -->
						<div class="buttons text-center mb-2">

					      <b-form-radio-group
					        id="btn-radios"
					        v-model="preview"
					        :options="radionoptions"
					        buttons
					       	button-variant="outline-primary"
					        name="radio-btn-outline"
					      ></b-form-radio-group>
					</div>
							<div class="card mb-0 mx-auto" style="max-width: 768px">

								<div class="card-body">

									<div class="tab-content br-n">
										<div id="viewindesktop" v-if=" page == 'preview' && preview=='desktop'">
											<div id="monitorframe">
												<div class = "py-3 N100-b"  style="margin-left:-20px; margin-right:-20px; margin-top:-20px; padding-left: 20px; padding-right: 20px;">
												<h3 class ="mb-0">{{newsletter.name}} ({{newsletter.periodstart | timeFormat}} - {{newsletter.periodend | timeFormat}})</h3>
												<h4 class=" font-weight-bold " style="display: inline;">{{cockpit.name}}  </h4>
													
												</div>
												<div class="sensor"></div>
												<div class="img-responsive pt-3" style= "padding:0 40px;" v-html="newsletterviewerdata">
												</div>
												<div class="alert alert-info fade show text-center">Like, dislike and comments section will be added here.</div>
											</div>
										</div>
										<div id="viewinmobilecockpit" v-if="page == 'preview' && preview=='mobile'">
											<div id="mobileframe">
												<div class="sensor"></div>
												<div class="speaker"></div>
												<div class="img-responsive " style="padding: 1.25rem;">
													<div class = " py-3 N100-b"  style="margin-left:-20px; margin-right:-20px; margin-top:-20px; padding-left: 20px; padding-right: 20px;">
												<h3 class ="mb-0">{{newsletter.name}} ({{newsletter.periodstart | timeFormat}} - {{newsletter.periodend | timeFormat}})</h3>
												<h4 class=" font-weight-bold " style="display: inline;">{{cockpit.name}}  </h4>
													
												</div>
													<div class = "pt-2" v-html="newsletterviewerdata"></div>
													<div class="alert alert-info fade show text-center">Like, dislike and comments section will be added here.</div>
												</div>

												<div class="homebutton"></div>
											</div>
										</div>
									</div>                               

								</div>
							</div>


							<!-- -->
						</div>

					</div>

					<div class="tab-pane p-t-20" v-bind:class="{'active':page=='publish'}" id="publish" role="tabpanel">

						<div v-bind:class="{'hide':page!='stillloading'}">
							<i class="fa fa-circle-o-notch fa-spin"></i>
						</div>
						<div class="card noshadow" id="newslettersteps" v-bind:class="{'hide': ( page=='stillloading' || publishconfirmation == true )  }">
							<div class="card-body text-center">
								<p>You are about to publish the newsletter:</p>


								<h3 style = "text-align: center !important">{{ newsletter.name }} ({{ newsletter.periodstart | timeFormat }} - {{ newsletter.periodend | timeFormat }}) </h3>

								<p class="" style ="margin: 0 230px">When you publish this newsletter it will be made available online and all user with access to this board will be able to see it. A notification will also be sent by email to all users with access to the board. It is not possible to edit the contents of a newsletter once it has been published.</p>

								<p class = "mt-3"> 
									<button v-on:click="dopublish( newsletter._id ); " class="btn btn-primary float-center btn-lg">Publish newsletter</button>
								</p>
							</div>
						</div>

						<div id="newsletterpublishedconfirmation" class="card-body" style="width: 70%; margin: auto;" v-bind:class="{'hide': ( page=='stillloading' || publishconfirmation == false )  }">
							<div class="card noshadow">
								<div class="card-body text-center" >
									<h2 style = "text-align: center !important" class="mt-3">Success!</h2>

									<p>Your newsletter was successfully published, and the board members have been notified. </p>
									<a @click.prevent="opennewsletterforviewing(newsletter._id); "><h3 style = "text-align: center !important" class="mt-3">{{ newsletter.name }} ({{ newsletter.periodstart | timeFormat }} - {{ newsletter.periodend | timeFormat }})</h3></a>
									<p class="mt-3">You can click on the newsletter title to read the newsletter and check any feedback that's coming in.</p>
                                      <!-- //#newsletter_5cae71665d927f2e84dea33e
                                      	<p class="mt-3 mb-3"><button type="button" class="btn btn-primary" onclick="gotonewspublishedletter()">Take me to the newsletter</button></p> -->
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>

              </div>

          </div>

            
          </b-modal>
          <div v-if ="errorloading" >
			<b-popover
				target="errormessage"
				custom-class= "danger"
				placement="right"
				ref="popover"
				id = "errormessagepopover" 
				@show="onpopovershow"
				title=" Problem saving"
				:show.sync="errorloadingpopover">

			<p> We are encountering a problem savings your changes. This may be due to a connection error or a fault on the server. Save your content offline and try to refresh the page.</p>
				
			</b-popover>
		</div>
      </div>
  </template>

  <style lang = "scss" scoped>
 
	
  .noborder{
  	border: 0 !important;
  }
  .noshadow{
  	box-shadow: none !important;
  }
  .cardwrapper {
    height: auto;
    margin-bottom:8px;
}

#newslettersidebar{
	border:none;
}

#newslettereditorcard{
	// height:100%;
	.fr-wrapper{
		// max-height:100%!important;
		height: 100%;
	}
}
#newslettermodalid{
	.modal-body{
		overflow-y: hidden;
	}
}
 #newslettermodalid{
  	#btn-radios{
  		label{
  			width: 150px
  		}

  		.active{
  			i{font-weight: 700}
  		}
  	}
}
</style>
<script>
	import Cockpitsapi from '@/services/api/cockpits'
	import Newslettersapi from '@/services/api/newsletters'

	import objectivesCard from '@/views/cockpit/components/objectivesCard'
	import moment from 'moment';
	import Boardcharts from '@/components/boardcharts/boardcharts.vue'

	import {bus} from '@/main'


	export default{
		name:'newsletterModal',
		data(){
			let self = this
			return{
			mode:"editor",
			cockpit:{},
			draftnewsletters : [],
			publishednewsletters : [],
			isDraftingNewsletter:false,
			template:'team1',
			periodStart:null,
			periodend:null,
			newslettertitle:'',
			bodytext:'',
			isshown: false,
			froalaconfig: {},
			draftstable : null,
			publishedtable : null,
			items: [],          
			errors: [],
			invalidfrom : "empty",
			invalidto : "empty",
			invalidtitle : "empty",
			activelockedto : {},
			creatingnewsletter : false,
			newsletter : {},    
			datefrom : new Date(),
			dateto : new Date(),
			datefromtext : "",
			datetotext : "",
			currentbodytext : "",
			newsletterviewerdata : "",
			commited : true,
			editor : null,     
			status : "idle",
			viewportheight: 0,
			viewportwidth: 0,
			editing : false,
			haschanges : false,
			maxstep : 1,
			page : 'overview',
			preview : 'desktop',
			commentposting : '',
			activecomment : {},
			publishconfirmation : false,
			editorcontent : "",
			devisetype: '',
			pagemode:'notready',
			activenewsletter:{},
			showdeleteactivenewsletter:false,
			calendarConfigstart: {maxDate:'', minDate:null, altFormat: 'j M, Y', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
			calendarConfigend: {maxDate:'', minDate:null, altFormat: 'j M, Y', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
			drafttingnewslettertabs:false,
			activeCockpit : {},
			indicator : null,
			comments :[],
			savingnewsletter: false,
			newsletterislocked : false,
			newsletterid : "",
			size:"",
			profile:{},
			errorloading:false,
			errorloadingpopover:false,
			radionoptions: [
	          { html: '<i class="fal fa-desktop"></i> Desktop', value: 'desktop' },
	          { html: ' <i class="fal fa-mobile-android-alt"></i> Mobile', value: 'mobile' },
	        ]
     
		}
	},	
	props:{
		cockpitid:{
			type:String,
			default:()=>""
		},
		token:{
			type:String,
			default:()=>""
		},
		shown:{
			type:Boolean,
			default:()=>false
		},
		viewer:{
			type:Boolean,
			default:()=>false
		}		
	},
	components:{objectivesCard,Boardcharts},
	computed:{
		orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

		loggedinuser(){
			return this.$store.getters.user
		}
	},
	mounted(){

		var self = this


		this.profile = this.$store.state.user.userInfo

		window.onbeforeunload = function (event) {
			Newslettersapi.Unlock( self.newsletterid, self.token)
		}

		this.getWindowWidth()
		this.$nextTick(function() {
			window.addEventListener("resize", this.getWindowWidth);
              //window.addEventListener(‘resize’, this.getWindowHeight);
          })

		
		this.froalaconfig = this.$store.state.config		
		
		$.getJSON( "https://s3token.flowe.io/", function(data){
			self.froalaconfig.imageUploadToS3 = data	      
		})

			//alert( flatpickr.formatDate(new Date(), "m-d-Y") )
			//bus.$on('opennewslettermodal', this.opennewslettermodal )
			bus.$on('opennewsletterforeditingmodal', this.opennewsletterforediting)
			bus.$on('onopennewsletterforcreating', this.opennewsletterforcreating)

			
					$('html').off('click',function() {
							self.errorloadingpopover = false
						});							
						$('html').on('click',function() {
							self.errorloadingpopover = false
						});


	
		},

		watch:{

			shown : function( e ){
				this.isshown = e
			},

			periodStart : function(e){
				this.calendarConfigend.minDate = e
			},

			periodend : function(e){
				this.calendarConfigstart.maxDate = e
			},

			mode : function(e){
				console.log(e)
			}
		},

		filters: {

			
		},

		methods:{

			dumppng( imageuri ){

				this.pngdump = imageuri

				//alert( this.pngdump  )

				//var apex = window.Apex._chartInstances

				//this.editor.html.set('<p>My custom paragraph.</p>');
				//var existingtext = this.linkInitControls.getEditor().html.get()
				//this.linkInitControls.getEditor().html.set( '<img src="' + imageuri + '"> ' + existingtext )

				//console.log( imageuri, "zzzzzzzz")
			},
			openpopoverinit(){
				setTimeout(function(){self.errorloadingpopover = true},100)
			},
			onpopovershow(){
		  		let self = this

		  		this.$nextTick(function(){

		  			$('#errormessagepopover').on('click', function(event){
		  			//	console.log('test')
		  				event.stopPropagation();
		  			})
		  		})
		  	},  

			replycomment(){},
			updatepages(){},
			commentposted(){},
			getWindowWidth(){
		      //console.log('hello')
		      var hh = $('.modal-header')
		      console.log()
		      if( window.innerWidth < 786 ){
		      	this.devisetype= 'mobil'
		      }else if(window.innerWidth < 1024){
		      	this.devisetype = 'tablet'
		      }else{
		      	this.devisetype = 'desktop'
		      }

		  },

		  getcomments( newsletterid ){

 
	         var self = this
	 
		Cockpitsapi.Comments( { context:this.orgkey, type:"newsletter", iid:newsletterid, "token": this.token } )
			.then( function(response){
				self.comments = response.data.comments
			})
			.catch(error => console.log(error))
			.finally( () => {

			//do everything
			//self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }

			})

		  },

		  inittabs(){

		  	let self = this


		  	$('a.newslettersteps[data-toggle="tab"]').on('shown.bs.tab', function (e) {

		  		var dest = e.target.href.split("#")


		  		if( dest[1] == 'create' ){

		  			newsletterlist.publishconfirmation = false

		  			$(".tooltip").tooltip("hide");
		  			$("#newslettercreateform")[0].reset()

                    //newsletterlist.newsletter = {}
                    //self.page = 'stillloading'


                    self.page = 'overview'
                    self.maxstep = 1

	                ///// reload tables
					////// save newsletter first                   

				}



				if( dest[1] == 'preview' ){

					newsletterlist.publishconfirmation = false

					$(".tooltip").tooltip("hide");
                    //newsletterlist.page = 'stillloading'

                    ///save newsletter
                    /// format headings
                    /// build body text

                    self.page = 'preview'
                    self.maxstep = 4

                }

                if( dest[1] == 'publish' ){

                	$(".tooltip").tooltip("hide");
                    //newsletterlist.page = 'stillloading'


                    

                    self.page = 'publish'
                    self.maxstep = 4

                    /// save newsletter
                    /// format headings
                    /// build body text



                }



            })


		  },

		  reloadData(){},
		  showobjectivedescriptionmodal(){},
		  loadmemberlist(){},
		  makeactive(){},
		  addstatus(){},
		  objectivesettings(){},
		  openmeasureinmodal(){},

		  

		  dopublish( newsletterid ){

		  	let self = this

		  	Newslettersapi.Publish( newsletterid, this.token)
		  	.then( function(response){

		        		//self.page = 'overview'
	                  	//self.maxstep = 1
	                  	//self.loadnewsletters()
	                  	self.publishconfirmation = true

	                  }).finally( () =>{
	                  	bus.$emit('changenewsletters',true)
	                  	self.mode = 'ispublished'
	                  })

	              },

	              dopublishconfirmation(){},

	              deleteactivenewsletter( newsletter ){
	              	this.showdeleteactivenewsletter =false
	              	let self = this

	              	Newslettersapi.Delete( newsletter._id, this.token)
	              	.then( function(response){

	              		self.page = 'overview'
	              		self.maxstep = 1
	              	}).finally( () =>{
	              		bus.$emit('changenewsletters')
	              		this.isshown = false
	              	})
	              },


	              opennewsletterforediting( newsletterid ){

	              	this.newsletterid = newsletterid
	              	//console.log(newsletterid, this.loggedinuser)
					let self = this

				Newslettersapi.Openforedit( newsletterid, this.token)
				.then( function(response){


					if( response.data.ok && response.data.newsletter ){    
						if( response.data.newsletter.lock.islocked  && (  response.data.newsletter.lock.userid != self.loggedinuser._id ) ){

							self.activelockedto = response.data.newsletter.lock

						}else{

							self.activelockedto = {}

							self.page = "editor"	
			              	self.drafttingnewslettertabs = true
			              	self.mode = "editor"
						  	self.maxstep = 4


							self.newsletter = response.data.newsletter
							document.title ='Edit - '+  self.newsletter.name+' | Flowe'
						}

						
		          	//self.editorcontent = 
		          	
		          	//self.reset()
		           	//self.loadnewsletters()
		           }else{

		           	self.$router.push("/400")

		           }

		       }).finally(()=>{
		          
		        })
			},

			opennewsletterforcreating(){
				this.pagemode = 'ready'
				this.mode = "creating"
			},	

			initializefroala: function(initControls) {
				//alert('')
				// this.froalaconfig.heightMax = 600;

				//let self = this

				
				let self = this 
				setTimeout(function(){


					self.linkInitControls = initControls;
					self.linkInitControls.initialize()

					self.newsletterviewerdata = self.linkInitControls.getEditor().html.get()
				}, 100)
				
			},
			

			opennewsletterforviewing(nid){

				this.newsletterid = nid

				this.isshown = false
				bus.$emit('onopennewsletterforviewing',nid,this.cockpitid)

			},
			modalshown(){

				this.froalaconfig.events.contentChanged = function () {self.updatedraft(self) }
				this.periodStart = null
				this.periodend = null


				let self = this


				this.cockpit = {}
				
				Cockpitsapi.Cockpits( { "_id":this.cockpitid, "token": this.token } )
				.then( function(response){

					//console.log( response.data, "cccc")

					if( response.data.ok || !response.data.ok ){
						
						self.cockpit = response.data.cockpits
						
						if( self.viewer == true ){

						}else if(self.mode == "editor"){

							setTimeout( function(){

								self.inittabs()

							}, 200)
	

						}						

					}
				})
				.catch(error => console.log(error))

				.finally( () => {
					self.pagemode = 'ready'

				})
			},

			updatedraft(){



				let self = this
				this.savingnewsletter = true
				var bodytext = this.linkInitControls.getEditor().html.get()	

				this.newsletterviewerdata = this.linkInitControls.getEditor().html.get()	

				//console.log('bodytext',bodytext, self.newsletter)
				this.errorloading = false 
				Newslettersapi.Save( { "t":this.token, "newsletterid":this.newsletter._id,"bodytext":bodytext} )
				.then( function(response){
				}).catch(error => {console.log(error);self.errorloading = true})

					.finally( () => {
						this.savingnewsletter = false
					})
			},

			createdraft(){

				let self = this

				var from = new Date(this.periodStart)
				var to = new Date(this.periodend)

				from = new Date(from.setHours(0))
				to = new Date( to.setHours(23) )
				to = new Date( to.setMinutes(59) )

				var bodytext = this.loadtemplate( this.cockpit, this.template, this.newslettertitle, from, to  )  

				bodytext = bodytext.replace( "|insertthegraphhere|", '<img src="' + this.pngdump +'" alt="Graph">' )

				from = moment(from).format('X') 
				to = moment(to).format('X') 

				Newslettersapi.Save( { "t":this.token, "title":this.newslettertitle, "newsletterid":"", "cockpitid":this.cockpitid, "template":this.template, "datefrom":from, "dateto":to, "bodytext":bodytext  } )
				.then( function(response){

					if( response.data.ok ){    
						//self.reset()
						//self.drafttingnewslettertabs = true

						
						/*self.$nextTick(function(){							
						})	*/


						self.isshown = false

						setTimeout( function(){

							var tourl = "/" + self.orgkey + "/boards/" + self.cockpitid + "/newsletters?nid=" + response.data.newsletterid + "&page=newsletter"
							//self.$router.push( tourl )

							location.href=tourl
							//self.isshown = true
						//	self.opennewsletterforediting( response.data.newsletterid )
						}, 100)


						//console.log()


						//
						//self.$func.addQuery({nid:response.data.newsletterid,page:'newsletter'},self.$route, self.$router)
						


						//self.loadnewsletters()
						// self.$emit('onlistupdate')
					}

				}).finally(()=>{
					self.$emit('onlistupdate')
				})

				

				
			},


			reset(){

				this.isDraftingNewsletter=false
				this.newslettertitle=""
				this.periodStart=""
				this.periodend=""
				this.bodytext=""


			},

			startDrafting(){
				this.isDraftingNewsletter=true;
				$('#pills-Create-tab').removeClass('active')
				$('#pills-Editer-tab').addClass('active');
			},

			modalhidden(){

				let self = this

				Newslettersapi.Unlock( this.newsletterid, this.token)
			  	.then( function(response){

			  		self.froalaconfig.events.contentChanged = function () {}

					self.reset()
					self.newsletter = {}
					self.publishconfirmation = false
					self.mode = "editor"
					self.drafttingnewslettertabs = false
	 				self.pagemode = 'notready'
					self.page = 'overview'
					self.$emit('onmodalhidden')
					self.linkInitControls.destroy();
					self.$func.removeQuery(['page','nid'],self.$route,self.$router)
					self.isshown = false

			  	})

				
			},

			togglecreatenewsletter(){
				
				$('.toggle_createnewsletter').parent('a').toggleClass('hide')
				$('#newslettercreateform').parent('div').parent('div').toggleClass('hide')
			},

			timeFormat : function( unixtime ){

				var date = new Date( unixtime * 1000 )

				var str = ""

				if( date != "" ){
					str = moment( date ).format('YYYY-MM-DD')
				}

				return str 
			},
			dateFormat : function( obj ){

				var str = ""

				if( obj != "" ){
					str = moment( obj ).format('YYYY-MM-DD')             
				}
				//str = moment( obj ).fromNow()


				return str 
			},

			loadtemplate : function( cockpit, template, title, datefrom, dateto ){


				// always point at the public img not the static builds due to the filename changes when rebuilt

				let imgurlgreen = '/static/img/green.png'
				let imgurlred = '/static/img/red.png'
				let imgurlblue = '/static/img/blue.png'
				let imgurlyellow = '/static/img/yellow.png'
					
				var green = '<img src="'+imgurlgreen+'">'
		        var red = '<img src="'+imgurlred+'">'
		        var blue = '<img src="'+imgurlblue+'">'
		        var yellow = '<img src="'+imgurlyellow+'">'


				function compare(a,b) {

					var adate = new Date(a.indicatordate)
					var bdate = new Date(b.indicatordate)

					if ( adate < bdate)
						return -1;
					if ( adate > bdate)
						return 1;
					return 0;
				}

				var html = "<div class=\"fr-view newslettercontent_" +template+ "\">"

				if( template == "team1" ){

		         // html += "<h2 class=\"fr-deletable headings\" contenteditable=\"false\">" + cockpitview.cockpit.name + "</h2>"
		         // html += "<h5 class=\"fr-deletable headings\" contenteditable=\"false\">" + title + " (" + moment(datefrom).format('YYYY-MM-DD') + " - " +  moment(dateto).format('YYYY-MM-DD') + ")</h5>"
		         
		        //  html += "<hr class=\"headings\">"


		        html += `<p><span style="color: rgb(41, 105, 176);">Editing the newsletter (Delete all blue text before publishing)</span></p>`
		        html += `<ol><li style="color: rgb(41, 105, 176);">All data, images, and text are editable, so you can customize the newsletter to fit your style.</li><li style="color: rgb(41, 105, 176);">All changes are saved as you type. Clicking 'X' to close saves.</li><li style="color: rgb(41, 105, 176);">Add qualitative text to explain and reflect on the board data for the period.</li><li style="color: rgb(41, 105, 176);">Use the preview tab above to review and go to the publish tab to make the newsletter available to all board members.</li><li style="color: rgb(41, 105, 176);">When the newsletter is published, all board members receive an email notification and can read the newsletter. A published newsletter can not be edited or deleted.</li></ol>`

		        html += "<p>Dear all,</p>"
		        html += "<p>Add your welcome paragraph here...</p>"
		        /*

		        */

		        html += "<p>|insertthegraphhere|</p>"


		         $.each(cockpit.sections, function(key,s){

		         		if( s.objectivesdata && s.objectivesdata.length >0 ){
		         			html += "<div class=\"sectiongroup\"><h4>" + s.label + "</h4> <hr>" 
		         		}
		         		
		            $.each( s.objectivesdata, function( key,m ){

		            	if( m.isactive &&  m.myrole  ){

		            		html += "<div class=\"objectivegroup\"><h4>" + m.name + "</h4>" 

		            		var lateststatus = ""
		            		if( m.indicators.length > 0 ){

		              /*lateststatus = m.indicators[0]
		              html += lateststatus.message*/

		              var mindicators = m.indicators.sort(compare);

		              $.each( mindicators, function( key,i ){

		              	var indicatordate = new Date(i.indicatordate)

		              	if( datefrom <= indicatordate && dateto >= indicatordate ){
		                  //moment(lateststatus.indicatordate).fromNow()

		                  if(i.color == 'green'){
		                  	html += green
		                  } else if(i.color == 'red'){
		                  	html += red
		                  } else if (i.color == 'blue'){
		                  	html += blue
		                  } else {
		                  	html += yellow
		                  }
		                  

		                  if( i.userdetail ){
		                  	html += " " + i.userdetail.firstname + ' ' + i.userdetail.lastname
		                  }else{
		                  	html += " Author"
		                  }
		                  

		                  html += " - " + moment(i.indicatordate).format('YYYY-MM-DD') + "</p>"

		                  html += "<div class ='clearfix' style=\"margin-left: 20px;\">"

		                  html += i.message

		                  html += "</div>"
		              }

		          })

		              
		              
		          }else{
		          	html += "<p style=\"margin-left: 20px;\">No indicators available</p>"
		          }

		          html += "</div>"

		      }

		  })
		            html += "</div>"

		   })
		        if( cockpit.measuresnotinsections && cockpit.measuresnotinsections.length > 0){
		      		html += "<div class=\"sectiongroup\"><h3> Not grouped </h3> <hr>" 
		      	}
		            $.each( cockpit.measuresnotinsections, function( key,m ){

		            	if( m.isactive &&  m.myrole  ){

		            		html += "<div class=\"objectivegroup\"><h4>" + m.name + "</h4>" 

		            		var lateststatus = ""
		            		if( m.indicators.length > 0 ){

		              /*lateststatus = m.indicators[0]
		              html += lateststatus.message*/

		              var mindicators = m.indicators.sort(compare);

		              $.each( mindicators, function( key,i ){

		              	var indicatordate = new Date(i.indicatordate)

		              	if( datefrom <= indicatordate && dateto >= indicatordate ){
		                  //moment(lateststatus.indicatordate).fromNow()
		              // html += i.color == 'green' ? green : red

		               		if(i.color == 'green'){
		                  	html += green
		                  } else if(i.color == 'red'){
		                  	html += red
		                  } else if (i.color == 'blue'){
		                  	html += blue
		                  } else {
		                  	html += yellow
		                  }

		                  if( i.userdetail ){
		                  	html += " " + i.userdetail.firstname + ' ' + i.userdetail.lastname
		                  }else{
		                  	html += " Author"
		                  }
		                  

		                  html += " - " + moment(i.indicatordate).format('YYYY-MM-DD') + "</p>"

		                  html += "<div style=\"margin-left: 20px;\">"

		                  html += i.message

		                  html += "</div>"
		              }

		          })

		              
		              
		          }else{
		          	html += "<p style=\"margin-left: 20px;\">No indicators available</p>"
		          }

		          html += "</div>"

		      }

		  })
		            html += "</div>"

		            html += "<br><p>In conclusion, I would like to ...</p>"



		        }

		        if( template == "blank" ){


					html += `<p><span style="color: rgb(41, 105, 176);">Editing the newsletter (Delete all blue text before publishing)</span></p>`
		        	html += `<ol><li style="color: rgb(41, 105, 176);">All data, images, and text are editable, so you can customize the newsletter to fit your style.</li><li style="color: rgb(41, 105, 176);">All changes are saved as you type. Clicking 'X' to close saves.</li><li style="color: rgb(41, 105, 176);">Add qualitative text to explain and reflect on the board data for the period.</li><li style="color: rgb(41, 105, 176);">Use the preview tab above to review and go to the publish tab to make the newsletter available to all board members.</li><li style="color: rgb(41, 105, 176);">When the newsletter is published, all board members receive an email notification and can read the newsletter. A published newsletter can not be edited or deleted.</li></ol>`

		        	html += "<p>Dear all,</p>"
		        	html += "<p>Add your welcome paragraph here...</p>"
		        	html += "<br><p>In conclusion, I would like to ...</p>"

		        }

		        html += "</div>"

		        return html

		    },


		}
	}

</script>