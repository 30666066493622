<template>
	<div>

    <h4 style="width: 100%;" class ="mt-3 sectionheader"> <span style="background-color: #ffffff; padding-right: 20px;">Risk profile development</span> <p class="border-top" style="margin-top: -9px;">&nbsp;</p></h4>
	
  <template >
  <div id="riskchart">

    <p class="text-warning p-3">No data available. Risk profile graph is based on data from published risk objective status.</p>

  </div>
  <div class="text-warning ml-3" v-if="chartwarning">

    <p class="learn-licensing"><a class="link-warning asalink" href="#" @click="explaingraph=explaingraph?false:true">Graph status is incomplete <i class="icon fal fa-exclamation-triangle"></i></a></p>

    <span v-if="explaingraph">You do not have access to all the objectives on this board, so the graph may not accurately reflect the overall status of the board. To get a complete picture, you will need to request access to any objectives you do not currently have access to.</span>
  </div>
  <a href="#" @click="dump()" class="hide">Dump</a>
  </template>
	</div>


</template>

<script>

	import Cockpitsapi from '@/services/api/cockpits'
	//import VueApexCharts from 'vue-apexcharts'
  //import ApexCharts from 'vue-apexcharts'
  import ApexCharts from 'apexcharts'
  
export default {
	name: 'riskcharts',
	components: {},
	data () {
	return {
    indicatorinweeks : [],
    explaingraph : false,
    runningchart : null,
    seriesdata:[],
    options2 : {
          series: [],
          chart: {
          height: 350,
          type: 'line',
        },
        plotOptions: {
          bar: {
            columnWidth: '30%'
          }
        },
        stroke: {
          width: [0, 5,5,5],
          curve: 'smooth',
          colors:['#2E477B', '#157815', '#976e1e', '#b83838']
        },
        title: {
          text: ''
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        labels: [],
        xaxis: {
          type: 'datetime'
        },
        yaxis: []
        },
	  chartwarning:false
	}
	},
	props:{
		size:{
			type:Number,
			default:()=>250
		},
    xaxissize:{
      type:String,
      default:()=>"18px"
    },    
	},
  methods: {

    dump(){

       

       let self = this

      this.runningchart.dataURI().then(({ imgURI }) => {
        self.$emit('ondumppng', imgURI)
        //console.log( imgURI , "uri")
      })

      //


    }
  },
	mounted(){
		
		let self = this

    //this.runningchart = new ApexCharts(document.querySelector("#riskchart"), self.options2);
    //this.runningchart.render();

		Cockpitsapi.Riskchartdata( { "_id": this.cockpitid, "token": this.token, "context": this.orgkey } ).then( function(response){

          if( response.data.indicatorperweek ){

            

            let average = []
            let best = []
            let worst = []
            let numrisks = []
            let dates = []

            let maxnumrisk = 0
            let maxcxp = 0.0

            for( let i in response.data.indicatorperweek ){

              let riskdata = response.data.indicatorperweek[i]

              if( riskdata.risk ){

                if( parseInt(riskdata.risk.numrisks) > maxnumrisk ){
                  maxnumrisk = parseInt(riskdata.risk.numrisks)
                } 

                if( riskdata.risk.worst > maxcxp ){
                  maxcxp = riskdata.risk.worst
                }

                average.push( parseInt(riskdata.risk.average) ) 
                best.push( parseInt(riskdata.risk.best) ) 
                worst.push( parseInt(riskdata.risk.worst) ) 
                numrisks.push( parseInt(riskdata.risk.numrisks) ) 
                dates.push( riskdata.risk.date )
              
              }


            }

            let series = []

            //colors:['#2E477B', '#157815', '#976e1e', '#b83838']
            
            series.push( { name:"No. of risks", type:"column", data:numrisks, color:"#B5C3E3" } )
            
            series.push( { name:"Worst", type:"line", data:worst, color:"#b83838" } )
            series.push( { name:"Average", type:"line", data:average, color:"#976e1e" } )
            series.push( { name:"Best", type:"line", data:best, color:"#157815" } )

            self.options2.series = series
            self.options2.labels = dates


            self.options2.yaxis.push( {
                                    showAlways: true,
                                    title: {
                                      text: 'No. of risks',
                                    },
                                    opposite: true,
                                    forceNiceScale: true,
                                    min: 0,
                                    max: maxnumrisk
                                  
                                  } )
            self.options2.yaxis.push( {
                                    showAlways: true,
                                    seriesName : 'Worst',
                                    
                                    title: {
                                      text: 'Consequence x Probability'
                                    },
                                    forceNiceScale: true,
                                    min: 0,
                                    max: maxcxp
                                  } ) 

             self.options2.yaxis.push( {
                                    seriesName : 'Worst', show: false
                                  }) 

             self.options2.yaxis.push( {
                                    seriesName : 'Worst', show: false
                                  })


            self.runningchart = new ApexCharts( document.querySelector("#riskchart"), self.options2);
            self.runningchart.render();
            self.runningchart.toggleSeries('Average')
            self.runningchart.toggleSeries('Best')

           //self.runningchart.updateSeries( { series:series } )
/*
{
          name: '',
          type: 'column',
          data: [4, 4, 4, 5, 8, 12, 8, 12, 6, 5, 11, 21]
        }, {
          name: '',
          type: 'line',
          data: [ 12.2, 12.0, 16.0, 1.4, 3.1, 5.2, 2., 1.7,4, 3.5, 2.7, 4.3, 2.2,]
        }, {
          name: '',
          type: 'line',
          data: [1.4, 2.4, 3.5, 2.7, 4.3, 2.2, 1.7, 3.1, 5.2, 12.2, 12.0, 16.0]
        }
*/

            setTimeout( function(){
              self.dump()
            }, 500)
            


          }

      })
	},
	watch:{

      series2data : function( e ){

     //   console.log( e, ev, "z")

        var datapoints = []
        var dataxy = []
        var datasingle = []

        if( e.length > 0 ){

          //var steps = 1
         // var cntr = 3

          //if( e.length > 12 ){

          //  steps = 4
          //}

          for( var i in e ){

            var datapoint = []

            datapoint.push( Date.parse(e[i].week), e[i].indexvalue )
            datapoints.push(datapoint)
            datasingle.push( e[i].indexvalue )

           // console.log( Date.parse(e[i].week), e[i].indexvalue , "dataxy" )

            dataxy.push( { x:Date.parse(e[i].week), y:e[i].indexvalue } )

            var weekdate = new Date(e[i].week)

           // const yy = weekdate.getFullYear() // 2019
            const dd = weekdate.getDate() // 23
            const mm = weekdate.getMonth()+1

           // cntr++

            //if( cntr >= steps ){

              this.xaxiscategories.push(mm+"/"+dd )
              //cntr = 0

            //}else{

            //  this.xaxiscategories.push("")
           // }

           //console.log( steps, cntr )


            

            
          }


        }

        this.series2 = []
        this.series2.push( { name:"data", data: datapoints } )

        this.series = []
        this.series.push( { name:"data", data: dataxy } )

      }

    },
	computed:{


	warningtext(){
        return this.chartwarning ? "User does not have access to all the objectives on the board, therefore the graph may look different from other users" : ""
      },

      cockpitid(){
        return this.$route.params.cid
      },

      
      orgkey(){
          return this.$route.params.orgname ? this.$route.params.orgname : null
        },  
      token(){
        return this.$store.getters.token
      },

	xaxisoptions(){
        return { 'type':'datetime' }
        //return { categories: this.xaxiscategories }
      },


	chartOptions(){

      return {
            
            chart: {
              height: this.size,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar:{ show:false }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
              show:false,
              row: {
                //colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                //opacity: 1
              },
            },
            xaxis: {
              type: 'datetime',
            },
            yaxis: {
              show:false,
              title: {
                text: ''
              },
              min: 0.8,
              max: 1.2
            },
            annotations: {
              yaxis: [
              {
                  y:0.7,
                  y2:0.8,
                  label: {
                    text: ""
                  }
                },
                {
                  y: 0.8,
                  y2: 0.9,
                  borderColor: '#FF0000',
                  fillColor: '#FF0000',
                  label: {
                    text: ''
                  }
                },
                {
                  y: 0.9,
                  y2: 1.9,
                  borderColor: '#FFFF00',
                  fillColor: '#FFFF00',
                  label: {
                    text: ''
                  }
                },
                {
                  y: 1.0,
                  y2: 1.2,
                  borderColor: '#008000',
                  fillColor: '#008000',
                  label: {
                    text: ''
                  }
                }
              ]
            }
          }
        }
	}
}
</script>
