<template>
	<div>

		<b-modal @hide="exportwindowclosed" v-model="exportloading2" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc>
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Export report</h3>
	          <a class = "close-modal dialog-close-bustaketton mr-2">
	            <i class="fal fa-times fa-lg " @click="exportloading2 = false"></i>
	          </a>
	        </template>
	        <div>
	        	<p>Export Master plan to standard format. Use the drop down to select a template and click OK to start the export.</p>


				<div class="dropdown">
				  <button class="btn btn-block text-left btn-secondary-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  	<div style="position:relative;">
				    <span>{{ selectedforexport.label }}</span> 
				    <span style="position:absolute; right: 10px">
				    	<i class="fas fa-caret-down"></i>
				    </span>
					</div>
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <div style="color:#000;" class="dropdown-item text-black" :key="key" v-for="( template, key) in exporttemplates" @click="setforexport(template)">{{ template.label }}</div>
				  </div>
				</div>
				
	        </div>

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe :disabled2="doexportready==false"  text1= "Export" @no ="cancel" @yes = "doexport(selectedforexport.key)"></button-gruppe>
	        </template>
        </b-modal>

		<b-modal v-model="confirmplan" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Confirm delete track</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="confirmplan = false"></i>
	          </a>
	        </template>
	        <span class = "text-danger"> Warning:</span> You are about to delete this track. Do you want to proceed?
	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Delete track" @no ="cancel" @yes = "confirmdeletetrack(activetrack)"></button-gruppe>
	        </template>
        </b-modal>

        <b-modal @shown="milestonemodalshown" @hide="canceleditmilestone" v-model="milestonemodal" id ="milestonemodal" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">{{ addmilestoneeditmode ?  'Add a' : 'Update' }}  milestone</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="milestonemodal = false"></i>
	          </a>
	        </template>
	        
	        <template>

				         		<div class="clearfix mt-3 pb-2 pl-2 pr-2 editmilestoneform">

				         			<div class="mb-2">

				         				<b-input-group class="">
									    
									    <template #prepend>
										  <b-input-group-text class="plain">Name: </b-input-group-text>
										</template>

									      <input type="text" class="form-control" v-model="editmilestones" maxlength="25" ref="editmilestonesinput" style="border-color: var(--N200);">
									    </b-input-group>			         			
				         			</div>

				         			<div class="mb-2">

				         				<datepicker @statechanged="milestonedatestatechanged" @ondatepicked="dodatepickedmilestone" :datevalue="trackdates.milestones.value" :defvalue="trackdates.milestones.value" :prependstr="'Date:'"></datepicker>

				         				
									</div>

									<div class="mb-2">

	
										<div class="d-flex checkbox-form">
					         				<div class="w-100 " style="white-space: nowrap;">On critical path?</div> 
					         				<div class="flex-shrink-1 text-right"> 

					         					<b-form-checkbox :disabled="false" v-model="iscriticalpath" inline name="check-button" class="my-auto" id="iscriticalpath" size="lg" switch >
				        						</b-form-checkbox>
					         				</div>

											
										</div>

																		</div>

				         		</div>
				         		</template>


	        <template v-slot:modal-footer="{ cancel, ok }">

	        	<button v-if="addmilestoneeditmode==false" type="button" class="btn btn-danger mr-1" @click.stop.prevent="trydeletemilestone(editmilestonesid)">Delete milestone</button> 

	            <button-gruppe :disabled2="milestonedatestate==2" text1= "Save milestone" @no ="canceleditmilestone" @yes="presavemilestone"></button-gruppe>
	        </template>
        </b-modal>

        <b-modal v-model="sorttracks" @shown="enablesorttracks" @hidden="trackssorted" id ="approvalchanges2" size="sm" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Reorder tracks</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="sorttracks = false"></i>
	          </a>
	        </template>

	        <div class="mb-3">
	        	Drag the track names to change the display order on the master plan chart. 
	        </div>

	        <ul id="sortabletracks" class="list-group">
	        	
	        	<li @contextmenu.prevent.stop="" class="list-group-item p-2 sortedtracks border-top-0 border-left-0 border-right-0" :id="track._id" :key="track._id" v-for="track in tracks">
	        		<i class="fas fa-grip-lines"></i> <span class="ml-2 disable-select">{{ track.label }}</span> 
	        	</li>

	        </ul>

	        <template v-slot:modal-footer="{ cancel, ok }">
	          <button-gruppe text1= "Save" @no ="cancel" @yes="updatetracksort()"></button-gruppe>
	        </template>
        </b-modal>

        <b-modal @hide="addnewtrackformhidden" @shown="addnewtrackformshown" v-model="addnewtrack" id ="approvalchanges2" size="sm-p" no-close-on-backdrop no-close-on-esc >
	        <template v-slot:modal-header>
	          <h3 class="modal-title">Add track</h3>
	          <a class = "close-modal dialog-close-button mr-2">
	            <i class="fal fa-times fa-lg " @click="addnewtrack = false"></i>
	          </a>
	        </template>
	        <form onsubmit="return false" v-if="canwrite" class="mt-2">       	 		
						

		  			<div class="mb-3">
					    <label class="form-label">Track name</label>
					    <input class="form-control card p-2 noshadow readonly titletext mb-0" type="text" v-model="formtracklabel" ref="newtracknameinput"> 
					    <span class="text-danger hide" v-if="!allowsave">name is required</span>
					  </div>

						<div class="mb-3">
					    <label class="form-label">Description</label>
					    <textarea class="form-control card p-2 noshadow readonly titletext mb-0 autogrow"  style="overflow: hidden; overflow-y: auto; height: auto; min-height: 72px; max-height: 200px; resize: vertical; " v-model="formtrackdescription" ref="formtrackdescriptioninput"></textarea>
					  </div>

		  			<button @click="createnewtrack()" style="position: absolute; left: -100000px;" type="submit"></button>
		  			
		  		</form>
	        <template v-slot:modal-footer="{ cancel, ok }">
	        	<button class="ml-1 btn btn-secondary" @click="addnewtrack = false" type="button">Cancel</button>

	        	
	        	<button v-show="allowsave" class="ml-1 btn btn-secondary" @click="createnewtrack(false)" type="button">Save and close</button>

	        	<button v-show="allowsave" class="ml-1 btn btn-primary" @click="createnewtrack(true)" type="button">Save and add another</button>
	          	
	        	<button v-show="!allowsave" class="ml-1 btn btn-secondary" type="button" disabled="disabled">Save and close</button>
	        	<button v-show="!allowsave" class="ml-1 btn btn-primary" type="button" disabled="disabled">Save and add another</button>
	          	
	        </template>
        </b-modal>
	       	
	       	<div class="mb-3 mt-3" :class="'xwidth_'+windowspec" >
	       	<button ref="addnewtrackbutton" v-if="canwrite" class="btn btn-primary" @click="addnewtrack=true"> <i style="font-size:18px" aria-hidden="true" class="fa fa-plus-circle mr-2 "></i> Add track</button>

	       	<button tabindex="-1" v-if="canwrite" :disabled="canexport?false:true" class="btn btn-secondary ml-2" @click.stop.prevent="doinitexport()">Export</button>

	        </div>

	       <div class="mb-5"> 


         <div id="interactivepowerslider" :class="'minwidth_'+windowspec+' lwidth_'+windowspec" style="position:relative; z-index: 10;">


         	<div v-if="tracks.length == 0">The Master plan is created when the first track is added. <br> Click on "Add track"</div>

         	<div v-if="graphworking" style="z-index: 70; background-color: #ffffff; height: 100%; width: 90%; position: absolute; top: 0; left:0;"><img :src="savedpng"></div>
			<div :class="windowspec" id="masterplan" v-show="tracks.length > 0" @contextmenu="contextmenu($event)" @click="graphclicked">
			</div>
       	 </div>




       	 <div :class="'rwidth_'+windowspec +' '+singlecol+'_'+windowspec" v-if="tracks.length > 0">	
			


			<template >			

          	<div :class="{'card':!ismobile}"> 

         	<div :class="{ 'card-body':!ismobile, 'p-0':ismobile }">

         	<pre v-if="1==2"><small>{{ activetrack }}</small> </pre>


         	<div v-if="canwrite"  >




         		<div v-if="!editlabel" class="editable" @click="doeditlabel">
         			<h3 v-if="!activetrack.label">--</h3>
         			<h3 v-else-if="activetrack.label==''">--</h3>
         			<h3 v-else>{{ activetrack.label }} <i class="fa fa-pencil editableicon"></i></h3>
         			<span class="text-danger" v-if="activetrack.label==''">name is required</span>
         		</div>

         		<div class="mb-3 has-success" v-else>

	         	<input type="" name="trackname" class="form-control card p-2 noshadow readonly titletext mb-0" v-model="activetracklabel" ref="inputtracklabel">

	         	<div class="text-right mt-1">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary btn-sm mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="updatetrack( 'label', activetracklabel)" class="btn btn-primary btn-sm" type="button">Save</button>
	         	</div>

	         	</div>

	         	
	         	
         	</div>
         	<div v-else>
         		<h3>{{ activetrack.label }} </h3>
         	</div>
         	
         	
         	<div class="mb-1" id="masterplandetails">

         		<label>Status</label> 

         		

         		<div v-if="!canwrite" :text="( activetrack.status && activetrack.status != '' )?trackstatus(activetrack.status):''">
         		</div>
         		<div v-else>

         			<vueselect class="masterplanstatuschooser" @input="statushasbeenselected" :searchable="false" :clearable = "false" v-model="statusselected" :options = "statusoptions">
         			<template #open-indicator="{ attributes }">
			          <span v-bind="attributes"> <i class="fas fa-sort"></i></span>
			        </template>
			        <template #option="{ color, icon, label }">

			          <div class ="d-flex align-items-center">
            <div class ="icon-holder">
              <span><i :class="icon" :style="'color: var(--'+color+')'"></i></span>
            </div>
            <div :class="{'droptext':ismobile, 'text-wrap':ismobile}">{{label}}</div>
          </div>

			        </template>
			         <template #selected-option="{ color, icon, label }">
			           <div class ="d-flex align-items-center">
            <div class ="icon-holder">
              <span><i :class="icon" :style="'color: var(--'+color+')'"></i></span>
            </div>
            <div :class="{'droptext':ismobile, 'text-wrap':ismobile}">{{label}}</div>
          </div>
			        </template>

         		</vueselect>

         			<b-dropdown v-if="1==2" variant="notabutton" id="dropdown-1" :text="( activetrack.status && activetrack.status != '' )?trackstatus(activetrack.status):'Select status'" class="text-left">
         				<template #button-content>
	      						<i :class="'pr-2 fa fa-' + trackicon(activetrack.status) + ' ' + activetrack.status " ></i> {{ trackstatus(activetrack.status) }}
	   					</template>

				    <b-dropdown-item :disabled="activetrack.status == 'green'?'disabled':false" @click.stop.prevent="updatetrack('status', 'Green')"><div class="d-flex align-items-start">
				    	<div style="width:32px; height: 32px;"><i class="fa fa-check green" ></i></div> {{ trackstatus('green') }}</div></b-dropdown-item>
				    <b-dropdown-item :disabled="activetrack.status == 'yellow'?'disabled':false" @click.stop.prevent="updatetrack('status', 'Yellow')"><div class="d-flex align-items-start">
				    	<div style="width:32px; height: 32px;"><i class="fas fa-exclamation yellow" ></i></div> {{ trackstatus('yellow') }}</div></b-dropdown-item>
				    <b-dropdown-item :disabled="activetrack.status == 'red'?'disabled':false" @click.stop.prevent="updatetrack('status', 'Red')"><div class="d-flex align-items-start">
				    	<div style="width:32px; height: 32px;"><i class="fas fa-times red" ></i></div> {{ trackstatus('red') }}</div></b-dropdown-item>
				    <b-dropdown-item :disabled="activetrack.status == ''?'disabled':false" @click.stop.prevent="updatetrack('status', '')">{{ trackstatus('') }}</b-dropdown-item>
				  	</b-dropdown>
         			
         		</div>
         		
         		

         	</div>

         	<div class="mb-4 mt-3" v-if="canwrite">

         	<label class="form-label">Description</label>

         	<div @click="doeditdescription" class="has-success editable">

         	<div id="inputtrackdescriptionviewer" ref="inputtrackdescriptionviewer" v-if="editdescription == false" class="card p-2 noshadow readonly titletext mb-0 description" style="min-height: 72px;max-height: 200px; overflow-y: auto; white-space: pre-wrap;">{{ activetrackdescription }} 

         	<i class="fa fa-pencil editableicon"></i>	
         	</div>

         	</div>

         	<textarea id="inputtrackdescriptioneditor" style="overflow: hidden; overflow-y: auto; height: auto; min-height: 72px; resize: vertical; max-height: 200px;" v-if="editdescription==true" name="trackdescription" class="form-control card p-2 noshadow readonly titletext mb-0 autogrow" v-model="activetrackdescription" ref="inputtrackdescription"></textarea>

         	<div class="text-right mt-1"  v-if="editdescription==true">
	         		<button @click.stop.prevent="canceledit" class="btn btn-secondary btn-sm mr-1" type="button">Cancel</button>
	         		<button @click.stop.prevent="updatetrack('description', activetrackdescription)" class="btn btn-primary btn-sm" type="button">Save</button>
	         	</div>


         	</div>
         	<div v-if="!canwrite" class="mb-4 mt-3">

         		<label class="form-label">Description</label> 

         		<div id="inputtrackdescriptionviewer" ref="inputtrackdescriptionviewer" v-if="editdescription == false" class="card p-2 noshadow readonly titletext mb-3" style="min-height: 72px;max-height: 200px; overflow-y: auto; white-space: pre-wrap;">{{ activetrackdescription }} 

         		
         		</div>
         		
         	</div>

         	<hr class="m-0 p-0">


         	<div class="mb-1 mt-4"> 

         	  	

         	<div>
         	<template v-if="!canwrite">

         	


         	<b-input-group class="mb-3" style="width: 100%;">

				<template #prepend>
				  <b-input-group-text class="plain" >Start date</b-input-group-text>
				</template>

		        {{ trackdates.editstart.value }}
		    </b-input-group>

         	</template>
			<template v-if="canwrite">


			<datepicker @ondatepicked="dodatepickedstart" :datevalue="trackdates.editstart.value" :defvalue="trackdates.editstart.value" :prependstr="'Start date'" ></datepicker>
			
			</template>	
			</div>

         	</div>


         	<div class="mb-3 mt-3"> 

         	  	

         	<div class="d-flex align-items-center">
         	<template v-if="!canwrite">

			<b-input-group class="mb-1" style="width: 100%;">

				<template #prepend>
				  <b-input-group-text class="plain" >End date</b-input-group-text>
				</template>

		      {{ trackdates.editend.value }}
		    </b-input-group>


         	</template>
			<template v-if="canwrite">

			

			<datepicker @ondatepicked="dodatepickedend" :datevalue="trackdates.editend.value" :defvalue="trackdates.editend.value" :prependstr="'End date'" :mindate="editmindate"></datepicker>
			
			</template>	
			</div>

         	</div>

         	<hr class="m-0 p-0">

			<div class="mb-3 mt-3">


         		<label>Track owner</label>  

         		<ownerPicker :singleuser="true" :activeriskobject="activetrack" :team="team" @onupdaterisk="updatetrackowner" :canwrite="canwrite" :boardusers="boardusers"></ownerPicker>
 
         	</div>

         	<hr class="m-0 p-0">

         	<div class="mt-3 mb-3">


         		<label v-if="activetrack.milestones && activetrack.milestones.length > 0">Milestones</label> 
         		<label v-else>&nbsp;</label>

         		<a v-if="addmilestoneeditmode == false && canwrite == true" @click="addmilestone()" class="btn btn-primary float-right">Add milestone</a>


         		         		<!-- style="max-height: 250px; overflow-y: scroll;" -->
         		
         		<div class="mb-3">


         			


         		<template>

         			
	         		<div v-if="activetrack.milestones && activetrack.milestones.length > 0" class="mt-3 mb-1">
	         			<table class="table table-borderless mb-0" >
         				<tr class="selectable" :class="{'even':key%2==0, 'canwrite':canwrite}" :key="key" v-for="( milestone, key) in activetrack.milestones" @click.prevent="updatemilestone(milestone)">
         					<td nowrap="nowrap" class="p-2" style="width:30%;" >{{ milestone.eventdate }}<span class="text-danger" v-if="milestone.iscritical"> *</span></td>
         					<td class="p-2" style="word-break: break-all;">{{ milestone.label }} </td>
         				</tr>
         			</table>
	         		<span class="ml-2"><small class="text-danger">*</small><small> Critical path</small></span>
         		</div>
         		</template>

         		

         	</div>         		
 
         	</div>
         	

         	<hr class="m-0 p-0">


         	<div class="mb-1 mt-3" v-if="canwrite">

         		<button class="btn btn-danger mr-2" @click="trydeletetrack()">Delete track</button>
         		
         	</div>

         	</div>
         </div>

         </template>

         

       </div>

         


     		<p class="clearfix">&nbsp;</p>
     </div>



     <div id="floating" class="card hide" style="position:absolute; background: #ffffff;">
     	
     	<div class="card-body">	
     	<p>Test</p>

     	<table class="table table-striped">

     		<tr :key="sh._id" v-for="sh in sortedplans">
     			<td>{{ sh.label }} </td>
     			<td> {{ prod( sh ) }} </td>
     		</tr>
     		
     	</table>
     	</div>
     </div>





      </div>
</template>

<style type="text/css">

.masterplanstatuschooser .vs__search{
   /* visibility: hidden;*/
    height: 1px;
    opacity: 0;
  }


.checkbox-form{
			height: 38px;
		}


@media screen and (max-width: 400px) {
   .editable .description{
			padding-right: 35px !important;
	}
}

@media(max-width: $breakpoints-md){


	

		.checkbox-form{
			height: 48px !important;
		}
	}

.selectable{
	cursor: pointer !important;
}
.selectable:hover{
	opacity: .9;
}

#masterplandetails .vs__actions {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 0 13px !important;
}


.vs__selected{
	  /*display: none !important;*/
	  /*margin: 0 !important;
	  padding: 0 !important;*/
	  margin: 0 0 0 0 !important;
	  padding: 0 0 !important;
	}

	.vs__dropdown-option:hover{
      color: #000 !important;
    }

    .vs__open-indicator{
    color:#000 !important;
    margin: auto
  }

  .vs__dropdown-option--highlight{
    background-color: $N100;
    color:#000 !important;
  }


	.btn-notabutton{
		font-size: 16px;
	}

	/*.vs__dropdown-toggle, .vs__search{

    background:#fff !important;
   }*/

	.disable-select {
	    user-select: none; /* supported by Chrome and Opera */
	   -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
	   -moz-user-select: none; /* Firefox */
	   -ms-user-select: none; /* Internet Explorer/Edge */
	}


	#masterplan.sm div.apexcharts-pan-icon, #masterplan.sm div.apexcharts-zoomin-icon, #masterplan.sm div.apexcharts-zoomout-icon, #masterplan.sm div.apexcharts-zoom-icon, #masterplan.sm div.apexcharts-reset-icon, #masterplan.sm div.sort-tracks-icon, #masterplan.sm div.apexcharts-toolbar-custom-icon {
	  transform: scale(1.0) !important;
	  position: relative;
	  left: 1px;
	  top: 0px;
	  width: 48px;
	  padding-right: 0px;
	  padding-left: 20px;
	  font-size: 20px;
	  margin-left: -10px;
	}
	
	#masterplan div.apexcharts-pan-icon, #masterplan div.apexcharts-zoomin-icon, #masterplan div.apexcharts-zoomout-icon, #masterplan div.apexcharts-zoom-icon, #masterplan div.apexcharts-reset-icon, #masterplan div.sort-tracks-icon, #masterplan div.apexcharts-toolbar-custom-icon {
	  transform: scale(1.0) !important;
	  position: relative;
	  left: 1px;
	  top: 0px;
	  width: 48px;
	  padding-right: 0px;
	  padding-left: 8px;
	  font-size: 16px;
	}

	.apexcharts-element-hidden{
		display: none !important;
	}

	#apexchartsmasterplanchart{
		z-index: 50 !important;
	}

</style>


<style type="text/css" scoped>



.custom-control-inline{
	margin-right: 0;
}

.input-group-append{
	background-color: #ffffff !important;
}

.even{
	background-color: #f5f6f8;
}

.input-group-text{
	padding-left: 0;
}

.milestone-item.canwrite{
	cursor: pointer;
}


.plain{
	background: none !important;
	border: none;
	width: 100px;
}


::v-deep .apexcharts-bar-area:hover {
  fill: #B5C3E3 !important;
}

::v-deep .apexcharts-rangebar-area {
	margin-top: -50px;
}

::v-deep .apexcharts-menu-item.exportCSV {
    display: none;
  }

		.lwidth_lg{
			float: left;
			padding-right: 2rem !important;
		}
		.rwidth_lg{
			float: left;
			width: 390px;
		}

		.lwidth_md{
			width: 400px;
			/*margin: auto;*/
		}
		.rwidth_md{
			/*margin: auto;*/
			width: 390px;
		}

		.lwidth_sm{
			/*margin: auto;*/
		}
		.rwidth_sm{
			margin: auto;
			/*width: 336px;*/
		}

	.narrow_lg{
		margin-top: 15px;
		margin-left: 10px;
	}
	.narrow_md{
		margin-top: 15px;
		margin-left: 10px;
	}
	.narrow_sm{
		margin-top: 15px;
	}

	.minwidth_sm{
		max-width: 360px;
	}

	.minwidth_md{
		min-width: 510px;
	}

	.minwidth_lg{
		min-width: 580px;
	}

	.action{
		font-size: 140%;
		text-align: center;
	}


	.green{ color: $G500; }
	.red{ color: $R700; }
	.yellow{ color: $Y500; }
	.blue{ color: $B200; }

	.white{ color: #ffffff; }

   .contact-selected{
   	border: solid 2px blue;
   }

	.plan{
		border: solid 1px #000;
		padding: 3px;
		background-color: #fff;
	}


	.vdr.active::before{
		outline: none;
	}

	.ui-selectable:hover{
		cursor: pointer;
	}


	.highlight{
		border: solid 1px #000;
		padding: 5p ;
	}

	.table th, .table td{
		padding: 0;
	}

	


	/*.ui-draggable-dragging{
		transform: scale(1.7);
	}*/

	.ui-selected .marker{
		transform: scale(1.2);
	}

	.touch-start{
		transform: scale(1.5);
	}

	/*.ui-selected span{
		position: relative;
		width: auto;
	}*/
</style>
<script type="text/javascript"></script>

<script>

	import Vue from 'vue'

	import Sortable from 'sortablejs';

	import Userapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'

	import Emailsapi from '@/services/api/emails'
	import {bus} from '@/main'

	import memberlistfull from '@/components/members/memberlistfull'
	

	 import vueselect from '@/views/tools/vueselect'
	import moment from 'moment';
	import ApexCharts from 'apexcharts'


	import ownerPicker from './ownerPicker'

	import AWS from 'aws-sdk'

	const ID = 'AKIA5ULT5RVZU7LWNUZY';
	const SECRET = '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';

	// The name of the bucket that you have created
	const BUCKET_NAME = 'flowe-editor';
	AWS.config.update({region: 'eu-west-3'});

	const s3Bucket = new AWS.S3({
	accessKeyId: ID,
	secretAccessKey: SECRET,
	apiVersion: '2006-03-01', 
	region: 'eu-west-3',
	params: {Bucket: BUCKET_NAME,ACL : 'public-read',}
	});

	export default{
		name: 'boardmasterplan',
		data(){
			return{
				doexportready:false,
				presavemilestonetimer:null,
				withtrackupdates:0,
				milestonedatestate:0,
				milestonemodal:false,
				attributes: { 'ref': 'openIndicator', 'role': 'presentation', 'class': 'vs__open-indicator'	},
				statusselected : {},
				statusoptions : [ { color:"green", icon:"fas fa-check", label:"On-track" }, { color:"yellow", icon:"fas fa-exclamation", label:"Some milestones delayed" }, { color:"red", icon:"fas fa-times", label:"Not on-track" }, { color:"", icon:"", label:"No status" } ],
				doupdatecriticalpath: true,
				minDate : new Date(),
				tracklistsorted : [],
				sorttracks : false,
				addmilestoneeditmode: false,
				uploadedgraphimage:"",
				savedpng:null,
				graphworking:false,
				milestonelabelbox : [],
				milestonelabeltext : [],
				milestonemarkers : [],
				gridcolor1: '#111',
				gridcolor2: '#111',
				trackcolor : '#B5C3E3', //b200
				tracktextcolor : '#313338', //n900				
				cpcolor : '#da6060', //R300
				notcpcolor : '#696f7b', //N700
				lastzoom:null,
				zoom2:null,
				criticalpathrendered:false,
				lastaddedtrack:null,
				lastselectedtrack:null,
				iscriticalpath:false,
				trackisenabled:false,
				editmilestones:"",
				editmilestonesid:null,
				milestonesstatus:"idle",
				formtracklabel:"",
				formtrackdescription:"",
				trackname:"",
				trackdescription:"",
				activetrackdescription : null,
				activetrackaction : null,
				activetracklabel : "",
				activetrack : {},
				trackstart:"",
				trackend:"",
				activechart : null,
				tracks:[],
				trackdates:{ formstart:{ status:0, value:"" }, formend:{ status:0, value:"" } , editstart:{ status:0, value:"" }, editend:{ status:0, value:"" }, milestones:{ status:0, value:"" } },
				dataoptions : {
	          		series: [],
	          		noData : {
					  text: 'No available data',
					  align: 'center',
					  verticalAlign: 'middle',
					  offsetX: 0,
					  offsetY: 0,
					  style: {
					    color: undefined,
					    fontSize: this.ismobile ? '16px' : '14px',
					    fontFamily: undefined
					  }
					},
					states: {
					    normal: {
			        		filter: {
			            		type: 'none',
			            		value: .15,
			        		}
			    		},
				    	hover: {
				        	filter: {
				           		type: 'darken',
				            	value: 0.50,
				        	}
				    	},
				    	active: {
				        	filter: {
				            	type: 'darken',
				            	value: 0.50,
				        	}
				    	},
					},
	          		chart: {
	          			id: 'masterplanchart',
	          			redrawOnParentResize: true,
	          			animations: {
        						enabled: false
        					},
	          			zoom: { enabled: true },
	          			height: 250,
	          			width: '100%',
	          			type: 'rangeBar',
	          			background: '#fff',
						toolbar: { show: true, offsetY: -5, offsetX: 0, tools: {

						  download: false,
						  selection: '<i class="far fa-search-plus"></i>',
						  zoom: '<i class="far fa-search-plus"></i>',
						  zoomin: '<i class="far fa-plus-circle"></i>',
						  zoomout: '<i class="far fa-minus-circle"></i>',
						  pan: '<i class="far fa-hand-paper"></i>',
						  reset: '<i class="far fa-home-alt"></i>',
						  customIcons: [{
							icon: '<i class="fas fa-sort"></i>',
							index: 0,
							title: 'Reorder tracks',
							class: 'sort-tracks-icon',
							click: function (chart, options, e) {

								bus.$emit('reordersort')
							}
							}]
						},
						export:{}
						},
	          		events : this.chartEvents(),	          		
	        		},
	        		plotOptions: {
			          bar: {
			          	barHeight: '50%',
			          	borderRadius: 0,
			            horizontal: true,
			            distributed: false,
			            dataLabels: {
			              hideOverflowingLabels: false,
			              position: 'bottom'
			            },
		             	colors: {
		             	  backgroundBarColors:'#000',	
			              backgroundBarOpacity: 1
			          	},
			          }
			        },
			        
			        tooltip: {
      					enabled: false,
	      					custom: function({series, seriesIndex, dataPointIndex, w}) {

	      						//let newdate = new Date( series[seriesIndex][dataPointIndex] )

	      						//console.log( this. dataPointIndex )

	      						var rawlabel = w.globals.labels[dataPointIndex]
			            		let labels = rawlabel.split('x|x')

						    	return '<div class="p-2">' + labels[1] + '</div>'
						  	}
      				},
			        dataLabels: {
			          textAnchor: 'start',
			          enabled: true,
			          offsetY: -5,
			          offsetX: -35,
			          formatter: this.datalabelformatter,
			          style: {
			          	fontSize: this.ismobile ? '16px' : '14px',
					    //fontFamily: 'Helvetica, Arial, sans-serif',
					    //fontWeight: 'bold',
			            colors: [this.tracktextcolor, '#fff']
			          }
			        },
			        xaxis: {
			          type: 'datetime',
			          labels:{show:true, textAnchor: 'end'}
			        },
			        yaxis: {
			          show: false, showAlways: false, floating: true,
			          labels:{show:false, textAnchor: 'end'},
			          axisBorder: { show: true }
			        },
			        legend: {
	          				show: false,
	          				showForSingleSeries: true,
	        //  customLegendItems: ['Actual', 'Expected'],
	         		 		markers: {
	            		fillColors: ['#00E396', '#775DD0']
	          		}
	        		},
	        		fill: {
			  		opacity: 1,
					},

			        grid: {
			          borderColor: '#696f7b',
					  row: {
					      colors: ['transparent', 'transparent'],
					      opacity: 1
					  },  
					  xaxis: {
					    lines: {
					      //show: true
					    }
					  }
					},
        		},
				//
				datepickerstate : 0,
				showclosedplan : false,
				explainvalues : false,
				closingremarks : "",
				closingvalue : null,
				closingplan : false,
				proximityplan:"",
				dtepicker: { disableMobile:true, maxDate:'', minDate:null, altFormat: 'Y-m-d', altInput: true, dateFormat: 'Y-m-d', showMonths:1, monthSelectorType:'static'},
				selectedforexport : { 'label':"(DK) Powerpoint", 'key':"dk1" } ,
				exporttemplates : [ { 'label':"(DK) Powerpoint", 'key':"dk1" } , { 'label':"(EN) Powerpoint", 'key':"en1"  } ],
				
				width: 100,
				height: 0,
				top: 0,
				left: 0,
				isdragging: false,
				tracks : [],
				plans : [],
				closedplans : [],
				activetrack : {},
				tempplan : {},
				focusplan : {},
				
				textcontact:"",
				textemail:"",
				windowsize:0,
				showtooltip:true,
				showpopover:false,
				nofade:true,
				sortedplans:[],
				mode:"idle",
				confirmplan:false,
				mouseovertimer:0,
				mouseovert : null,
				addnewtrack: false,
				addanother: false,
				//boardusers : [],
				editlabel: false,
				editdescription: false,
				editaction:false,
				exportloading: false,
				exportloading2: false,
				imgbody : null,
				touchevent : "",
				touchtimer : null,
				apidomain:""
			}
		},

		props:{
		},

		watch:{

			'activetrack.label' : function( e, ev ){

				if( e == '' ){

					this.activetrack.label = ev
				}

			},

			/*addnewtrack : function( e ){
				if( e == true ){
					this.activetrack = {}
					this.showpopover = false
				}				
			},*/

			/*plans : function( e ){

				if( e.length == 0 ){
					this.activetrack = {}
				}

				this.sortedplans = e 
			},*/
			//activetrack : function( e ){

   				//this.updateboardusers()
			//},

			cockpit : function( e ){

				if( e.masterplanobjective && e.masterplanobjective.isactive == false  ){
					//redir
					let currentroute = this.$router.history.current.path
					let newroute = currentroute.replace("masterplan", "board")
					location.href=newroute
					//{{ cockpit.masterplanobjective._id }}
					//this.$router.push( 'board' )
				}
			}
		},

		computed:{

			editmindate(){


				let date = new Date(this.trackdates.editstart.value) 
				date.setDate(date.getDate() + 1);
				return date

			},

			formstartvalue(){

				return this.trackdates.formstart.value
			},

			formstartready(){

				let toreturn = true
 
				if( this.trackdates.formstart.value != "" )  {
					toreturn = false
				}


				return toreturn
				//console.log( trackdate , "before")
			},

			buttonVariant(){

				let toreturn = ' border-normal'

				if( this.datepickerstate == 1 ){
					toreturn = ' border-success'
				}
				if( this.datepickerstate == 2 ){
					toreturn = ' border-danger'
				}

				return toreturn
			},


			boardusers(){


				let teamids = []

   				for( var i in this.cockpit.sharedtoteams ){
					teamids.push( this.cockpit.sharedtoteams[i].userid )
				}
			

				let boardusers = []

				if( this.team && this.cockpit.sharedtoteams && teamids.indexOf( this.team._id ) > -1){	       

					for( var i in this.team.sharedtousers ){

						//console.log( teamids, this.team.sharedtousers[i].userid , "lllllllllll")

						if( boardusers.indexOf( this.team.sharedtousers[i].userid ) == -1 ){
							boardusers.push( this.team.sharedtousers[i].userid )
						}
							
					}

				}

				return boardusers

			},

			allowsave(){

				let notallowed = 0 
				if( this.formtracklabel == '' ){
					notallowed += 1
				}

				if( this.trackdates.formstart.value == '' ){
					notallowed += 1
				}

				if( this.trackdates.formend.value == '' ){
					notallowed += 1
				}

				if( notallowed == 0 ){
					return true
				}else{
					return false
				}
			},
			
			canexport(){

				let toreturn = false

				if( this.tracks && this.tracks.length > 0 ){
					toreturn = true
				}

				return toreturn

			},

			canwrite(){

				let toreturn = false

				if( this.cockpit && ( this.cockpit.myrole.toLowerCase()=='owner' || this.cockpit.myrole.toLowerCase()=='contributor')){
					toreturn = true
				}
				else if( this.cockpit && ( this.cockpit.myrole.toLowerCase()!='owner' && this.cockpit.myrole.toLowerCase()!='contributor')){
					toreturn =  false
				}

				if( this.isboardusers( this.loggedinuser._id ) ){
					toreturn = true
				}

				return toreturn
			},

			loggedinuser(){
			    return this.$store.getters.user
			},

			team(){
				return this.cockpit.masterplan ? this.cockpit.masterplan : {}
			},

			singlecol(){

				if( this.windowsize < 1087 ){
					return 'narrow'
				}else{
					return 'wide'
				}
				 
			},

			ismobile(){

				return this.$store.state.isMobile
			},

			windowspec(){

				var ismobile = this.$store.state.isMobile

				var winsize = ""

				if(  ismobile || this.windowsize < 460 ){
					winsize = 'sm'
				}else if( this.windowsize >= 461 && this.windowsize < 768 ){
					winsize = 'md'
				}else{
					winsize = 'lg'
				}

				return winsize

			},

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		}, 

		created() {
		  window.addEventListener("resize", this.windowOnresize);
		},
		destroyed() {
		  window.removeEventListener("resize", this.windowOnresize);
		},

		mounted(){

			bus.$off('reordersort')
			bus.$on('reordersort', this.customiconsortclicked)



			let self = this

			setTimeout( function(){

				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
        		self.activechart.render();

			},50)	
			

			this.trackname = ""
			this.plandescription = ""


			this.windowOnresize()

			

			setTimeout( function(){

				self.getalltracks()
			}, 50)
			

		},

		filters: {
  			nl2br : function( str ){

  				if( str ){
  					return str.replace(/(\\r)*\\n/g, '<br>')
  				}else{
  					return str;
  				}

				

			},
		},

		methods:{

			statushasbeenselected( e ){

				console.log( e )

				this.updatetrack('status', e.color )
			},

			milestonemodalshown(){

				let self = this

				setTimeout( function(){
					self.$refs['editmilestonesinput'].focus()
				}, 100)
				
			},

			milestonedatestatechanged( val ){

				if( val == 2 ){
					clearTimeout(this.presavemilestonetimer)
					//this.presavemilestonetimer = null
				}

				this.milestonedatestate = val
			},

			dodatepickedmilestone( e ){

				this.trackdates.milestones.value = e

			},

			dodatepickedstart( e ){

				this.updatetrack('trackstart', e )
				this.trackdates.editstart.value = e

				if( new Date( this.trackdates.editstart.value ) >= new Date( this.trackdates.editend.value ) ){

					let date = new Date( this.trackdates.editstart.value )
					date.setDate(date.getDate() + 1);

					this.updatetrack('trackend', moment( date ).format('YYYY-MM-DD') )
					this.trackdates.editend.value = moment( date ).format('YYYY-MM-DD')
				}
			},
			dodatepickedend( e ){

				this.updatetrack('trackend', e )
				this.trackdates.editend.value = e
			},

			trackssorted(){

				this.getalltracks()
			},

			updatetracksort(e){

				let self = this
			  
			  	let tracks = document.querySelectorAll('.sortedtracks')

			  	let trackpos = 1

			  	let update = []

				tracks.forEach(function (element) {
				//	console.log( element.id)					

					update.push( { "trackid":element.id, "sortorder": trackpos+"" } )	

					trackpos += 1			
				});

				//self.updatetrack( 'sortorderall', trackpos, element.id )
				

				Userapi.reOrderTracks( { "field":"reordertracks", "boardid": self.cockpitid, "t": self.token, "sortthis": JSON.stringify(update) } ).then( function(response){
					self.sorttracks = false
				})

				//
			},

			/*updatesort( e,i,o){

				//let el = $('#sortabletracks').get(0)

				let tracks = document.querySelectorAll('.sortedtracks')

				tracks.forEach(function (element) {
					  				console.log( element.id)
								});

				//toArray
			},*/

			customiconsortclicked(){

				this.sorttracks = true
			},

			enablesorttracks(){

				this.bindsortabletracks()

			},

			bindsortabletracks(){

				var delay = this.$store.state.isMobile ? 400 : 0;


				let self = this

			    new Sortable( $('#sortabletracks').get(0)   , {
			          group: 'shared',
			          animation: 150,
			         // handle: ".draghandle",
			          delay:delay,
			          chosenClass:"shadow",
			          ghostClass:"ghostClass",
			          //onUpdate:function( event ){ 
			          // self.updatesort( event )
			          //},
			      });


			},

			exportwindowclosed( e ){
				
				//let self = this
				/*

				setTimeout( function(){
				//	self.$refs['initexportbutton'].blur()
				}, 800)

				setTimeout( function(){


				self.activechart.destroy();
				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
	        	self.activechart.render();


	        	self.getalltracks()

				}, 100)*/

				/*self.activechart.destroy();
				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
	        	self.activechart.render();
	        	self.getalltracks()*/

				//location.reload();

			},

			datalabelformatter(val, opts) {

	          	let track = this.tracks[opts.dataPointIndex]
	          	return track.label// + " " + track.sortorder
	            //var rawlabel = opts.w.globals.labels[opts.dataPointIndex]
	            //let labels = rawlabel.split('x|x')
	            //return labels[1]
			},

			graphclicked: function(e) {

				//console.log( e )


				

				//console.log( clientX, clientY, "client")

				let self = this

				for( let i in this.milestonemarkers ){

					let clientX = e.layerX
					let clientY = e.layerY

					let x = this.milestonemarkers[i].getAttribute("x1")
					let y = this.milestonemarkers[i].getAttribute("y1")

					let found = 0
					let proximity = 9.0

					//console.log( this.milestonemarkers[i], "this")				

					//console.log( x, y, "xy")

					if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
						found += 1
					}
					if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
						found += 1
					}

					if( found < 2 ){

						found = 0

						clientX = clientX - 20.0
						clientY = clientY - 30.0

						//console.log( clientX, clientY, "recompute")



						if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
							found += 1
						}
						if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
							found += 1
						}

					}


					if( found == 2){

						if(  this.milestonelabelbox[i] ){
							this.milestonelabeltext[i].style.display = "block"
								this.milestonelabelbox[i].style.display = "block"
						}
						

							/*if( this.activetrack ){
								this.activetrack.milestones.forEach(function (element) {
					  				console.log( element._id , "ml")
								}); 
								//= this.tracks[i]
							}*/

						found = 0
						
					}else{

						if(  this.milestonelabelbox[i] ){
							this.milestonelabeltext[i].style.display = "none"
						this.milestonelabelbox[i].style.display = "none"
						}
						
					}

					//console.log( found )

					//console.log( found , "lines")
				
				}

				//console.log( e )
		        //do stuff
		        e.preventDefault();
		     },


			contextmenu: function(e) {

				//.log( e )
		        //do stuff
		        e.preventDefault();
		     },

			sortorder( track , direction ){

				let neworderposition = 0

				if( direction == "up" ){
					neworderposition = track.sortorder != null ? track.sortorder + 1 : 0
				}else{
					neworderposition = track.sortorder != null ? track.sortorder - 1 : 0
				}

				this.updatetrack( 'sortorder', neworderposition )
			},

			openoutside( what ){

			//	if( what == 'custom' ){
				window.open( "/masterplan1?boardid="+this.cockpit._id )
			//		location.href=
			//	}
			},

			trackicon( status ){

				let toreturn = ''


				if( status == 'green' ){
					toreturn = 'fas fa-check'
				}
				if( status == 'yellow' ){
					toreturn = 'fas fa-exclamation'
				}
				if( status == 'red' ){
					toreturn = 'fass fa-times'
				}
				if( status == '' ){
					toreturn = ''
				}


				return toreturn
			},

			trackstatusitem( status ){

				let toreturn = {}

				this.statusoptions.forEach(function (element) {
					//console.log( element, element.color,)
					if( element.color == status ){
						toreturn = element
					}
				});

				return toreturn
			},

			trackstatus( status ){

				let toreturn = ''

				if( status == 'green' ){
					toreturn = 'All on-track'
				}
				if( status == 'yellow' ){
					toreturn = 'On-track, some milestones delayed'
				}
				if( status == 'red' ){
					toreturn = 'Not on-track'
				}
				if( status == '' ){
					toreturn = 'No status'
				}

				return toreturn
			},

			addmilestone(){

				this.milestonemodal = true

				this.activetrack.milestones.forEach(function (element) {
				  element.editmode = false
				});


				this.addmilestoneeditmode = true

				this.editmilestonesid = ""
				this.editmilestones = ""

				this.trackdates.milestones.value = moment( new Date() ).format('YYYY-MM-DD')
				this.iscriticalpath = false
				this.milestonesstatus='add'

			},

			presavemilestone(){

				let self = this

				let timer = 100

				if( this.milestonedatestate == 4 ){
					timer = 500
				}

				this.presavemilestonetimer = setTimeout( function(){
					
							self.savemilestone()
					}, timer )

				
			},

			savemilestone(){

				let milestone = { "_id": this.editmilestonesid ? this.editmilestonesid : "generatenew", "label": this.editmilestones, "eventdate":this.trackdates.milestones.value, "status":"", "iscritical":this.iscriticalpath }

				if( milestone._id == 'generatenew' ){
					this.updatetrack( 'addamilestone', JSON.stringify(milestone) )
				}else{
					this.updatetrack( 'updateamilestone', JSON.stringify(milestone) )
				}

				this.canceleditmilestone()				

			},

			trydeletemilestone( mid ){

				this.milestonemodal = false
				this.updatetrack( 'removemilestone', mid )

			},

			updatemilestone( milestone ){

				if( !this.canwrite ){
					return false
				}

				this.milestonemodal=true

				this.addmilestoneeditmode = false

				this.editmilestonesid = milestone._id
				this.editmilestones = milestone.label
				this.trackdates.milestones.value = milestone.eventdate
				this.iscriticalpath = milestone.iscritical

				this.activetrack.milestones.forEach(function (element) {
				  element.editmode = false
				});

				milestone.editmode=true
			},

			canceleditmilestone( milestone ){

				this.milestonemodal=false

				this.addmilestoneeditmode = false

				this.activetrack.milestones.forEach(function (element) {
				  element.editmode = false
				});

				this.milestonesstatus='idle'

			},

			addmilestonestotrack(){

				let milestone = { "label": this.editmilestones, "eventdate":this.trackdates.milestones.value, "status":"", "iscritical":false }

				this.updatetrack( 'addamilestone', JSON.stringify(milestone) )

			},



			getTrack( trackid ){

				let self = this


				this.editlabel = false

				function sortmilestones( a, b ) {
				  if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
				    return -1;
				  }
				  if ( new Date(a.eventdate) > new Date(b.eventdate) ){
				    return 1;
				  }
				  return 0;
				}

				//tracksmilestones.sort( sortmilestones );
				this.lastaddedtrack = null
				//this.lastselectedtrack = trackid

				Userapi.Track( trackid, this.token ).then( function(response){

					if( response.data.ok ){
						self.activetrack = response.data.track

						let tracksmilestones = []

						if( self.activetrack.milestones ){

							tracksmilestones = self.activetrack.milestones
							tracksmilestones.sort( sortmilestones );

							/*tracksmilestones.forEach(function (element) {
							  element*.editmode = false
							});*/

						}

						//self.activetrack.milestones
						//tracksmilestones.sort( sortmilestones );

						self.activetrack.milestones = tracksmilestones

						self.trackdates.editstart.value = self.activetrack.trackstart
						self.trackdates.editend.value = self.activetrack.trackend
						self.activetrackdescription = self.activetrack.description


						self.statusselected = self.trackstatusitem(self.activetrack.status)

					}
				})



			},

			chartEvents(){

				let self = this


				return {
					beforeMount: function (chartContext, config) {
				        	setTimeout( function(){
			      				self.savemeacopy('upload')
			      			}, 1000)
				      },
			      dataPointSelection: function( event, chartContext, config ) {

			      	let i = self.activechart.w.globals.selectedDataPoints
			      	//console.log( i[0] )

			      	if( i[0][0] != null ){

						let index = config.dataPointIndex

						self.lastselectedtrack = index
				      	if( self.tracks[index] ){
				      		self.getTrack( self.tracks[index]._id )
				      	}


			      	}else{

			      		self.activechart.toggleDataPointSelection( 0, self.lastselectedtrack ) 

			      	}

			      	

			      },
			      mounted: function (chartContext, options) {

			      	//console.log( "mounted")


			      	setTimeout( function(){


			      		self.savemeacopy()
			      	}, 300)

			      	setTimeout( function(){

			      		//alert('')

			      		//self.activechart.toggleDataPointSelection( 0, 3 ) 

			      		self.savemeacopy()
			      	}, 500)
			      	
			      },
			      animationEnd: function (chartContext, options) {
			      	alert('')
			      },
			      updated: function(chartContext, config) {

			      	//alert( self.lastaddedtrack )

			      	/*if( self.lastaddedtrack == null ){

			      		self.activechart.toggleDataPointSelection( 0, 0 ) 
			      		//self.lastaddedtrack = null
			      		
			      	}else{

			      		self.activechart.toggleDataPointSelection( 0, 3 ) 
			      		self.lastaddedtrack = null

			      	}*/

					self.graphworking = true
					console.log( "updated")

			        setTimeout( function(){

			      		//if( self.doupdatecriticalpath ){
			      		self.rendercriticalpath( function(){

								self.savemeacopy()
			      				self.graphworking = false
			      		})
			      		//}

			      		//self.doupdatecriticalpath = false
			      		
			      		
			      	}, 200)
			      },
			      beforeResetZoom: function () {
			        self.lastzoom = null;
			      },
			      zoomed: function(_, value ) {
			        self.lastzoom = [value.xaxis.min, value.xaxis.max];
			      }
			    }

			},

			savemeacopy( isupload , callback ){


				let self = this

				this.activechart.dataURI().then(({ imgURI }) => {
    				//self.$emit('ondumppng', imgURI)
    				self.savedpng = imgURI
	        		


	        		if( isupload && isupload == 'upload' ){


								var md5 = require('md5');

					            let base64image = imgURI
					            let nowstring = new Date().toString()

					            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

					            let imagekey = "image" + nowstring

					            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
					            var data = {
					            Key: md5( imagekey  ) + ".jpg" , 
					            Body: buf,
					            ContentEncoding: 'base64',
					            ContentType: 'image/jpeg'
					            };

					            s3Bucket.putObject(data, function(err, data){
					              if (err) { 
					                
					                console.log(err);
					                console.log('Error uploading data: ', data); 
					              
					              } else {

					                //console.log( "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" , 'successfully uploaded the image!');
					                //let graphimageurl = "<img src=\"https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" + "\" style=\"width: 300px;\" class=\"fr-fic fr-dib\">"

					                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg"

					               // console.log(graphimageurl, "uploaded");

					                self.uploadedgraphimage = graphimageurl

					               if( self.exportloading == false ){

					               	Userapi.savePlanPng( { "field":"savepng", "boardid": self.cockpitid, "t": self.token, "imgurl": graphimageurl } )
					               }

					                
					                if( callback ){
					                	callback(graphimageurl)
					                }


					              }
					            })



	        		}else{

	        			Userapi.savePlanPng( { "field":"savepng", "boardid": this.cockpitid, "t": this.token, "imgurl": imgURI } )
	        		}

	      		})

			},

			rendercriticalpath( callback ){

				let self = this

				let milestoneYs = []
				let milestoneXs = []

				let baroffset = '-6'

				if( this.exportloading == true ){
					baroffset = '-25'
				}


		       	let parent = document.getElementById('apexchartsmasterplanchart');

		       	let isrendered = parent.querySelector('#masterplancriticalpath')

		       	if( !parent || isrendered ){
		       		return false
		       	}

		       	let rangebars = parent.querySelectorAll('.apexcharts-rangebar-area')

		       	for( let i in rangebars ){
		       		if( rangebars[i] && typeof rangebars[i].hasAttribute === 'function' &&  rangebars[i].hasAttribute('barHeight') === true ){
		       			if( rangebars[i].hasAttribute('barHeight') === true ){
		       				rangebars[i].setAttribute('transform','translate(0,'+baroffset+')');
		       			}
		       		}
		       		
		       	}

		       	let element = parent.querySelector('.apexcharts-svg')

				let goalgroups = parent.querySelectorAll('[className="apexcharts-bar-goals-groups"]');				

					
				let xypath = []
				for( let i in goalgroups ){
					let g = goalgroups[i]

					let lines = []

					let x = 0.0

					let y = 0.0

					if( typeof g.getElementsByTagName === 'function' ){
						
						lines = g.getElementsByTagName('line')

						for( let o in lines ){

							let x = null
							//lines[o].x1
							let y = null

							if( typeof lines[o].hasAttribute === 'function' ){

								/*lines[o].setAttribute("onclick", function(){

									alert('')
								})\\*/

								



								let stroke = lines[o].getAttribute("stroke")

								 // if critical by color

									let pad = 18.0// 20.0 

									

									x = lines[o].getAttribute("x2")
									let y1 = lines[o].getAttribute("y1")
									let y2 = lines[o].getAttribute("y2")

									lines[o].setAttribute("y1", parseFloat(y1) + pad)
									lines[o].setAttribute("y2", parseFloat(y2) + pad)

									// set on wide
									if( self.exportloading == true ){
										lines[o].setAttribute("y1", parseFloat(y1) + 7.0)
										lines[o].setAttribute("y2", parseFloat(y2) + 7.0)
									}

									//lines[o].setAttribute("transform", "rotate(45)")

									//lines[o].setAttribute("x2", parseFloat(x) + 30.0)

									x = parseFloat(x) + 12
									y = parseFloat(y1) + 23.0

									if( self.exportloading == true ){
										//x = parseFloat(x) + 27.0
										y = parseFloat(y1) + 13.0
									}

								milestoneYs.push(y)
								milestoneXs.push(x)

								if( stroke == this.cpcolor){
									xypath.push( {x:x,y:y} )
								}								

								self.milestonemarkers.push( lines[o] )
							} 								
							
						}

						
					}
					
				}


			let cntr = 0
			let labely = null


			let pointsannotations = parent.querySelector('.apexcharts-point-annotations')

			if( pointsannotations && typeof pointsannotations.getElementsByTagName === 'function' ){


				let Xs = []


				let rects = pointsannotations.getElementsByTagName('rect')

				for( let i in rects ){
					if( rects[i] && typeof rects[i].hasAttribute === 'function'){
						
						rects[i].setAttribute("y", milestoneYs[i] )

						let y = rects[i].getAttribute("y")

						if( labely != y ){
	       					cntr = 0
	       				}

						// hide only labels on regular view
						if( self.exportloading == false){
							rects[i].style.display = "none"
						}else{

							if( i % 2 == 0 ){
       							rects[i].setAttribute("y", milestoneYs[i] )
	       					}else{
	       						rects[i].setAttribute("y", milestoneYs[i] + 18.0 )
	       					}

						 }
						
						//rects[i].style.display = "none"

						Xs.push( rects[i].getAttribute("x") )

						self.milestonelabelbox.push( rects[i] )

						cntr += 1
	       				labely = y
					}
				}				
								
				

			}
				

			let milestonelabels = parent.querySelectorAll('.apexcharts-point-annotation-label');	

			cntr = 0
			labely = null

			for( let i in milestonelabels ){
	       		if( milestonelabels[i] && typeof milestonelabels[i].hasAttribute === 'function'){
	       			//console.log( milestonelabels[i].textContent, "milestonelabels")

	       			//let alt = i % 2

	       			let y = milestonelabels[i].getAttribute("y")

	       			if( labely != y ){
	       				cntr = 0
	       			}

	       			milestonelabels[i].setAttribute("y", milestoneYs[i] + 14.0 )

	       			if( self.exportloading == false){
	       				milestonelabels[i].style.display = "none"	       				
	       			}else{

						if( i % 2 == 0 ){
	       					milestonelabels[i].setAttribute("y", milestoneYs[i] + 14.0 )
	       				}else{
	       					milestonelabels[i].setAttribute("y", milestoneYs[i] + 32.0 )
	       				}

	       			}

	       			//milestonelabels[i].style.display = "none"

	       			//console.log( milestonelabels[i].textContent)

	       			self.milestonelabeltext.push( milestonelabels[i] )

	       			cntr += 1
	       			labely = y
	       		}
	       		
	       	}

			function xycompare( a, b ) {
			  if ( a.x < b.x ){
			    return -1;
			  }
			  if ( a.x > b.x ){
			    return 1;
			  }
			  return 0;
			}

			xypath.sort( xycompare );


			let path = null

			let lastx = 0.0
			let lasty = 0.0


			for( let o in xypath ){

				let x = xypath[o].x
				let y = xypath[o].y

				let ypadding = 25						

				if( path ){

					if( x != lastx ){
						path.push( "L "+ lastx + " " + ( y + ypadding) )
					}

					//ypadding = o == (xypath.length-1) ? 0 : ypadding

					path.push( "L "+ x + " " + ( y + ypadding) )
				}else{
					path = ["M "+ x + " " + ( y + ypadding) ]
					path.push( "L "+ x + " " + ( y + ypadding) )
				}

				lastx = x
				lasty = y

			}

			if( path ){

				var pathel = document.createElementNS("http://www.w3.org/2000/svg", 'path'); 
				pathel.setAttribute( "d", path.join(" ")); //Set path's data
				pathel.style.stroke = this.cpcolor; //Set stroke colour
				pathel.style.fill = "none"
				//pathel.style.strokeDasharray="5,5"
				pathel.style.strokeWidth = "2px"; //Set stroke width
				pathel.setAttribute( "id", "masterplancriticalpath")
				

				//element.appendChild(pathel);
				element.insertBefore(pathel,element.firstChild);

				this.criticalpathrendered = true			

			}


			if(callback){
				callback()
			}



			},

			ondatekeyup( event, track ){

				if( event.keyCode != 13 ){
					track.status = 0
				}				

			},

			datepickerdone(){

				/*let self = this

				setTimeout( function(){

					self.$refs['datepickerbutton'].blur()
					//window.focus()
				}, 300)*/

			},

			isboardusers( userid ){

		        let toreturn = false

		        if( this.boardusers ){

		          if( this.boardusers.indexOf( userid ) > -1){
		            toreturn = true
		          }

		        }

		        return toreturn
		    },



			setforexport( template ){

				this.selectedforexport = template

			},

			mywidth( iid ){

				//addnewtrackbutton



				let el = this.$refs['flipshlabel_'+iid]

				if(  el && el[0] ){

				return el[0].scrollWidth + 'px'
					
				}

				
			},

			myleft( iid ){

				let el = this.$refs['flipshlabel_'+iid]

				if(  el &&  el[0] ){

				return '-' + el[0].scrollWidth + 'px'
					
				}

				
			},


			/*doinitexportcsv(){

				let self = this

				self.exportloading = false

				Userapi.Exportplancsv( "", this.cockpitid, this.token ).then( function(response){					
					self.apidomain = response.data.apidomain
					//location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
				})

			},*/

			doinitexport(){

				let self = this

				this.exportloading2 = true
				//this.activetrack = {}

				this.doexportready = false
				setTimeout( function(){
					self.doexportready = true
				}, 1000)

				/*setTimeout( function(){


					let wideoptions = self.dataoptions



					//,

					//let chartheight = 100 + ( response.data.tracks.length * 56 )

					//console.log( wideoptions.chart.height )

					wideoptions.chart.width = '1400px'
					wideoptions.plotOptions.bar.barHeight = '40%'
					wideoptions.dataLabels.offsetY = -25
			        //  offsetX: -35,
					//console.log( wideoptions.chart.height )

					self.activechart.destroy();
					self.activechart = new ApexCharts(document.querySelector("#masterplan"), wideoptions);
		        	self.activechart.render();

		        	self.getalltracks()

				}, 100)*/



			},

			doexport( template ){

				let self = this

				this.doexportready = false

				setTimeout( function(){ self.doexportready = true }, 2000)


				Userapi.Masterplangraphimage( self.cockpitid, "" , self.token, "export", template ).then( function(response){

					if( response.data.ok ){
						
						location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
						setTimeout( function(){
							self.exportloading2 = false
						}, 200)

						
					}

				})

				/*self.savemeacopy( 'upload' , function( url  ){
					location.href = url
				})*/


				//if( self.exportloading == false ){


				/*	self.activechart.dataURI().then(({ imgURI }) => {
    				//self.$emit('ondumppng', imgURI)
    					Userapi.savePlanPng( { "field":"savepng", "boardid": self.cockpitid, "t": self.token, "imgurl": imgURI, "forexport":"yes", "template":template } ).then( function(response){
    						if( response.data.ok ){


    							location.href = response.data.apidomain + '/static/exports/' + response.data.newfilename
    							setTimeout( function(){
    								///ccccccself.exportloading = false
    							}, 200)

    							
    						}
						})

    				})
				*/
				    
				
				//}



				


			},

			

			canceledit(){

				this.activetracklabel = this.activetrack.label
				this.activetrackdescription = this.activetrack.description
				//this.activetrack
				//this.activetrackaction = this.activetrack.mitigatingaction

				this.editlabel = false
				this.editdescription = false
			},

			doeditdescription(){

				let self = this

				let hh = $('#inputplandescriptionviewer').height()

				this.editdescription = true
				this.editlabel = false
				this.editaction = false

				this.activetrackdescription  = this.activetrack.description

				setTimeout( function(){
					/////////////////////autosize($('.autogrow'));
					$('#inputtrackdescriptioneditor').height(hh+10)
					self.$refs.inputtrackdescription.focus()
					//self.activetrackdescription = null
				},50)

			},


			doeditlabel(){

				let self = this

				this.editlabel = true
				this.editdescription = false
				this.editaction = false

				this.activetracklabel = this.activetrack.label


				setTimeout( function(){
					self.$refs.inputtracklabel.focus()
				},100)

				
			},

			
			closeotherbox( el ){


				this.activetrack = {}
			},

			isTruncated( iid ) {

				let el = this.$refs['shlabel_'+iid]

				if( el && el[0] ){
					return el[0].offsetWidth < el[0].scrollWidth
				}else{
					return false
				}
			   
			},

			saveplan( sh ){

				//console.log( sh,  'saveplan')

				var updatevalue = { "t":this.token, "action":"updateplan", "field":'updateplan', "label":sh.label, "description":sh.description, "proximity":sh.proximity, "objectid": sh._id }

				Userapi.Updateplan( updatevalue ).then( function(response){

				})
	
			},

			addnewtrackformhidden(){

				let self = this



				if( this.withtrackupdates > 0 ){


					this.getalltracks( function(){

						//console.log( , "wwwwwww")


						//self.activechart.w.globals.selectedDataPoints.forEach(function (element) {
								// self.activechart.toggleDataPointSelection( 0, element ) 
						//		});


							/*setTimeout( function(){

								self.activechart.toggleDataPointSelection( 0, 0 ) 
								self.activechart.toggleDataPointSelection( 0, 0 ) 

								//alert('')
							}, 3000)*/
						
					})




				}

				//if( this.plans ){
				//	this.onselected( this.plans[  this.plans.length - 1 ], false )
				//}
				
			},


			addnewtrackformshown(){

				let date = new Date()

				this.trackname = ""
				this.plandescription = ""
				this.formtracklabel = ""
				this.formtrackdescription = ""				


				this.trackdates.formstart.value = moment( date ).format('YYYY-MM-DD')

				date.setDate(date.getDate() + 30);

				this.trackdates.formend.value = moment( date ).format('YYYY-MM-DD')

				this.$refs.newtracknameinput.focus()

				/////////////////////autosize($('.autogrow'));


				//alert('')
			},


			sortplans(){

			},

			removeuserfromteam( userid, isforced , callback ){


					let self = this

					Teamsapi.Removeuser( this.token, this.team._id, userid, true, isforced, "checktracks" ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtoriskteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey, "remove" ).then( function( response2 ){

								if( callback ){
									callback()
								}
							})
							
						}
						//Teamsapi.Updateuserstatus( option._id, self.orgkey , 'add').then( function( response2 ){						
						//		self.getteams(response.data.team._id)					
						//})

					})

			},

			addusertoteam( userid , callback){


					let self = this

					Teamsapi.Adduser( this.token, this.team._id, userid ).then( function( response ){

						if( response.data.ok ){
							//self.$emit('onuserisaddedtoriskteam', userid )

							Teamsapi.Updateuserstatus( userid, self.orgkey , 'add').then( function( response2 ){	

								if( callback ){
									callback()
								}					
							//		self.getteams(response.data.team._id)					
							})

						}


					})

			},


			windowOnresize(){

				this.windowsize = window.innerWidth

			},

			trydeletetrack(){

				this.confirmplan = true
			},



			confirmdeletetrack( track ){

				let self = this

				Userapi.Trackdelete( track._id, this.token ).then( function(response){

					if( track.owner._id ){
						self.removeuserfromteam( track.owner._id, false , function(){

							})
					}
					
					self.activetracklabel = ""
					self.activetrackdescription = ""
					self.activetrack = {}

					self.confirmplan = false
					//self.activetrack = {}
					self.getalltracks()

					//self.activechart.toggleDataPointSelection( 0, 0 ) 
////////////////
				})

			},

			ondatepicked( act , el ){

				console.log( this.$refs['edit-start-picker'].defaultValue , this.$refs['edit-start-picker'].value , 'picked')



				//if( moment(track.value, 'YYYY-MM-DD', true).isValid() ){

				//}

				
			},

			ondateselect( e ){

				/*if( e == "" ){
					this.updateplan( this.activetrack, 'proximity', "")
					this.datepickerstate = 1
					setTimeout( ()=>this.datepickerstate=0, 2500)
					return false
				}
				
				//alert(newdate)

				let self = this

				setTimeout( function(){

					let newdate = new Date(self.proximityplan)
					if( (newdate instanceof Date && !isNaN(newdate.valueOf())) == true ){
						self.updateplan( self.activetrack, 'proximity', self.proximityplan)

						self.datepickerstate = 1
						setTimeout( ()=>self.datepickerstate=0, 2500)
					}else{
						self.datepickerstate = 2
					}

				}, 100)*/

			},

			updateplan( sh, act, value, callback){


				var updatevalue = { "t":this.token, "action":"updateplan", "field":'updateplan', "label":sh.label, "description":sh.description, "proximity":sh.proximity, "objectid": sh._id }

				Userapi.Updateplan( updatevalue ).then( function(response){

				})
			},

			updatetrackowner( track, action, newdata ){
				

				if( action == "removeowner" ){
					this.updatetrack( 'owner', "", newdata.newid )
				}

				if( action == "addusertoboard" ){
					this.updatetrack( 'owner', newdata.newid )
				}

				if( action == "addowner" ){
					this.updatetrack( 'owner', "createnew", newdata.firstname )
				}

				if( action == "owneruser" ){
					this.updatetrack( 'owneruserfirstname', newdata._id , newdata.firstname )
				}

				//this.updatetrack( 'owner', newdata.newid )
			},

			updatetrack( field, value, value2 ){

				if( field=='label' ){
					if(value==""){
						return false
					}
				}


				function sortmilestones( a, b ) {
				  if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
				    return -1;
				  }
				  if ( new Date(a.eventdate) > new Date(b.eventdate) ){
				    return 1;
				  }
				  return 0;
				}

				let objectid = this.activetrack._id

				if( field=='sortorder2' ){
					objectid = value2
				}

				let self = this
				let updatevalue = { "t":this.token, "objectid":objectid, "action":"updatetrack", "field":field, "newvalue":value }

				if( value2 ){
					updatevalue.newvalue2 = value2
				}

				Userapi.Updatetrack( updatevalue ).then( function(response){


					if( response.data.ok && response.data.ok == true ){


					self.editlabel = false
					self.editdescription = false

					self.editmilestones = ""
					self.trackdates.milestones.value = ""
					self.trackdates.milestones.status = 0
					self.iscriticalpath = false
					self.milestonesstatus = "idle"

					if( response.data.track ){
						self.activetrack = response.data.track

						let milestones = []

						if( self.activetrack.milestones ){
							milestones = self.activetrack.milestones
							milestones.sort( sortmilestones );
						}

						self.activetrack.milestones = milestones					

						self.statusselected = self.trackstatusitem(self.activetrack.status)
						
					}




					if( field == "owner" ){

						if( value == "" ){

							self.removeuserfromteam( value2, false , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})

							})

						}else{

							self.addusertoteam( value , function(){

								self.$store.dispatch('fechtCockpit',{cid: self.cockpit._id, orgkey: self.orgkey, token: self.token}).then( function(response){
									if( response.data.ok){
										//self.updateboardusers()
									}
								})

							})
						}

						

					}				

					

					//self.activetrackdescription = ""
					//self.activetracklabel = ""
					
					self.getalltracks()



					}


					




				})
			},

			unselect(){
				this.activetrack = {}
			},


			onselected( sh , showinfo, isforced, shouldfocus ){	


			},




			getalltracks( callback ){


				function sortmilestones( a, b ) {
				  if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
				    return -1;
				  }
				  if ( new Date(a.eventdate) > new Date(b.eventdate) ){
				    return 1;
				  }
				  return 0;
				}

				//tracksmilestones.sort( sortmilestones );



				let green = '#7ece7e'
				let red = '#ff9c9c'
				let yellow = '#ffe0a5'
				let blue = '#b5c3e3'

				//



				let self = this

				//alert('')
				Userapi.Tracks( this.cockpitid, this.token, "open" ).then( function(response){

					

						//this.getallplans
						/*
						{
                x: 'Analysis',
                y: [
                  new Date('2019-02-27').getTime(),
                  new Date('2019-03-04').getTime()
                ],
                fillColor: '#008FFB'
              }*/

              let newseries = []
              let xpoints = []

						if( response.data.tracks ){						

							self.tracks = response.data.tracks							

							let mintrackstart = new Date()
							let maxtrackend = new Date()

							for( let i in response.data.tracks ){

								let currentd = new Date( response.data.tracks[i].trackstart )

								if( currentd < mintrackstart ){
									mintrackstart = currentd
								}
							}

							let trackcntr = 0

							for( let i in response.data.tracks ){


								


                				let milestones = []

                				let xaxi = response.data.tracks[i]._id//+'x|x'+response.data.tracks[i].label

                				if( response.data.tracks[i].milestones ){
									

									let tracksmilestones = response.data.tracks[i].milestones

									tracksmilestones.sort( sortmilestones );

									/*tracksmilestones.forEach(function (element) {
									  element.editmode = false
									});*/

                					for( let o in tracksmilestones ){                						

                						let criticalcolor = tracksmilestones[o].iscritical ? self.cpcolor : self.notcpcolor

                						let milestone = { name: tracksmilestones[o].label, value: new Date( tracksmilestones[o].eventdate ).getTime(), strokeColor: criticalcolor , strokeWidth: 10, strokeHeight: 0, strokeLineCap: 'round',  mouseEnter: function(e) {
											 //   alert('cc')
											}}

                    					milestones.push(milestone)


                    					//let xpoint = []
                    					//+ ' - ' + tracksmilestones[o].eventdate


                    					let labelstyle = {
									            background: '#fff',
									            //color: '#777',
									            fontSize: '13px',
									            fontWeight: 400,
									            //fontFamily: 'roboto'
									             }

									    

										xpoints.push( {  y:xaxi, x:milestone.value, marker: { size: 6, fillColor: "#000",
      strokeColor: "#000" }, label: {  borderColor: '#d5dae3', borderWidth: 1, text:tracksmilestones[o].label ,  offsetY: 10000, style : labelstyle  } } )
                					}
                				}

                				let trackcolor = blue

                				if( response.data.tracks[i].status && response.data.tracks[i].status == 'green' ){
                					trackcolor = green
                				}
                				if( response.data.tracks[i].status && response.data.tracks[i].status == 'red' ){
                					trackcolor = red
                				}
                				if( response.data.tracks[i].status && response.data.tracks[i].status == 'yellow' ){
                					trackcolor = yellow
                				}

                				response.data.tracks[i].status
								let series = { x:'', y:[], fillColor: trackcolor, goals:milestones }

								series.x = xaxi



								let trackstart = new Date( response.data.tracks[i].trackstart ).getTime()
								let trackend = new Date( response.data.tracks[i].trackend ).getTime()

								if(  isNaN(trackstart) || isNaN(trackend) ){
									
									trackstart = mintrackstart.getTime()
									/*new Date()
									trackstart.setDate(trackstart.getDate() - 1);*/
									//trackstart

									trackend = new Date().getTime()
									series.fillColor = '#CD0000'
								}

								series.y.push( trackstart )
								series.y.push( trackend )

								newseries.push( series )


								if( self.lastaddedtrack == response.data.tracks[i]._id){

									//alert('found')
									//alert(trackcntr)

									//self.activechart.toggleDataPointSelection( 0, trackcntr ) 
								
									
									    //	alert(trackcntr)
								}


									trackcntr += 1

								//xpoints.push({ x:xaxi, y:trackstart })
								//console.log( series, "series")
							}

							//self.dataoptions.chart.height = 100 + ( response.data.tracks.length * 40 );

							////self.dataoptions.series = []
							////self.dataoptions.series.push( { name: 'Gantt', data : newseries } )
							////self.dataoptions.series.push( { name: 'Data 2', type: 'line', data : xpoints } )

							//console.log( newseries )

							/*

							Object { x: "62b1294e293cc1317c4ec5a6x|xHand Transforms not on end", y: 1647475200000 }*/

							/*self.dataoptions.annotations = {
								  points: [
							      {
							        x: "1647475200000",
							        y: "62b1294e293cc1317c4ec5a6x|xHand Transforms not on end",
							        marker: {
							          size: 6,
							      	},
							      	label: {
							      		text:'xxxxxxxxxxxxxxxxxxxxx'
							      	}
							      }]
								}*/

							//console.log( mintrackstart )

							
							
							

						}

						

						setTimeout( function(){

							if( self.lastaddedtrack ){


								setTimeout( function(){

									self.activechart.toggleDataPointSelection( 0, (self.tracks.length - 1) ) 

								}, 100)



							}else{


								if( !self.activetrack._id && !self.lastaddedtrack ){

									setTimeout( function(){

										self.activechart.toggleDataPointSelection( 0, 0 ) 

									}, 100)

									

									/*self.activetrack = self.tracks[0]
									self.trackdates.editstart.value = self.activetrack.trackstart
									self.trackdates.editend.value = self.activetrack.trackend								

									*/

								}


							}


							

							if( self.activechart ){

/*

								let tracksmilestones = []

								if( self.activetrack.milestones ){

									tracksmilestones = self.activetrack.milestones
									tracksmilestones.sort( sortmilestones );
								}

								self.activetrack.milestones = tracksmilestones

								self.trackdates.editstart.value = self.activetrack.trackstart
								self.trackdates.editend.value = self.activetrack.trackend
								self.activetrackdescription = self.activetrack.description

								self.statusselected = self.trackstatusitem(self.activetrack.status)


								*/

								//rowheight

								let chartheight = 100 + ( self.tracks.length * 56 )

								if( self.exportloading == true ){
									chartheight = 100 + ( self.tracks.length * 100 )
								}

								self.activechart.updateOptions( { chart: { height : chartheight  }, series: [{ name: 'Gantt', data : newseries }] }, false  )


								setTimeout( function(){

									self.activechart.updateOptions( { annotations : { position: 'front' ,
									   xaxis: [{ x: new Date().getTime(), opacity: 0.3, strokeDashArray: 6,  borderColor: '#313338',
      fillColor: '#313338', }],
									  points: xpoints
									}  }, false  )


								}, 50)


								if (self.lastzoom) {
								    self.activechart.zoomX(self.lastzoom[0], self.lastzoom[1]);
								  }

								//self.activechart.destroy();
								//self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
		        				//self.activechart.render();

							}


							if( callback ){
							callback()
						}


							//	alert('')
						},100)

						

						
				
					})




			},

			createnewtrack( addanother ){


				function sortmilestones( a, b ) {
				  if ( new Date(a.eventdate) <  new Date(b.eventdate) ){
				    return -1;
				  }
				  if ( new Date(a.eventdate) > new Date(b.eventdate) ){
				    return 1;
				  }
				  return 0;
				}


				let self = this

				if( this.formtracklabel != '' ){

					let newplan = { "label":this.formtracklabel, "description":this.formtrackdescription, "trackstart":this.trackdates.formstart.value, "trackend": this.trackdates.formend.value }

					//this.plans.push( newplan )

					this.trackname = ""
					this.plandescription = ""
					this.formtracklabel = ""
					this.formtrackdescription = ""

					//Newplan

					Userapi.Newtrack( { "t":this.token, "action":"new", "boardid":this.cockpitid, "label": newplan.label, "description": newplan.description, "trackstart":this.trackdates.formstart.value, "trackend":this.trackdates.formend.value } ).then( function(response){


						if( response.data.ok ){

							//self.tracks = []

							//self.plans = response.data.plans

							self.withtrackupdates += 1

							
							if( response.data.newtrack != "" && response.data.track ){
							
								self.activetrack = response.data.track

								let tracksmilestones = []

								if( self.activetrack.milestones ){

									tracksmilestones = self.activetrack.milestones
									tracksmilestones.sort( sortmilestones );

								}

								self.activetrack.milestones = tracksmilestones

								self.trackdates.editstart.value = self.activetrack.trackstart
								self.trackdates.editend.value = self.activetrack.trackend
								self.activetrackdescription = self.activetrack.description

								self.statusselected = self.trackstatusitem(self.activetrack.status)


								//self.activechart.toggleDataPointSelection( 0, self.tracks.length ) 
							//alert('')

								self.lastaddedtrack = response.data.newtrack
							}
							


							if( addanother == false ){
								
								self.addnewtrack = false
							}else{

								setTimeout( function(){
									self.$refs.newtracknameinput.focus()								
								}, 300)
							}

						}


						

						//for( var i in self.plans ){

						//}

						/*setTimeout( function(){

							if( self.plans[ self.plans.length - 1 ] ){
								self.onselected( self.plans[ self.plans.length - 1 ] , true, true, false)
							}
								
						}, 1000)*/


						
						
						
						//console.log( response, "xxxxxxxx")
					})

				}

				//$('.tinydraggable').tinyDraggable();


			}
		},

		components:{ ownerPicker, vueselect }

	}
</script>