<template>
  <b-modal v-model="isshown" size="md" no-close-on-backdrop no-close-on-esc @hidden="modalhidden" @shown="modalshown"   hide-footer>
    <template v-slot:modal-header>
         <!-- <button v-if="!validforposting && !deviseDesktop" type="button" aria-label="Close" class="close" disabled style ="margin-left: auto auto auto 0;  padding:0; font-size:16px;"> Add indicator</button> -->
       
      <h3 class="modal-title"><span>Add status update</span></h3>
      <a class = "close-modal dialog-close-button mr-2">
      <i class="fal fa-times fa-lg fa-lg" @click="isshown = false"></i>
    </a>
    </template>
    <div> 
    <h4 class ="font-weight-bold">{{objective.name}}</h4>
    <div class ="diverder"></div>


    <div class="hide" v-if="objective.measuretype=='masterplan'">

      <div  id="risktable2">
        
        <p>Add description...</p>
        
        <p>
          <img :src="uploadedgraphimage" style="width: 600px;" class="fr-fic fr-dib" >
        </p>

        <p>&nbsp;</p>

        <h3>Milestones</h3>

        <table style="width: 100%;" class="fr-no-border" >              
          <tbody :key="key2" v-for="( cell, key2) in trackcells">
            <tr v-if="cell.row=='header'">
              <td colspan="2"><strong>{{ cell.label }}</strong></td>
            </tr>
            <tr v-else>
              <template v-if="cell.row=='item'">
                <td style="width: 30.000%;" nowrap>{{ cell.eventdate }}</td>
                <td style="width: 70.000%;">{{ cell.label }}</td>
              </template>
              <template v-else>
                <td>- no milestones -</td>
              </template>
              
            </tr>
          </tbody>
        </table>

        <p>&nbsp;</p>
        

      </div>

    </div>

    <div class="hide" v-if="objective.measuretype=='risk'">

      <div  id="risktable2">

        <p>Add description...</p>
        
        <p>
          <img :src="uploadedgraphimage" style="width: 600px;" class="fr-fic fr-dib" >
        </p>
        <table class="table w-100" style="width:100%;" width="100%">
          <tr>
            <td width="40%" style="background-color: rgb(209, 213, 216);">Risk</td>
            <td width="40%" style="background-color: rgb(209, 213, 216);">Mitigating Action</td>
            <td width="20%" style="background-color: rgb(209, 213, 216);">Owner</td>
          </tr>
          <tr :key="risk._id" v-for="risk in risks">
            <td><strong>{{ risk.label }}</strong> <p>{{ risk.description }}</p></td>
            <td>{{ risk.mitigatingaction }} </td>
            <td>{{ risk.owner.firstname }} {{ risk.owner.lastname }}</td>
          </tr>        
        </table>
      </div>

    </div>
    

    <div class ="indicaterselecter" :class ="preselecter.color" v-if="!haserrors">

      <vueselect class="statuschooser" :searchable="searchable" :clearable = "false" v-model="preselecter" :options = "objectivestatusoptions">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes"> <i class="fas fa-sort"></i></span>
        </template>
        <template #option="{ refid, color, icon, indexvalue, label, weight }">
          <div class ="d-flex align-items-center">
            <div class ="icon-holder">
              <span><i :class="icon" :style="'color: var(--'+color+')'"></i></span>
            </div>
            <div :class="{'droptext':ismobile, 'text-wrap':ismobile}">{{label}}</div>
          </div>
        </template>
         <template #selected-option="{ refid, color, icon, indexvalue, label, weight }">
          <div class ="d-flex align-items-center">
            <div class ="icon-holder">
              <span><i :class="icon" :style="'color: var(--'+color+')'"></i></span>
            </div>
            <div :class="{'droptext':ismobile, 'text-wrap':ismobile}">{{label}}</div>
          </div>
        </template>  
        <template v-if="1==2" #search="{ attributes, events }">&nbsp;
          <input
            v-if="searchable"
            maxlength="1"
            class="vs__search"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </vueselect>
    </div>

    <div class = "align-self-center m-2" v-if="haserrors">

      <div sizem="sm" class="base-icon-label danger nav-icon asalink" @click="moreerr()">
        <span class="iconlabelbefore overflow-string iconlabel">Unable to determine status</span>
        <div class="icon-md  forcesm">
          <span><i v-if="!showerror" class="fa-do-not-enter far" aria-hidden="true"></i> <i v-if="showerror" class="fa-do-not-enter fas" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>




      <div class="form-group smt-2">
        <div id="statuscommenteditortoolbar"></div>

        <froala id="statuseditor" :config="froalaconfig" class="mb-0" :onManualControllerReady="initializefroala" v-model="message"></froala>
      </div>

    </div>

    <div class="form-group text-right">
      Note: Status updates cannot be changed once published.
      <p class="text-danger" v-if="addnewerror && addnewerror=='orgnoaccess'">Sorry. It looks like you no longer have access to this. Please try again or contact your IT administrator if the error persists.</p>
      <div class = "mt-2 mb-2"> 
        <button-gruppe default="none" text1="Save and publish" :loading = "status == 'loading'" @no ="cancelloadautocomplete(); isshown = false" @yes="addnewindicator()" :disabled2="validforposting == false || imageuploading == true"></button-gruppe>
      
      </div>
    </div>


    <b-modal v-model="autosavecontentfound" modal-class="" size="md" no-close-on-backdrop no-close-on-esc scrollable>
    <template v-slot:modal-header>
          <h3 class="modal-title">Autosaved content found</h3>
          <a class = "close-modal dialog-close-button mr-2">
      <i class="fal fa-times fa-lg " @click="autosavecontentfound = false"></i>
    </a>        
    </template> 


    <p> We recovered autosaved content from a previous session.
Click ‘OK’ to load it, or ‘Cancel’ to discard it and start over. </p>


    <template v-slot:modal-footer="{ cancel, ok }">
      <button-gruppe @no ="cancelloadautocomplete()" @yes="loadautocomplete()"></button-gruppe>
    </template>

  </b-modal>

    



  </b-modal>
</template>

<style scoped>

.droptext {
    /*width: 265px !important;*/
  }

@media screen and (max-width: 400px) {
   .droptext {
    /*width: 265px !important;*/
  }
}

  
  
  
  .fr-view table.fr-no-border td, .fr-view table.fr-no-border th {
    border: none !important;
  }

  

</style>

<style type="text/css">

 .statuschooser .vs__search{
   /* visibility: hidden;*/
    height: 1px;
    opacity: 0;
  }
  
  .statuschooser .vs__dropdown-menu{
    /*border: solid 1px red !important;*/
  }

  /*.fr-alternate-rows tr:nth-child(odd){
    background-color: #4C8BF5;
    co*lor: #fff;
  }*/

</style>

<script type="text/javascript"></script>
<script>

  import Userapi from '@/services/api/users'
  import Cockpitsapi from '@/services/api/cockpits'
    import vueselect from '@/views/tools/vueselect'
  import AWS from 'aws-sdk'

  const ID = 'AKIA5ULT5RVZU7LWNUZY';
  const SECRET = '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';

  // The name of the bucket that you have created
  const BUCKET_NAME = 'flowe-editor';
  AWS.config.update({region: 'eu-west-3'});

  const s3Bucket = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
    apiVersion: '2006-03-01', 
    region: 'eu-west-3',
    params: {Bucket: BUCKET_NAME,ACL : 'public-read',}
  });

  export default {
    name:'addStatustoindicator',
    props:{
      shown:Boolean,
      cockpitid:String,
      token:String,
      objectivedata:Object,
      board:Object,
      deviseDesktop:Boolean
    },
    data(){
      return{
        tracks:[],
        searchable : false,
        haserrors : false,
        showerror : false,
        uploadedgraphimage:"",
        risks:[],
        riskaggregation:{},
        objective:{},
        preselecter:{ refid:"", label: 'Select status', color:null, icon:null, indexvalue:null, weight:null },
        indicatoroptions:[
          // {html:null, label: 'Select status', value:null, key:null, classed:null, placeholder:true, icon:false}, 
          {html: '<i class = "far fa-check">', label: 'On track', value:'green', key:1.20, classed:'green',icon:true},
          {html: '<i class = "far fa-times">', label: 'Off track', value:'red', key:0.80, classed:'red', icon:true}
        ],
        isshown : false,
        showinstruction : false,
        targetvalue: "",
        realizedvalue: "",
        statusindicator : "",
        statustext : "",
        directvalue : 1,
        froalaconfig: {},
        linkInitControls: null,
        editorinit: false,
        message :"",
        autosavecontentfound : false,
        autosave : {},
        status: 'idle',
        calculatetimer : null,
        runningcalculations : false,
        imageuploading : false,
        addnewerror : ""
    }

  },
  watch:{

    shown : function( e ){
      this.isshown = e
    },
    
  },
  components:{ 
  vueselect     
  },

  computed:{

    ismobile(){

        return this.$store.state.isMobile
      },

    trackcells(){

      let self = this

      let toreturn = []

      this.tracks.forEach(function (track) {
        toreturn.push( { "row":"header", "label":track.label }  )

        if( track.milestones && track.milestones.length>0){
           track.milestones.forEach(function (milestone) {
            toreturn.push( { "row":"item", "label":milestone.label, "eventdate":milestone.eventdate }  )
           })
        }else{
          toreturn.push( { "row":"norows", "label":"", "eventdate":"" }  )
        }
      })

      return toreturn
    },

    organization(){
        return this.$store.state.organizations.selectedorganizations
      },

      validorg(){

        if( this.organization.key && ( this.$route.params.orgname != this.organization.key )  ){
          return false
        }else{
          return true
        }


      },

    orgkey(){
        return this.$route.params.orgname ? this.$route.params.orgname : null
      },

    objectivestatusoptions(){

      //var obj = this.objective.statusoptions

      var statusoptions = []

      $.each(  this.objective.statusoptions , function( i,o ){

        if( o.isdefault ){
          statusoptions.push(o)
        }

      })

      return statusoptions
    },

    userid(){
      return this.$store.state.user.userInfo._id
    },
    calculatedindexcolor(){
      return this.preselecter.color
    },
    calculatedindex(){
      return this.preselecter.indexvalue
    },

    validforposting(){
      if(typeof this.calculatedindex == 'number'){
        return true
      } else {
        return false
      }
    },

    cockpit(){
        return this.$store.getters.GetCockpit
      },

  },
   mounted(){     

        let self = this
        this.froalaconfig = this.$store.state.config
        this.froalaconfig.heightMax = 400
        this.froalaconfig.placeholderText = 'Add description…'
        this.froalaconfig.events = {
          'image.beforeUpload': function (images, lo ) {
            self.imageuploading = true        
          },
          'image.inserted': function ($img, response) {
            self.imageuploading = false
          }
        }

        $.getJSON( "https://s3token.flowe.io/", function(data){
          self.froalaconfig.imageUploadToS3 = data        
        })


        
      
    },
  methods:{

    onblurselect(){

      alert('')
    },

    moreerr(){

      if( this.showerror == true ){
        this.showerror = false
      }else{
        this.showerror = true
      }

    },

    sortbycxp( risks ){

      let sortedrisks = []


      for( let x in risks ){
        risks[x].cxp = risks[x].consequence * risks[x].probability
        sortedrisks.push( risks[x] )
      }

      //console.table(sortedrisks)

      sortedrisks.sort((a,b) => (a.cxp > b.cxp) ? -1 : ((b.cxp > a.cxp) ? 1 : 0))

//      console.table(sortedrisks)


      return sortedrisks

    },

    getdefaultselectvalue(){

      let self = this

      //var obj = this.objective.statusoptions

      let defcolor = null 

      /*if( self.objective.measuretype == 'risk' ){
          if( self.objective.aggregationmethod == 'worst' ){
            defcolor =  self.riskaggregation.worst
          }
          if( self.objective.aggregationmethod == 'best' ){
            defcolor =  self.riskaggregation.best
          }
          if( self.objective.aggregationmethod == 'average' || self.objective.aggregationmethod == 'averagelow' || self.objective.aggregationmethod == 'averagehigh' || self.objective.aggregationmethod == 'optimisticaverage' ){
            defcolor =  self.riskaggregation.averagelow
          }

      }*/

    // if( !defcolor || defcolor == "" ){

     // this.haserrors = true
     // this.showerror = true
    //}

      var defaultselected = {}

      $.each(  this.objective.statusoptions , function( i,o ){

        if( o.isdefault ){
          //statusoptions.push(o)


          if(o.color == defcolor){
            defaultselected = o
          }
         
        }



      })


      let empty = { html:null, label: 'Select status', value:null, key:null, classed:null, placeholder:true, icon:false}

      return !defcolor ? empty : defaultselected
    },

    posonly( val){

      //@input="posonly($event)"

      //console.log( val.data , "cc")
    },

    cancelloadautocomplete(){

      this.autosave = {}
      Cockpitsapi.Autosave( "status_" + this.objective._id, "", localStorage.token )
      this.autosavecontentfound = false


    
    },

    loadautocomplete(){

      this.message = this.autosave.content
      
      //Cockpitsapi.Autosave( "status_" + this.objective._id, "", localStorage.token )
      //this.autosave = {}

      this.autosavecontentfound = false


    },
    pickvalue( newvalue ){

      this.directvalue = newvalue
    },

    initializefroala( initControls ){
      this.linkInitControls = initControls;
    },

    ping2(){
      alert("pong pong")
    },

    modalshown(){

      
        let self = this

        this.addnewerror = ""

        this.froalaconfig = this.$store.state.config
        this.froalaconfig.heightMax = 400
        this.froalaconfig.placeholderText = 'Add description…'
        this.froalaconfig.events = {
          'image.beforeUpload': function (images, lo ) {
           // alert('vv')
            self.imageuploading = true        
          },
          'image.inserted': function ($img, response) {
            self.imageuploading = false
          }
        }

        $.getJSON( "https://s3token.flowe.io/", function(data){
          self.froalaconfig.imageUploadToS3 = data        
        })
      
      this.showinstruction = false
      this.statustext = ""
       this.imageuploading = false
      document.title ='Add status update | Flowe'
      if( !this.editorinit ){

        this.linkInitControls.initialize()
          //this.editorinit = true

        this.froalaconfig.events.contentChanged = function () {

          var editorvalue = self.linkInitControls.getEditor().html.get()
          
          Cockpitsapi.Autosave( "status_" + self.objective._id, editorvalue, localStorage.token )
          .then( function(response){

          })

          
        }

      }

      this.objectivesettings()
        
        
      },

      objectivesettings( callback ){

      let self = this

      Cockpitsapi.Objectiveedit( this.objectivedata._id,  localStorage.token, this.orgkey )
      .then( function(response){
        
       

        self.objective = response.data.measure

        
        if( response.data.autosave && response.data.autosave._id ){

          if( response.data.autosave.content != "" ){
            self.autosave = response.data.autosave
            self.autosavecontentfound = true
          }
        }
        
       


        if( response.data && response.data.ok ){


         if( self.objective.measuretype == "masterplan" ){

          /*if( self.cockpit.planpng ){


          setTimeout( function(){

              self.uploadedgraphimage = self.cockpit.planpng
              self.rendermessage()

          }, 300)

          }*/


          Userapi.Masterplangraphimage( "", self.objective._id , self.token, "status" ).then( function(response){
       
          if( response.data.ok ){

            var md5 = require('md5');

            let base64image = response.data.graphimage
            let nowstring = new Date().toString()

            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

            let imagekey = "image" + nowstring

            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
            var data = {
            Key: md5( imagekey  ) + ".jpg" , 
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: 'image/jpeg'
            };

            s3Bucket.putObject(data, function(err, data){
              if (err) { 
                
                console.log(err);
                console.log('Error uploading data: ', data); 
              
              } else {

                //console.log( "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" , 'successfully uploaded the image!');
                //let graphimageurl = "<img src=\"https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" + "\" style=\"width: 300px;\" class=\"fr-fic fr-dib\">"

                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg"

                self.uploadedgraphimage = graphimageurl

                self.rendermessage()

                /*Userapi.Riskscomputed( "", self.objective._id , self.token ).then( function(response){

                  //if( response.data.ok ){
                    self.riskaggregation = response.data.aggregation
                    self.risks = self.sortbycxp(response.data.risks) 
                    self.preselecter = self.getdefaultselectvalue()
                    
                  //}

                }) */


              }
            })

          }else{
            self.preselecter = self.getdefaultselectvalue()
          }
            


          })


          
         }

        if( self.objective.measuretype == "risk" ){

          Userapi.Risksgraphimage( "", self.objective._id , self.token ).then( function(response){
       
          if( response.data.ok ){

            var md5 = require('md5');

            let base64image = response.data.graphimage
            let nowstring = new Date().toString()

            // let s3Bucket = new AWS.S3( { params: {Bucket: 'myBucket'} } );

            let imagekey = "image" + nowstring

            var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
            var data = {
            Key: md5( imagekey  ) + ".jpg" , 
            Body: buf,
            ContentEncoding: 'base64',
            ContentType: 'image/jpeg'
            };

            s3Bucket.putObject(data, function(err, data){
              if (err) { 
                
                console.log(err);
                console.log('Error uploading data: ', data); 
              
              } else {

                //console.log( "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" , 'successfully uploaded the image!');
                //let graphimageurl = "<img src=\"https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg" + "\" style=\"width: 300px;\" class=\"fr-fic fr-dib\">"

                let graphimageurl = "https://s3-eu-west-3.amazonaws.com/flowe-editor/" + md5( imagekey  ) + ".jpg"

                self.uploadedgraphimage = graphimageurl

                Userapi.Riskscomputed( "", self.objective._id , self.token ).then( function(response){

                  //if( response.data.ok ){
                    self.riskaggregation = response.data.aggregation
                    self.risks = self.sortbycxp(response.data.risks) 
                    self.preselecter = self.getdefaultselectvalue()
                    self.rendermessage()
                  //}

                }) 


              }
            })

          }else{
            self.preselecter = self.getdefaultselectvalue()
          }
            


          })


          
        }
      }

        


        if( callback ){
          callback()
        }

      //}

      })
      .catch( function(){
        //self.cockpitsettingsmodalshown = false
      })
      .finally( () => {
      })



      },

      rendermessage(){

        let self = this

        Userapi.Tracks( this.cockpit._id, this.token, "open" ).then( function(response){

          if( response.data.ok ){

            self.tracks = response.data.tracks
          }


          var el = document.getElementById( 'risktable2' );
          setTimeout( function(){
            
            self.message = el.innerHTML
            $('#risktable2').hide()

          }, 200)

        })  

        
        
      },

      modalhidden(){

        this.froalaconfig.events.contentChanged = function(){}
        //this.linkInitControls.destroy()
       //## this.validforposting = false
        this.preselecter = { html:null, label: 'Select status', value:null, key:null, classed:null, placeholder:true, icon:false},
        this.$emit('onmodalhidden')
        this.$func.removeQuery(['page'],this.$route, this.$router)
        this.isshown = false

        this.message = ""
      },

      addnewindicator(){

        this.addnewerror = ""

        this.status = 'loading'
        var editorvalue = this.linkInitControls.getEditor().html.get()

        let self = this 
        Cockpitsapi.Objectiveindicatorsadd( { "statusoptionid":this.preselecter.refid, "cockpitid":this.$route.params.cid, "userid": this.userid, "measureid": this.objective._id, "calculatedindex":this.calculatedindex, "calculatedindexcolor":this.calculatedindexcolor, "statusmessage":editorvalue, "statusmessagetext": editorvalue } ).then( function(response){

          if( response.data.ok ){
            self.$emit('onaddnewindicatorsuccess', self.objective._id, response.data.ind)
            self.addnewerror = ""
          }else{
            self.addnewerror = response.data.error
          }

                })
        .catch(error => console.log(error))
        .finally( () => {
            this.status = 'idle'
                  
        })



      },
    }

  }

</script>